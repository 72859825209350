import { Record } from "immutable";
import {
  API_END,
  API_START,
  SET_POST_FILES_IMAGES,
  FETCH_PUBLISH_EVENT,
  REMOVE_POST_IMAGE,
  SET_PUBLISH_EVENT_RESULT,
  SET_POST_URL,
  SET_POST_DATE,
  SET_POST_DESCRIPTION_FR,
  SET_POST_DESCRIPTION_EN,
  CLEAR_POST_URL,
  FETCH_GET_OWN_EVENTS,
  SET_OWN_EVENTS_RESULT,
  SET_DELETE_EVENT_RESULT,
  SET_DELETE_EVENT_FAILURE,
  FETCH_DELETE_EVENT,
  SET_POST_DESCRIPTION_ERROR,
  SET_POST_URL_ERROR,
  ADD_POST_CAMPSITE,
  DELETE_POST_CAMPSITE,
  MISSING_POST_CAMPSITE,
  SET_PUBLISH_EVENT_FAILURE,
  SET_POST_GOODDEAL,
  SET_POST_SORTED,
  RESET_EVENT,
  FETCH_GET_OWN_GOOD_DEALS_TITLES,
  SET_OWN_GOOD_DEAL_TITLES_RESULT,
  SET_POST_TITLE_FR,
  SET_POST_TITLE_EN,
  SET_GET_EVENT_RESULT,
  FETCH_GET_EVENT,
  FETCH_ADD_EVENT,
  SET_ADD_EVENT_FAILURE,
  SET_ADD_EVENT_RESULT,
  FETCH_UPDATE_EVENT,
  SET_UPDATE_EVENT_RESULT,
  SET_UPDATE_EVENT_FAILURE,
  FETCH_MODERATING_EVENT_ACCEPT,
  FETCH_MODERATING_EVENT_REFUSE,
  SET_MODERATING_EVENT_ACCEPT_RESULT,
  SET_MODERATING_EVENT_ACCEPT_FAILURE,
  SET_MODERATING_EVENT_REFUSE_RESULT,
  SET_MODERATING_EVENT_REFUSE_FAILURE,
  FETCH_GET_HOME_EVENTS,
  SET_HOME_EVENTS_RESULT,
  SET_POST_TITLE_ERROR,
  SET_POST_TYPE,
  SET_POST_TYPE_ERROR,
  SET_POST_GOODDEAL_ERROR,
  SET_POST_PROMOTION_FR,
  SET_POST_START_DATE_ERROR,
  SET_POST_PROMOTION_ERROR,
  SET_POST_PROMOTION_EN,
  SET_GET_EVENT_CAMPSITES_RESULT,
  PUBLISHED,
  FETCH_GET_EVENT_CAMPSITES,
  ADD_WIZARD,
  SET_PROFILE_DETAILS,
  CLEAR_SNACK_WARN_MSG,
  SET_CITIES,
  ADD_POST_CITY,
  DELETE_POST_CITY,
  MISSING_POST_CITY,
  SET_EVENT_TYPES,
  SET_EVENT_CHANNELS,
  ADD_POST_CHANNEL,
  DELETE_POST_CHANNEL,
  MISSING_POST_CHANNEL
} from "../actions/types";
import { t } from "../services/i18n";
import moment from "moment";

const InitialState = new Record({
  isFetching: false,
  _id: null,
  url: null,
  title: null,
  titleErrorMsg: null,
  description: null,
  descriptionErrorMsg: null,
  urlState: null,
  startDate: null,
  lang: null,
  files: [],
  events: {},
  snackErrorMsg: null,
  snackWarnMsg: null,
  snackInfoMsg: null,
  urlErrorMsg: null,
  campsites: [],
  campsitesIds: [],
  campsitesEntities: [],
  cities: [],
  citiesIds: [],
  citiesEntities: [],
  suggestions: [],
  newWizard: null,
  campsiteErrorMsg: null,
  cityErrorMsg: null,
  goodDealsTitles: {},
  goodDealId: null,
  photoUri: null,
  page: 1,
  limit: 6,
  hasNextPage: false,
  nextPage: 2,
  totalPages: 0,
  totalDocs: 0,
  sorted: [{ id: "dateUpdated", desc: true }],
  eventTypeId: null,
  typeErrorMsg: null,
  isGoodDealType: false,
  goodDealErrorMsg: null,
  promotion: null,
  isDateMandatory: false,
  isPromotionType: false,
  startDateErrorMsg: null,
  promotionErrorMsg: null,
  warnMsgCleared: false,
  company: null,
  eventTypes: {},
  channelsIds: [],
  channels: [],
  channelsSuggestions: [],
  channelsEntities: {},
  publishedChannelErrorMsg: null
});

// verifies if value is a valid URL
const verifyUrl = value => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

const initialState = new InitialState();

function keepState(state) {
  const suggestions = [...state.suggestions];
  // eslint-disable-next-line
  for (const suggestion of suggestions) {
    suggestion.showInfo = true;
  }
  return (
    initialState
      .set("isFetching", state.isFetching)
      .set("campsitesEntities", state.campsitesEntities)
      .set("citiesEntities", state.citiesEntities)
      .set("suggestions", suggestions)
      // .set("campsites", state.campsites)
      .set("events", state.events)
      .set("goodDealsTitles", state.goodDealsTitles)
      .set("company", state.company)
      .set("page", state.page)
      .set("limit", state.limit)
      .set("hasNextPage", state.hasNextPage)
      .set("totalPages", state.totalPages)
      .set("totalDocs", state.totalDocs)
      .set(
        "channelsSuggestions",
        Object.values(state.channelsEntities).map(obj => {
          let suffixe;
          switch (obj.channeltype) {
            case "CampsiteEventChannel":
              suffixe = " - Campings [HapiVisit]";
              break;
            case "CityEventChannel":
              suffixe = " - Villes [HapiCity]";
              break;
            case "NeighborhoodEventChannel":
              suffixe = " - Voisins [HapiColibri]";
              break;
            default:
              suffixe = "";
          }
          return {
            name: obj.name + suffixe,
            id: obj._id
          };
        })
      )
      .set("channelsEntities", state.channelsEntities)
  );
}

export default function uploadReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (
        action.payload === FETCH_GET_OWN_EVENTS ||
        action.payload === FETCH_GET_HOME_EVENTS
      ) {
        return state.set("isFetchingList", true).set("events", {});
      }
      if (action.payload === FETCH_GET_OWN_GOOD_DEALS_TITLES) {
        return state.set("isFetching", true).set("goodDealsTitles", {});
      }
      if (
        action.payload === FETCH_DELETE_EVENT ||
        action.payload === FETCH_GET_EVENT ||
        action.payload === FETCH_GET_EVENT_CAMPSITES ||
        action.payload === FETCH_ADD_EVENT ||
        action.payload === FETCH_UPDATE_EVENT ||
        action.payload === FETCH_PUBLISH_EVENT ||
        action.payload === FETCH_MODERATING_EVENT_ACCEPT ||
        action.payload === FETCH_MODERATING_EVENT_REFUSE
      ) {
        return state
          .set("isFetching", true)
          .set("newWizard", null)
          .set("snackErrorMsg", null)
          .set("snackInfoMsg", null);
      }
      break;

    case SET_EVENT_CHANNELS: {
      return state
        .set(
          "channelsSuggestions",
          Object.values(action.entities.channels).map(obj => {
            let suffixe;
            switch (obj.channeltype) {
              case "CampsiteEventChannel":
                suffixe = " - Campings [HapiVisit]";
                break;
              case "CityEventChannel":
                suffixe = " - Villes [HapiCity]";
                break;
              case "NeighborhoodEventChannel":
                suffixe = " - Voisins [HapiColibri]";
                break;
              default:
                suffixe = "";
            }
            return {
              name: obj.name + suffixe,
              id: obj._id
            };
          })
        )
        .set("channelsEntities", action.entities.channels);
    }

    case ADD_POST_CHANNEL: {
      const channelsSuggestions = state.channelsSuggestions.filter(
        (suggestion, index) => suggestion.id !== action.value.id
      );
      return state
        .set("channels", [...state.channels, action.value])
        .set("channelsIds", [...state.channelsIds, action.value.id])
        .set("publishedChannelErrorMsg", null)
        .set("channelsSuggestions", channelsSuggestions);
    }

    case DELETE_POST_CHANNEL: {
      const channelsSuggestions = [
        ...state.channelsSuggestions,
        state.channels[action.value]
      ];
      return state
        .set(
          "channels",
          state.channels.filter((channel, index) => index !== action.value)
        )
        .set(
          "channelsIds",
          state.channelsIds.filter((channel, index) => index !== action.value)
        )
        .set("publishedChannelErrorMsg", null)
        .set("channelsSuggestions", channelsSuggestions);
    }

    case CLEAR_SNACK_WARN_MSG:
      return state.set("warnMsgCleared", true).set("snackWarnMsg", null);

    case ADD_WIZARD: {
      if (!state.company) {
        return state
          .set("snackWarnMsg", t("event:completeprofile"))
          .set("warnMsgCleared", false);
      }
      return state.set("newWizard", "add").set("warnMsgCleared", false);
    }

    case SET_PROFILE_DETAILS: {
      const { company } = action.payload;

      return state.set("company", company);
    }

    case RESET_EVENT:
      return keepState(state);

    case SET_GET_EVENT_CAMPSITES_RESULT: {
      const { _id, channelsIds = [], status } = action.payload;

      // if (citiesIds && citiesIds.length > 0) {
      //   const cities = [];
      //   const suggestions = [
      //     ...state.suggestions.filter(obj => !citiesIds.includes(obj.id))
      //   ];
      //   // eslint-disable-next-line
      //   for (const cityId of citiesIds) {
      //     const elem = {
      //       position: {
      //         lat: state.citiesEntities[cityId].latitude,
      //         lng: state.citiesEntities[cityId].longitude
      //       },
      //       showInfo: true,
      //       name: state.citiesEntities[cityId].name,
      //       id: state.citiesEntities[cityId]._id,
      //       icon: state.citiesEntities[cityId].subscribe
      //         ? "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_v_L_8x.png&scale=1.2"
      //         : "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_search_v_L_8x.png&scale=1.2"
      //     };
      //     cities.push(elem);
      //     elem.showInfo = false;
      //     suggestions.push(elem);
      //   }
      //   return state
      //     .set("_id", _id)
      //     .set("citiesIds", citiesIds)
      //     .set("cities", cities)
      //     .set("suggestions", suggestions)
      //     .set("status", status)
      //     .set("newWizard", "publish");
      // } else {
      //   const campsites = [];
      //   const suggestions = [
      //     ...state.suggestions.filter(obj => !campsitesIds.includes(obj.id))
      //   ];
      //   // eslint-disable-next-line
      //   for (const communityId of campsitesIds) {
      //     const elem = {
      //       position: {
      //         lat: state.campsitesEntities[communityId].latitude,
      //         lng: state.campsitesEntities[communityId].longitude
      //       },
      //       showInfo: true,
      //       name: state.campsitesEntities[communityId].name,
      //       id: state.campsitesEntities[communityId]._id,
      //       icon: state.campsitesEntities[communityId].subscribe
      //         ? "https://mt.google.com/vt/icon/name=icons/spotlight/camping_v_L_8x.png&scale=1.2"
      //         : "https://mt.google.com/vt/icon/name=icons/spotlight/camping_search_v_L_8x.png&scale=1.2"
      //     };
      //     campsites.push(elem);
      //     elem.showInfo = false;
      //     suggestions.push(elem);
      //   }

      const channelsSuggestions = [...state.channelsSuggestions];
      const channels = [];
      if (channelsIds.length > 0) {
        for (const channelId of channelsIds) {
          let suffixe;
          switch (state.channelsEntities[channelId].channeltype) {
            case "CampsiteEventChannel":
              suffixe = " - Campings [HapiVisit]";
              break;
            case "CityEventChannel":
              suffixe = " - Villes [HapiCity]";
              break;
            case "NeighborhoodEventChannel":
              suffixe = " - Voisins [HapiColibri]";
              break;
            default:
              suffixe = "";
          }

          channels.push({
            name: state.channelsEntities[channelId].name + suffixe,
            id: state.channelsEntities[channelId]._id
          });
          const index = channelsSuggestions.findIndex(
            obj => obj.id === state.channelsEntities[channelId]._id
          );
          if (index >= 0) {
            channelsSuggestions.splice(index, 1);
          }
        }
      }

      return (
        state
          .set("_id", _id)
          // .set("campsitesIds", campsitesIds)
          // .set("campsites", campsites)
          // .set("suggestions", suggestions)
          .set("status", status)
          .set("newWizard", "publish")
          .set("channelsIds", channelsIds)
          .set("channels", channels)
          .set("channelsSuggestions", channelsSuggestions)
      );
    }

    case SET_POST_TYPE:
      return keepState(state)
        .set("eventTypeId", action.payload._id)
        .set("isGoodDealType", action.payload.name === "Bon plan")
        .set("isPromotionType", action.payload.name === "Promotion")
        .set(
          "isDateMandatory",
          action.payload.name === "Évènement" ||
            action.payload.name === "Spectacle" ||
            action.payload.name === "Concert" ||
            action.payload.name === "Exposition"
        );

    case SET_EVENT_TYPES: {
      return state.set("eventTypes", action.entities.eventTypes);
    }

    case SET_GET_EVENT_RESULT: {
      const {
        _id,
        url,
        title,
        description,
        lang,
        goodDealId,
        startDate,
        photoUri,
        eventTypeId,
        promotion
      } = action.payload;

      return state
        .set("_id", _id)
        .set("url", url)
        .set("title", title)
        .set("description", description)
        .set("lang", lang)
        .set("goodDealId", goodDealId)
        .set("startDate", startDate ? moment(startDate) : null)
        .set("photoUri", photoUri)
        .set("eventTypeId", eventTypeId)
        .set("promotion", promotion)
        .set("newWizard", "update")
        .set(
          "isGoodDealType",
          state.eventTypes[eventTypeId].name === "Bon plan"
        )
        .set(
          "isPromotionType",
          state.eventTypes[eventTypeId].name === "Promotion"
        )
        .set(
          "isDateMandatory",
          state.eventTypes[eventTypeId].name === "Évènement" ||
            state.eventTypes[eventTypeId].name === "Spectacle" ||
            state.eventTypes[eventTypeId].name === "Concert" ||
            state.eventTypes[eventTypeId].name === "Exposition"
        );
    }

    case SET_OWN_GOOD_DEAL_TITLES_RESULT:
      return state.set("goodDealsTitles", action.entities.goodDeals);

    // case SET_CAMPSITES: {
    //   return state
    //     .set("suggestions", [
    //       ...state.suggestions,
    //       ... Object.values(action.entities.campsites).map(obj => ({
    //         position: { lat: obj.latitude, lng: obj.longitude },
    //         showInfo: true,
    //         name: obj.name,
    //         id: obj._id,
    //         icon: obj.subscribe
    //           ? "https://mt.google.com/vt/icon/name=icons/spotlight/camping_v_L_8x.png&scale=1.2"
    //           : "https://mt.google.com/vt/icon/name=icons/spotlight/camping_search_v_L_8x.png&scale=1.2"
    //       }))
    //     ])
    //     .set("campsitesEntities", action.entities.campsites);
    // }

    case SET_CITIES: {
      return state
        .set(
          "suggestions",
          Object.values(action.entities.cities).map(obj => ({
            position: { lat: obj.latitude, lng: obj.longitude },
            showInfo: true,
            name: obj.name,
            id: obj._id,
            icon: obj.subscribe
              ? "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_v_L_8x.png&scale=1.2"
              : "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_search_v_L_8x.png&scale=1.2"
          }))
        )
        .set("citiesEntities", action.entities.cities);
    }

    case ADD_POST_CAMPSITE: {
      const suggestions = [
        ...state.suggestions.filter(obj => obj.id !== action.value.id)
      ];
      const campsite = {
        ...state.suggestions.filter(obj => obj.id === action.value.id)[0]
      };
      campsite.showInfo = false;
      suggestions.push(campsite);
      if (
        action.value.position &&
        !state.campsitesIds.includes(action.value.id)
      ) {
        return state
          .set("campsites", [...state.campsites, action.value])
          .set("campsitesIds", [...state.campsitesIds, action.value.id])
          .set("suggestions", suggestions)
          .set("campsiteErrorMsg", null);
      } else {
        return state
          .set("suggestions", suggestions)
          .set("campsiteErrorMsg", null);
      }
    }

    case ADD_POST_CITY: {
      const suggestions = [
        ...state.suggestions.filter(obj => obj.id !== action.value.id)
      ];
      const city = {
        ...state.suggestions.filter(obj => obj.id === action.value.id)[0]
      };
      city.showInfo = false;
      suggestions.push(city);
      if (action.value.position && !state.citiesIds.includes(action.value.id)) {
        return state
          .set("cities", [...state.cities, action.value])
          .set("citiesIds", [...state.citiesIds, action.value.id])
          .set("suggestions", suggestions)
          .set("cityErrorMsg", null);
      } else {
        return state.set("suggestions", suggestions).set("cityErrorMsg", null);
      }
    }

    case DELETE_POST_CAMPSITE: {
      const suggestions = [
        ...state.suggestions.filter(
          obj => obj.id !== state.campsitesIds[action.value]
        )
      ];
      const campsite = { ...state.campsites[action.value] };
      campsite.showInfo = true;
      suggestions.push(campsite);
      return state
        .set(
          "campsites",
          state.campsites.filter((campsite, index) => index !== action.value)
        )
        .set(
          "campsitesIds",
          state.campsitesIds.filter((campsite, index) => index !== action.value)
        )
        .set("suggestions", suggestions)
        .set("campsiteErrorMsg", null);
    }

    case DELETE_POST_CITY: {
      const suggestions = [
        ...state.suggestions.filter(
          obj => obj.id !== state.citiesIds[action.value]
        )
      ];
      const city = { ...state.cities[action.value] };
      city.showInfo = true;
      suggestions.push(city);
      return state
        .set(
          "cities",
          state.cities.filter((city, index) => index !== action.value)
        )
        .set(
          "citiesIds",
          state.citiesIds.filter((city, index) => index !== action.value)
        )
        .set("suggestions", suggestions)
        .set("cityErrorMsg", null);
    }

    case MISSING_POST_CAMPSITE:
      return state.set("campsiteErrorMsg", t("event:missingcampsite"));

    case MISSING_POST_CITY:
      return state.set("cityErrorMsg", t("event:missingcity"));

    case MISSING_POST_CHANNEL:
      return state.set("publishedChannelErrorMsg", t("event:missingchannel"));

    case SET_HOME_EVENTS_RESULT:
    case SET_OWN_EVENTS_RESULT: {
      const { events, infospages } = action.entities;
      const { page, hasNextPage, totalPages, totalDocs, limit } = infospages[
        "1"
      ];
      return state
        .set("events", events ? events : {})
        .set("page", page)
        .set("limit", limit)
        .set("hasNextPage", hasNextPage)
        .set("totalPages", totalPages)
        .set("totalDocs", totalDocs);
    }

    case SET_POST_SORTED: {
      return state.set("sorted", action.value);
    }

    case SET_MODERATING_EVENT_ACCEPT_RESULT:
      return state.set("snackInfoMsg", t("event:acceptsuccess"));

    case SET_MODERATING_EVENT_ACCEPT_FAILURE:
      return state.set("snackErrorMsg", t("event:accepterror"));

    case SET_MODERATING_EVENT_REFUSE_RESULT:
      return state.set("snackInfoMsg", t("event:refusesuccess"));

    case SET_MODERATING_EVENT_REFUSE_FAILURE:
      return state.set("snackErrorMsg", t("event:refuseerror"));

    case SET_DELETE_EVENT_RESULT:
      return state.set("snackInfoMsg", t("event:deletesuccess"));

    case SET_DELETE_EVENT_FAILURE:
      return state.set("snackErrorMsg", t("event:deleteerror"));

    case SET_POST_DESCRIPTION_ERROR:
      return state.set("descriptionErrorMsg", t("event:descriptionmandatory"));

    case SET_POST_TYPE_ERROR:
      return state.set("typeErrorMsg", t("event:typemandatory"));

    case SET_POST_TITLE_ERROR:
      return state.set("titleErrorMsg", t("event:titlemandatory"));

    case SET_POST_GOODDEAL_ERROR:
      return state.set("goodDealErrorMsg", t("event:gooddealmandatory"));

    case SET_POST_START_DATE_ERROR:
      return state.set("startDateErrorMsg", t("event:datemandatory"));

    case SET_POST_PROMOTION_ERROR:
      return state.set("promotionErrorMsg", t("event:promotionmandatory"));

    case SET_POST_DESCRIPTION_FR:
      return state
        .set("description", action.value)
        .set("descriptionErrorMsg", null);

    case SET_POST_DESCRIPTION_EN: {
      const en = state.lang
        ? { ...state.lang.en, description: action.value }
        : { description: action.value };
      return state.set("lang", { en });
    }

    case SET_POST_PROMOTION_EN: {
      const en = state.lang
        ? { ...state.lang.en, promotion: action.value }
        : { promotion: action.value };
      return state.set("lang", { en });
    }

    case SET_POST_TITLE_FR:
      return state.set("title", action.value).set("titleErrorMsg", null);

    case SET_POST_PROMOTION_FR:
      return state.set("promotion", action.value);

    case SET_POST_TITLE_EN: {
      const en = state.lang
        ? { ...state.lang.en, title: action.value }
        : { title: action.value };
      return state.set("lang", { en });
    }

    case SET_POST_FILES_IMAGES:
      return state.set("files", action.payload).set("photoUri", null);

    case REMOVE_POST_IMAGE:
      return state.set("files", []).set("photoUri", null);

    case SET_PUBLISH_EVENT_RESULT:
      return keepState(state).set(
        "snackInfoMsg",
        action.payload.status === PUBLISHED
          ? t("event:publishsuccess")
          : t("gooddeal:moderatesuccess")
      );

    case SET_ADD_EVENT_RESULT:
      return keepState(state).set("snackInfoMsg", t("event:addsuccess"));

    case SET_UPDATE_EVENT_RESULT:
      return keepState(state).set("snackInfoMsg", t("event:updatesuccess"));

    case SET_PUBLISH_EVENT_FAILURE:
      return state.set("snackErrorMsg", t("event:publisherror"));

    case SET_ADD_EVENT_FAILURE:
      return state.set("snackErrorMsg", t("event:adderror"));

    case SET_UPDATE_EVENT_FAILURE:
      return state.set("snackErrorMsg", t("event:updateerror"));

    case SET_POST_URL:
      return state
        .set("url", action.payload)
        .set("urlErrorMsg", null)
        .set("urlState", verifyUrl(action.payload) ? "success" : "error")
        .set("goodDealId", null);

    case SET_POST_GOODDEAL:
      return (
        state
          .set("goodDealId", action.payload ? action.payload._id : null)
          .set("title", action.payload ? action.payload.title : null)
          // .set("description", action.payload ? action.payload.description : null)
          // .set("lang", action.payload ? action.payload.lang : null)
          .set("goodDealErrorMsg", null)
          .set(
            "photoUri",
            action.payload
              ? state.goodDealsTitles[action.payload._id].photoUri
              : null
          )
      );

    case SET_POST_URL_ERROR:
      return state.set("urlErrorMsg", t("event:invalidurl"));

    case CLEAR_POST_URL:
      return state
        .set("urlErrorMsg", null)
        .set("url", null)
        .set("urlState", null);

    case SET_POST_DATE:
      return state
        .set(
          "startDate",
          action.payload instanceof moment && !isNaN(action.payload)
            ? action.payload
            : null
        )
        .set("startDateErrorMsg", null);

    case API_END:
      if (
        action.payload === FETCH_PUBLISH_EVENT ||
        action.payload === FETCH_ADD_EVENT ||
        action.payload === FETCH_UPDATE_EVENT ||
        action.payload === FETCH_DELETE_EVENT ||
        action.payload === FETCH_GET_OWN_GOOD_DEALS_TITLES ||
        action.payload === FETCH_GET_EVENT ||
        action.payload === FETCH_GET_EVENT_CAMPSITES ||
        action.payload === FETCH_MODERATING_EVENT_ACCEPT ||
        action.payload === FETCH_MODERATING_EVENT_REFUSE
      ) {
        return state.set("isFetching", false);
      }
      if (
        action.payload === FETCH_GET_HOME_EVENTS ||
        action.payload === FETCH_GET_OWN_EVENTS
      ) {
        return state.set("isFetchingList", false);
      }
      break;

    default:
      return state;
  }
  return state;
}
