import {
  FETCH_GET_CAMPSITE_CHANNELS,
  SET_CAMPSITE_CHANNELS,
  SET_CAMPSITE_CHANNELS_FAILURE,
  FETCH_GET_CITY_CHANNELS,
  SET_CITY_CHANNELS,
  SET_CITY_CHANNELS_FAILURE,
  FETCH_GET_NEIGHBORHOOD_CHANNELS,
  SET_NEIGHBORHOOD_CHANNELS,
  SET_NEIGHBORHOOD_CHANNELS_FAILURE,
  FETCH_GET_GOODDEAL_CHANNELS,
  SET_GOODDEAL_CHANNELS,
  SET_GOODDEAL_CHANNELS_FAILURE,
  FETCH_GET_EVENT_CHANNELS,
  SET_EVENT_CHANNELS,
  SET_EVENT_CHANNELS_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const channels = new schema.Entity(
  "channels",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchGetCampsiteChannels() {
  return apiAction({
    url: "/channels/campsite",
    method: "GET",
    schema: [channels],
    onSuccess: setCampsiteChannels,
    onFailure: setCampsiteChannelsFailure,
    label: FETCH_GET_CAMPSITE_CHANNELS
  });
}

function setCampsiteChannels(normalized) {
  return {
    type: SET_CAMPSITE_CHANNELS,
    entities: normalized.entities
  };
}

function setCampsiteChannelsFailure(data) {
  return {
    type: SET_CAMPSITE_CHANNELS_FAILURE,
    payload: data
  };
}

export function fetchGetCityChannels() {
  return apiAction({
    url: "/channels/city",
    method: "GET",
    schema: [channels],
    onSuccess: setCityChannels,
    onFailure: setCityChannelsFailure,
    label: FETCH_GET_CITY_CHANNELS
  });
}

function setCityChannels(normalized) {
  return {
    type: SET_CITY_CHANNELS,
    entities: normalized.entities
  };
}

function setCityChannelsFailure(data) {
  return {
    type: SET_CITY_CHANNELS_FAILURE,
    payload: data
  };
}

export function fetchGetNeighborhoodChannels() {
  return apiAction({
    url: "/channels/neighborhood",
    method: "GET",
    schema: [channels],
    onSuccess: setNeighborhoodChannels,
    onFailure: setNeighborhoodChannelsFailure,
    label: FETCH_GET_NEIGHBORHOOD_CHANNELS
  });
}

function setNeighborhoodChannels(normalized) {
  return {
    type: SET_NEIGHBORHOOD_CHANNELS,
    entities: normalized.entities
  };
}

function setNeighborhoodChannelsFailure(data) {
  return {
    type: SET_NEIGHBORHOOD_CHANNELS_FAILURE,
    payload: data
  };
}

export function fetchGetGooddealChannels() {
  return apiAction({
    url: "/channels/gooddeal",
    method: "GET",
    schema: [channels],
    onSuccess: setGooddealChannels,
    onFailure: setGooddealChannelsFailure,
    label: FETCH_GET_GOODDEAL_CHANNELS
  });
}

function setGooddealChannels(normalized) {
  return {
    type: SET_GOODDEAL_CHANNELS,
    entities: normalized.entities
  };
}

function setGooddealChannelsFailure(data) {
  return {
    type: SET_GOODDEAL_CHANNELS_FAILURE,
    payload: data
  };
}

export function fetchGetEventChannels() {
  return apiAction({
    url: "/channels/event",
    method: "GET",
    schema: [channels],
    onSuccess: setEventChannels,
    onFailure: setEventChannelsFailure,
    label: FETCH_GET_EVENT_CHANNELS
  });
}

function setEventChannels(normalized) {
  return {
    type: SET_EVENT_CHANNELS,
    entities: normalized.entities
  };
}

function setEventChannelsFailure(data) {
  return {
    type: SET_EVENT_CHANNELS_FAILURE,
    payload: data
  };
}
