/*eslint-disable*/
import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Bounce } from "react-activity";
import "react-activity/dist/react-activity.css";

export default function Activity() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Bounce />
      </GridItem>
    </GridContainer>
  );
}
