// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {
  SET_POST_DESCRIPTION_ERROR,
  SET_POST_TITLE_ERROR,
  SET_POST_DESCRIPTION_FR,
  SET_POST_TITLE_FR,
  SET_POST_PROMOTION_FR,
  SET_POST_PROMOTION_ERROR
} from "actions/types";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle
};

class Step3 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const {
      dispatch,
      description,
      title,
      isPromotionType,
      promotion
    } = this.props;

    let success = true;
    if (!title) {
      dispatch({
        type: SET_POST_TITLE_ERROR
      });
      success = false;
    }
    if (!description) {
      dispatch({
        type: SET_POST_DESCRIPTION_ERROR
      });
      success = false;
    }

    if (isPromotionType && !promotion) {
      dispatch({
        type: SET_POST_PROMOTION_ERROR
      });
      success = false;
    }

    return success;
  }

  render() {
    const {
      dispatch,
      description,
      descriptionErrorMsg,
      title,
      titleErrorMsg,
      promotion,
      isPromotionType,
      promotionErrorMsg
    } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Titre *"
            id="title-fr"
            formControlProps={{
              fullWidth: true
            }}
            error={titleErrorMsg !== null}
            helperText={titleErrorMsg}
            inputProps={{
              value: title ? title : "",
              onChange: e =>
                dispatch({
                  type: SET_POST_TITLE_FR,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Description *"
            id="description-fr"
            formControlProps={{
              fullWidth: true
            }}
            error={descriptionErrorMsg !== null}
            helperText={descriptionErrorMsg}
            inputProps={{
              value: description ? description : "",
              onChange: e =>
                dispatch({
                  type: SET_POST_DESCRIPTION_FR,
                  value: e.target.value
                }),
              multiline: true,
              rows: 5
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText={"Promotion" + (isPromotionType ? " *" : "")}
            id="promotion"
            formControlProps={{
              fullWidth: true
            }}
            error={promotionErrorMsg !== null}
            helperText={promotionErrorMsg}
            inputProps={{
              value: promotion ? promotion : "",
              onChange: e =>
                dispatch({
                  type: SET_POST_PROMOTION_FR,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    description,
    descriptionErrorMsg,
    title,
    titleErrorMsg,
    promotion,
    isPromotionType,
    promotionErrorMsg
  } = state.eventReducer;

  return {
    description,
    descriptionErrorMsg,
    title,
    titleErrorMsg,
    promotion,
    isPromotionType,
    promotionErrorMsg
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step3));
