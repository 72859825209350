import {
  whiteColor,
  beigeColor,
  primaryColor,
  blueColor,
  roseColor,
  brownColor,
  darkGrayColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

export const flex1 = "flex1",
  flex = "flex",
  visibilityHidden = "visibilityHidden",
  row = "row",
  column = "column",
  bgPrimary = "bgPrimary",
  bgBeige = "bgBeige",
  bgGray = "bgGray",
  bgWhite = "bgWhite",
  darkGray = "darkGray",
  blue = "blue",
  orange = "orange",
  rose = "rose",
  white = "white",
  textCenter = "textCenter",
  floatRight = "floatRight",
  alignItemsCenter = "alignItemsCenter",
  alignItemsSpaceBetween = "alignItemsSpaceBetween",
  alignItemsSpaceAround = "alignItemsSpaceAround",
  justifyContentCenter = "justifyContentCenter",
  justifyContentSpaceBetween = "justifyContentSpaceBetween",
  justifyContentSpaceAround = "justifyContentSpaceAround",
  bold = "bold",
  h100 = "h100",
  w100 = "w100",
  m20 = "m20",
  mb20 = "mb20",
  p20 = "p20",
  ph10 = "ph10",
  ph20 = "ph20",
  pv20 = "pv20",
  pb20 = "pb20",
  pb50 = "pb50",
  pt20 = "pt20",
  pt30 = "pt30",
  pl20 = "pl20",
  pl40 = "pl40",
  preWrap = "preWrap",
  rounded5 = "rounded5";

export function cla(classes, tabstyles) {
  let styles = "";
  for (const style of tabstyles) {
    styles += classes[style] + " ";
  }
  return styles;
}

const commonStyle = {
  visibilityHidden: {
    visibility: "hidden"
  },
  flex: {
    display: "flex"
  },
  flex1: {
    flex: 1
  },
  row: {
    flexDirection: "row"
  },
  column: {
    flexDirection: "column"
  },
  white: {
    color: whiteColor
  },
  bgPrimary: {
    backgroundColor: primaryColor[0]
  },
  bgGray: {
    backgroundColor: grayColor[15]
  },
  bgBeige: {
    backgroundColor: beigeColor[0]
  },
  bgWhite: {
    backgroundColor: whiteColor
  },
  darkGray: {
    color: darkGrayColor
  },
  orange: {
    color: primaryColor[0]
  },
  blue: {
    color: blueColor[0] + "!important"
  },
  brown: {
    color: brownColor[0]
  },
  rose: {
    color: roseColor[0]
  },
  textCenter: {
    textAlign: "center"
  },
  floatRight: {
    float: "right"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  alignItemsSpaceBetween: {
    alignItems: "space-between"
  },
  alignItemsSpaceAround: {
    alignItems: "space-around"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  justifyContentSpaceAround: {
    justifyContent: "space-around"
  },
  bold: {
    fontWeight: 900
  },
  h100: {
    height: "100%"
  },
  w100: {
    width: "100%"
  },
  m20: {
    margin: 20
  },
  mb20: {
    marginBottom: 20
  },
  p20: {
    padding: 20
  },
  ph20: {
    paddingLeft: 20,
    paddingRight: 20
  },
  ph10: {
    paddingLeft: 10,
    paddingRight: 10
  },
  pv20: {
    paddingTop: 20,
    paddingBottom: 20
  },
  pb20: {
    paddingBottom: 20
  },
  pb50: {
    paddingBottom: 50
  },
  pt20: {
    paddingTop: 20
  },
  pt30: {
    paddingTop: "30px!important"
  },
  pl20: {
    paddingLeft: 20
  },
  pl40: {
    paddingLeft: 40
  },
  preWrap: {
    whiteSpace: "pre-wrap"
  },
  rounded5: {
    borderRadius: 5
  }
};

export default commonStyle;
