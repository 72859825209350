import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

export const PhoneMask = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[
        "+",
        "3",
        "3",
        " ",
        "(",
        "0",
        ")",
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/
      ]}
    />
  );
};

PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired
};
