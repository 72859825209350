import {
  API,
  API_START,
  API_END,
  ACCESS_DENIED,
  API_ERROR,
  SERVER_IN_MAINTENANCE
} from "./types";
const { REACT_APP_SERVER_URL } = process.env;

export const apiStart = label => ({
  type: API_START,
  payload: label
});

export const apiEnd = label => ({
  type: API_END,
  payload: label
});

export const serverInMaintenance = label => ({
  type: SERVER_IN_MAINTENANCE,
  payload: label
});

export const accessDenied = url => ({
  type: ACCESS_DENIED,
  payload: {
    url
  }
});

export const apiError = (error, label) => ({
  type: API_ERROR,
  error: error && error.message ? error.message : error,
  payload: label
});

export function apiAction({
  customUrl = null,
  url = "",
  method = "GET",
  data = null,
  onSuccess = () => {},
  onFailure = () => {},
  accessToken = null,
  label = "",
  headers = null,
  schema = null,
  withCredentials = true
}) {
  if (headers) {
    headers["Content-Type"] = "application/json";
  }
  return {
    type: API,
    payload: {
      url: customUrl ? customUrl : REACT_APP_SERVER_URL + url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headers,
      schema,
      withCredentials
    }
  };
}
