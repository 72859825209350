import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  blueCardHeader,
  brownCardHeader,
  beigeCardHeader,
  grayCardHeader,
  grayColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";
const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$blueCardHeader,&$brownCardHeader,&$beigeCardHeader,&$grayCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    }
  },
  centeredCardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$blueCardHeader,&$brownCardHeader,&$beigeCardHeader,&$grayCardHeader": {
      borderRadius: "50%",
      padding: "15px",
      position: "absolute",
      left: "calc(50% - 38px)",
      top: "-38px",
      border: "5px solid" + whiteColor
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  blueCardHeader,
  brownCardHeader,
  beigeCardHeader,
  grayCardHeader
};

export default cardIconStyle;
