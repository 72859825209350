import withStyles from "@material-ui/core/styles/withStyles";

import { confirmAlert } from "react-confirm-alert";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Apartment from "@material-ui/icons/Apartment";
import WhereToVote from "@material-ui/icons/WhereToVote";

import {
  fetchGetNeighborhoodDetails,
  fetchGetNeighborhoods,
  fetchDeleteNeighborhood,
  fetchAddNeighborhood,
  fetchUpdateNeighborhood
} from "actions/neighborhood";
import { fetchGetCommunityDetails } from "actions/community";

import {
  RESET_NEIGHBORHOOD,
  SET_DELETE_NEIGHBORHOOD_CONFIRMATION_NAME
} from "actions/types";

// core components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import CustomInput from "components/CustomInput/CustomInput";
import Wizard from "components/DigiHapi/Wizard";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { beigeColor } from "assets/jss/material-dashboard-pro-react";

// core components
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import Components from "./NeighborhoodsPage";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import CardFooter from "components/Card/CardFooter";

const style = {
  ...extendedTablesStyle,
  customUI: {
    backgroundColor: beigeColor[1],
    maxWidth: "400px",
    borderRadius: "10px",
    padding: 10
  },
  w100: {
    width: "100%"
  },
  floatRight: {
    float: "right"
  },
  justifyContentSpaceAround: {
    display: "flex",
    justifyContent: "space-around"
  },
  descriptionContainer: {
    height: "60px"
  }
};

class NeighborhoodsWizard extends React.Component {
  fetchAddNeighborhood = () => {
    const {
      fetchAddNeighborhood,
      name,
      address,
      zipCode,
      city,
      webSiteUrl,
      mail,
      latitude,
      longitude,
      phone,
      fax,
      registerkey,
      files,
      channelsIds,
      citiesIds,
      building,
      residence,
      district
    } = this.props;

    fetchAddNeighborhood({
      name,
      address,
      zipCode,
      city,
      webSiteUrl,
      mail,
      latitude,
      longitude,
      phone,
      fax,
      registerkey,
      files,
      channelsIds,
      citiesIds,
      building,
      residence,
      district
    });
  };

  fetchUpdateNeighborhood = () => {
    const {
      fetchUpdateNeighborhood,
      _id,
      name,
      registerkey,
      address,
      zipCode,
      city,
      webSiteUrl,
      mail,
      latitude,
      longitude,
      phone,
      fax,
      files,
      logoUri,
      mapUri,
      mapSmallUri,
      equipmentsIds,
      channelsIds,
      citiesIds,
      building,
      residence,
      district
    } = this.props;

    fetchUpdateNeighborhood({
      _id,
      name,
      registerkey,
      address,
      zipCode,
      city,
      webSiteUrl,
      mail,
      latitude,
      longitude,
      phone,
      fax,
      files,
      logoUri,
      mapUri,
      mapSmallUri,
      equipmentsIds,
      channelsIds,
      citiesIds,
      building,
      residence,
      district
    });
  };

  render() {
    const { update, isFetching, onClose } = this.props;
    return (
      <GridItem xs={12} sm={12}>
        <Wizard
          onClose={onClose}
          isFetching={isFetching}
          validate
          steps={[
            {
              stepName: "Illustration",
              stepComponent: Step1,
              stepId: "picture"
            },
            {
              stepName: "Informations",
              stepComponent: Step2,
              stepId: "infos"
            },
            {
              stepName: "Localisation",
              stepComponent: Step3,
              stepId: "localisation"
            }
          ]}
          title={
            update
              ? "Mise à jour d'une communauté de voisinage"
              : "Ajout d'une communauté de voisinage"
          }
          subtitle=""
          finishButtonClick={
            update ? this.fetchUpdateNeighborhood : this.fetchAddNeighborhood
          }
        />
      </GridItem>
    );
  }
}

class NeighborhoodsPage extends React.Component {
  state = {
    br: false,
    color: "info",
    message: null,
    wizard: null,
    alert: null,
    name: ""
  };

  componentDidMount() {
    const { neighborhoods, fetchGetNeighborhoods } = this.props;
    if (!neighborhoods || Object.entries(neighborhoods).length === 0) {
      fetchGetNeighborhoods();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { snackErrorMsg, snackInfoMsg, newWizard } = this.props;

    if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
      this.autoCloseMessage();
    }

    if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
      this.setState({
        wizard: null
      });
      this.props.fetchGetNeighborhoods();
      this.autoCloseMessage();
    }

    if (newWizard && prevProps.newWizard !== newWizard) {
      this.addWizard(newWizard === "update");
    }
  }

  hideAlert = () => {
    this.setState({
      br: false
    });
  };

  autoCloseMessage = () => {
    const { snackErrorMsg, snackInfoMsg } = this.props;

    this.setState({
      br: true,
      message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
      color: snackInfoMsg ? "info" : "danger"
    });

    if (snackInfoMsg) {
      setTimeout(this.hideAlert, 5000);
    }
  };

  onClose = () => {
    this.setState({
      wizard: null
    });
    this.props.dispatch({
      type: RESET_NEIGHBORHOOD
    });
  };

  addWizard = (update = true) => {
    this.setState({
      wizard: (
        <Components.NeihborhoodsWizard update={update} onClose={this.onClose} />
      )
    });
  };

  changeCommunity = id => {
    this.props.fetchGetCommunityDetails(id);
  };

  editNeighborhood = id => {
    this.props.fetchGetNeighborhoodDetails(id);
  };

  deleteNeighborhood = id => {
    this.props.fetchDeleteNeighborhood(id, this.props.deleteConfirmationName);
  };

  getRoundButtons = (id, name) => {
    const { classes } = this.props;
    return [
      { color: "success", icon: WhereToVote, onClick: this.changeCommunity },
      { color: "brown", icon: Edit, onClick: this.editNeighborhood },
      { color: "danger", icon: Delete, onClick: this.delete }
    ].map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          disabled={this.state && this.state.wizard ? true : false}
          onClick={() => prop.onClick(id, name)}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  getNeighborhoods = () => {
    const { neighborhoods } = this.props;

    const neighborhoodsObj = Object.values(neighborhoods).map(
      ({ _id, name }) => {
        return {
          name,
          actions: (
            <div className="actions-right">
              {this.getRoundButtons(_id, name)}
            </div>
          )
        };
      }
    );
    return neighborhoodsObj;
  };

  delete = (neighborhoodId, neighborhoodName) => {
    const { classes, dispatch } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUI}>
            <h2>Confirmation</h2>
            <p>
              Êtes-vous sûr de vouloir supprimer la communauté de voisinage ?
            </p>
            {`Confirmer la suppression de la communauté de voisinage en saisisant le nom de la communauté ${neighborhoodName}`}
            <CustomInput
              labelText="Non de la communauté ..."
              id="neighborhood"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                // value: deleteConfirmationName,
                onChange: e =>
                  dispatch({
                    type: SET_DELETE_NEIGHBORHOOD_CONFIRMATION_NAME,
                    value: e.target.value
                  })
              }}
            />
            <div className={classes.justifyContentSpaceAround}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Annuler
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.deleteNeighborhood(neighborhoodId);
                  onClose();
                }}
              >
                Supprimer
              </Button>
            </div>
          </div>
        );
      }
    });
  };

  render() {
    const { color, message, alert } = this.state;
    const { classes, isFetchingList } = this.props;

    return (
      <GridContainer justify="center">
        {alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color="primary">
                <Apartment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Communautés de voisinage
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.getNeighborhoods()}
                filterable
                columns={[
                  {
                    Header: "Nom",
                    accessor: "name",
                    sortable: true,
                    filterable: false
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                loading={isFetchingList}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={false}
                previousText="Précédent"
                nextText="Suivant"
                rowsText="Lignes"
                noDataText="Aucunes communautés de voisinage"
                ofText="sur"
                loadingText="Chargement..."
                className="-striped -highlight"
              />
            </CardBody>
            <CardFooter product>
              <div className={classes.w100}>
                <Button
                  className={classes.floatRight}
                  color="primary"
                  onClick={() => this.addWizard(false)}
                  disabled={this.state.wizard}
                >
                  <Add /> {"Ajout d'une communauté de voisinage"}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {this.state.wizard}
        <Snackbar
          place="br"
          color={color}
          icon={AddAlert}
          message={message ? message : ""}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
      </GridContainer>
    );
  }
}

NeighborhoodsPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    _id,
    isFetching,
    isFetchingList,
    name,
    address,
    zipCode,
    city,
    webSiteUrl,
    mail,
    latitude,
    longitude,
    phone,
    fax,
    registerkey,
    files,
    snackInfoMsg,
    snackErrorMsg,
    logoUri,
    mapUri,
    mapSmallUri,
    newWizard,
    equipmentsIds,
    deleteConfirmationName,
    channelsIds,
    citiesIds,
    building,
    residence,
    district
  } = state.neighborhoodReducer;

  const { neighborhoods } = state.entitiesReducer;
  return {
    _id,
    isFetching,
    isFetchingList,
    name,
    address,
    zipCode,
    city,
    webSiteUrl,
    mail,
    latitude,
    longitude,
    phone,
    fax,
    registerkey,
    files,
    neighborhoods,
    snackInfoMsg,
    snackErrorMsg,
    logoUri,
    mapUri,
    mapSmallUri,
    newWizard,
    equipmentsIds,
    deleteConfirmationName,
    channelsIds,
    citiesIds,
    building,
    residence,
    district
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetNeighborhoods,
      fetchGetNeighborhoodDetails,
      fetchDeleteNeighborhood,
      fetchAddNeighborhood,
      fetchUpdateNeighborhood,
      fetchGetCommunityDetails
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default {
  NeighborhoodsPage: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(NeighborhoodsPage)),
  NeihborhoodsWizard: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(NeighborhoodsWizard))
};
