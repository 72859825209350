// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// material ui icons
import Close from "@material-ui/icons/Close";
import {
  fetchGetAddressUsingZipCode,
  fetchGetCityUsingZipCode,
  fetchGetAddressUsingCoordinates
} from "actions/datagouv";

import {
  CLEAR_PHONE,
  CLEAR_EMAIL,
  CLEAR_WEB_SITE_URL,
  SET_GOODDEAL_ADDRESS,
  SET_GOODDEAL_CITY,
  SET_GOODDEALS_PHONE,
  SET_GOODDEALS_PHONE_ERROR,
  SET_GOODDEAL_EMAIL,
  SET_GOODDEALS_EMAIL_ERROR,
  SET_WEB_SITE_URL,
  SET_WEB_SITE_URL_ERROR,
  SET_GOODDEAL_ZIP_CODE,
  SET_GOODDEAL_SELECTED_CITY,
  SET_GOODDEAL_SELECTED_ADDRESS,
  SET_GOODDEAL_SELECTED_ZIPCODE,
  SET_FB_URL,
  CLEAR_FB_URL,
  SET_FB_URL_ERROR,
  SET_ZIPCODE_ERROR,
  SET_CITY_ERROR
} from "actions/types";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react";
import { PhoneMask } from "utils/masks";

const OTHER = "Autre ...";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle,
  ...customSelectStyle,
  myInputLabelStyle: {
    ...defaultFont,
    textTransform: "none",
    fontWeight: "400",
    fontSize: "14px"
  },
  grayColor: {
    color: grayColor[3] + " !important"
  }
};

class Step4 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const {
      dispatch,
      phoneState,
      emailState,
      webSiteUrlState,
      fbUrlState,
      zipCode,
      city
    } = this.props;

    let success = true;
    if (phoneState === "error") {
      dispatch({
        type: SET_GOODDEALS_PHONE_ERROR
      });
      success = false;
    }
    if (emailState === "error") {
      dispatch({
        type: SET_GOODDEALS_EMAIL_ERROR
      });
      success = false;
    }
    if (webSiteUrlState === "error") {
      dispatch({
        type: SET_WEB_SITE_URL_ERROR
      });
      success = false;
    }
    if (fbUrlState === "error") {
      dispatch({
        type: SET_FB_URL_ERROR
      });
      success = false;
    }
    if (!zipCode) {
      dispatch({
        type: SET_ZIPCODE_ERROR
      });
      success = false;
    }
    if (!city) {
      dispatch({
        type: SET_CITY_ERROR
      });
      success = false;
    }
    return success;
  }

  renderItems = data => {
    const { classes } = this.props;
    const components = [];

    if (data) {
      // eslint-disable-next-line
      for (const obj of data) {
        components.push(
          <MenuItem
            key={obj}
            classes={{
              root: classes.selectMenuItem + " " + classes.myInputLabelStyle,
              selected:
                classes.selectMenuItemSelected + " " + classes.myInputLabelStyle
            }}
            value={obj}
          >
            {obj}
          </MenuItem>
        );
      }
    }
    return components;
  };

  render() {
    const {
      classes,
      dispatch,
      phone,
      phoneState,
      phoneErrorMsg,
      customAddress,
      address,
      addresses,
      selectedAddress,
      addressUpdated,
      zipCodes,
      selectedZipCode,
      customZipCode,
      zipCode,
      zipCodeErrorMsg,
      zipCodeUpdated,
      cities,
      selectedCity,
      customCity,
      cityErrorMsg,
      webSiteUrl,
      webSiteUrlState,
      webSiteUrlErrorMsg,
      fbUrl,
      fbUrlState,
      fbUrlErrorMsg,
      email,
      emailState,
      emailErrorMsg,
      longitude,
      latitude
    } = this.props;
    return (
      <GridContainer justify="center">
        {selectedAddress === OTHER ? (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Adresse"
              id="address"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: customAddress ? customAddress : "",
                onChange: e =>
                  dispatch({
                    type: SET_GOODDEAL_ADDRESS,
                    value: e.target.value
                  }),
                onBlur: () => {
                  if (addressUpdated && address) {
                    if (zipCode) {
                      this.props.fetchGetAddressUsingZipCode({
                        address,
                        zipCode
                      });
                    } else if (longitude && latitude) {
                      this.props.fetchGetAddressUsingCoordinates({
                        address,
                        latitude,
                        longitude
                      });
                    }
                  }
                }
              }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={
                  classes.selectLabel +
                  " " +
                  classes.myInputLabelStyle +
                  " " +
                  classes.grayColor
                }
              >
                Adresse
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select + " " + classes.myInputLabelStyle
                }}
                value={selectedAddress ? selectedAddress : ""}
                onChange={(e, value) =>
                  dispatch({
                    type: SET_GOODDEAL_SELECTED_ADDRESS,
                    payload: value.props.children
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  classes={{
                    root:
                      classes.selectMenuItem + " " + classes.myInputLabelStyle
                  }}
                >
                  {OTHER}
                </MenuItem>
                {this.renderItems(Object.keys(addresses))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        {selectedZipCode === OTHER ? (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Code postal *"
              id="zipcode"
              formControlProps={{
                fullWidth: true
              }}
              error={zipCodeErrorMsg !== null}
              helperText={zipCodeErrorMsg ? zipCodeErrorMsg : undefined}
              inputProps={{
                value: customZipCode ? customZipCode : "",
                onChange: e =>
                  dispatch({
                    type: SET_GOODDEAL_ZIP_CODE,
                    value: e.target.value
                  }),
                onBlur: () =>
                  zipCodeUpdated &&
                  zipCode &&
                  this.props.fetchGetCityUsingZipCode({ address, zipCode })
              }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={
                  classes.selectLabel +
                  " " +
                  classes.myInputLabelStyle +
                  " " +
                  classes.grayColor
                }
              >
                Code postal *
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select + " " + classes.myInputLabelStyle
                }}
                value={selectedZipCode ? selectedZipCode : ""}
                onChange={(e, value) =>
                  dispatch({
                    type: SET_GOODDEAL_SELECTED_ZIPCODE,
                    payload: value.props.children
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  classes={{
                    root:
                      classes.selectMenuItem + " " + classes.myInputLabelStyle
                  }}
                >
                  {OTHER}
                </MenuItem>
                {this.renderItems(Object.keys(zipCodes))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        {selectedCity === OTHER ? (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Ville *"
              id="city"
              error={cityErrorMsg !== null}
              helperText={cityErrorMsg ? cityErrorMsg : undefined}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: customCity ? customCity : "",
                onChange: e =>
                  dispatch({
                    type: SET_GOODDEAL_CITY,
                    value: e.target.value
                  })
              }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={
                  classes.selectLabel +
                  " " +
                  classes.myInputLabelStyle +
                  " " +
                  classes.grayColor
                }
              >
                {"Ville *"}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select + " " + classes.myInputLabelStyle
                }}
                value={selectedCity ? selectedCity : ""}
                onChange={(e, value) =>
                  dispatch({
                    type: SET_GOODDEAL_SELECTED_CITY,
                    payload: value.props.children
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  classes={{
                    root:
                      classes.selectMenuItem + " " + classes.myInputLabelStyle
                  }}
                >
                  {OTHER}
                </MenuItem>
                {this.renderItems(Object.keys(cities))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Téléphone"
            id="phone"
            formControlProps={{
              fullWidth: true
            }}
            success={phone && phoneState === "success"}
            error={phoneState === "error"}
            helperText={phoneErrorMsg ? phoneErrorMsg : undefined}
            inputProps={{
              placeholder: "+33 (0)X XX XX XX XX",
              inputComponent: PhoneMask,
              value: phone ? phone : "",
              onChange: e =>
                dispatch({
                  type: SET_GOODDEALS_PHONE,
                  value: e.target.value
                }),
              endAdornment: phone !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_PHONE
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Url site internet"
            id="url"
            formControlProps={{
              fullWidth: true
            }}
            helperText={webSiteUrlErrorMsg ? webSiteUrlErrorMsg : undefined}
            success={webSiteUrl && webSiteUrlState === "success"}
            error={webSiteUrlState === "error"}
            inputProps={{
              value: webSiteUrl ? webSiteUrl : "",
              onChange: e =>
                dispatch({
                  type: SET_WEB_SITE_URL,
                  value: e.target.value
                }),
              type: "text",
              endAdornment: webSiteUrl !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_WEB_SITE_URL
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Url page Facebook"
            id="url"
            formControlProps={{
              fullWidth: true
            }}
            helperText={fbUrlErrorMsg ? fbUrlErrorMsg : undefined}
            success={fbUrl && fbUrlState === "success"}
            error={fbUrlState === "error"}
            inputProps={{
              value: fbUrl ? fbUrl : "",
              onChange: e =>
                dispatch({
                  type: SET_FB_URL,
                  value: e.target.value
                }),
              type: "text",
              endAdornment: fbUrl !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_FB_URL
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Email de contact"
            id="contact"
            formControlProps={{
              fullWidth: true
            }}
            helperText={emailErrorMsg ? emailErrorMsg : undefined}
            success={email && emailState === "success"}
            error={emailState === "error"}
            inputProps={{
              value: email ? email : "",
              onChange: e =>
                dispatch({
                  type: SET_GOODDEAL_EMAIL,
                  value: e.target.value
                }),
              type: "text",
              endAdornment: email !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_EMAIL
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step4.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    addressUpdated,
    customAddress,
    addresses,
    selectedAddress,
    address,
    customZipCode,
    zipCodes,
    selectedZipCode,
    zipCode,
    zipCodeErrorMsg,
    zipCodeUpdated,
    customCity,
    city,
    cityErrorMsg,
    selectedCity,
    cities,
    webSiteUrl,
    webSiteUrlState,
    webSiteUrlErrorMsg,
    fbUrl,
    fbUrlState,
    fbUrlErrorMsg,
    latitude,
    longitude,
    phone,
    phoneErrorMsg,
    phoneState,
    email,
    emailState,
    emailErrorMsg
  } = state.gooddealReducer;
  return {
    address,
    customAddress,
    addresses,
    selectedAddress,
    addressUpdated,
    zipCode,
    zipCodeErrorMsg,
    customZipCode,
    zipCodes,
    selectedZipCode,
    zipCodeUpdated,
    customCity,
    city,
    cityErrorMsg,
    selectedCity,
    cities,
    webSiteUrl,
    webSiteUrlState,
    webSiteUrlErrorMsg,
    fbUrl,
    fbUrlState,
    fbUrlErrorMsg,
    latitude,
    longitude,
    phone,
    phoneErrorMsg,
    phoneState,
    email,
    emailState,
    emailErrorMsg
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetAddressUsingZipCode,
      fetchGetCityUsingZipCode,
      fetchGetAddressUsingCoordinates
    },
    dispatch
  );
  return { ...actions, dispatch };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Step4));
