// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import {
  SET_TEMPLATE_NAME_ERROR,
  SET_TEMPLATE_DESCRIPTION_ERROR,
  SET_TEMPLATE_NAME_FR,
  SET_ACTIVITY_DESCRIPTION_FR,
  SET_ACTIVITY_POST_DESCRIPTION_FR,
  SET_CHOICES_TAGS,
  SELECT_ACTIVITY_CHOICE_FR,
  STEP2_VALIDATED,
  SET_ACTIVITY_GOODDEAL_DESCRIPTION_FR,
  SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_FR
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle,
  ...customSelectStyle,
  ...regularFormsStyle
};

class Step2_Activities extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const { dispatch, name, choices, description } = this.props;

    let success = true;
    if (!name) {
      dispatch({
        type: SET_TEMPLATE_NAME_ERROR
      });
      success = false;
    }

    if (choices) {
      for (const choice of Object.values(choices)) {
        if (!choice.description) {
          dispatch({
            type: SET_TEMPLATE_DESCRIPTION_ERROR,
            choiceId: choice.name
          });
          success = false;
          break;
        }
      }
    } else if (!description) {
      dispatch({
        type: SET_TEMPLATE_DESCRIPTION_ERROR
      });
      success = false;
    }

    if (success) {
      dispatch({
        type: STEP2_VALIDATED
      });
    }

    return success;
  }

  handleTags = choices => {
    const { dispatch } = this.props;

    dispatch({
      type: SET_CHOICES_TAGS,
      payload: choices
    });
  };

  renderMenuItems = choices => {
    const { classes } = this.props;
    const components = [];

    // eslint-disable-next-line
    for (const obj of Object.values(choices)) {
      components.push(
        <MenuItem
          key={obj.name}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={obj.name}
        >
          {obj.name}
        </MenuItem>
      );
    }
    return components;
  };

  render() {
    const {
      classes,
      dispatch,
      nameErrorMsg,
      descriptionErrorMsg,
      name,
      choices,
      choicesTags,
      choiceId,
      description,
      postDescription,
      gooddealDescription,
      gooddealPostDescription
    } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Nom *"
            id="name-fr"
            formControlProps={{
              fullWidth: true
            }}
            error={nameErrorMsg !== null}
            helperText={nameErrorMsg}
            inputProps={{
              required: true,
              value: name ? name : "",
              onChange: e =>
                dispatch({
                  type: SET_TEMPLATE_NAME_FR,
                  payload: e.target.value
                })
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <legend>Choix</legend>
          <TagsInput
            value={choicesTags}
            onChange={this.handleTags}
            tagProps={{
              className: "react-tagsinput-tag info"
            }}
            inputProps={{ placeholder: "Ajouter un choix" }}
          />
        </GridItem>
        {choices && (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Sélectionner le choix à mettre à jour
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={choiceId ? choiceId : ""}
                onChange={e =>
                  dispatch({
                    type: SELECT_ACTIVITY_CHOICE_FR,
                    payload: e.target.value
                  })
                }
                inputProps={{
                  required: true,
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choix
                </MenuItem>
                {this.renderMenuItems(choices)}
              </Select>
            </FormControl>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Description *"
            id="description-fr"
            formControlProps={{
              fullWidth: true
            }}
            error={descriptionErrorMsg !== null}
            helperText={descriptionErrorMsg}
            inputProps={{
              value: description ? description : "",
              required: true,
              onChange: e =>
                dispatch({
                  type: SET_ACTIVITY_DESCRIPTION_FR,
                  payload: e.target.value
                }),
              multiline: true,
              rows: 5
            }}
          />
          <CustomInput
            labelText="Post photo description"
            id="post-description-fr"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: postDescription ? postDescription : "",
              onChange: e =>
                dispatch({
                  type: SET_ACTIVITY_POST_DESCRIPTION_FR,
                  payload: e.target.value
                }),
              multiline: true,
              rows: 3
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Description pour bons plans"
            id="descrption-gooddeal-fr"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: gooddealDescription ? gooddealDescription : "",
              required: true,
              onChange: e =>
                dispatch({
                  type: SET_ACTIVITY_GOODDEAL_DESCRIPTION_FR,
                  payload: e.target.value
                }),
              multiline: true,
              rows: 5
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Description après photo pour bons plans"
            id="post-description-gooddeal-fr"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: gooddealPostDescription ? gooddealPostDescription : "",
              onChange: e =>
                dispatch({
                  type: SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_FR,
                  payload: e.target.value
                }),
              multiline: true,
              rows: 3
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step2_Activities.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    name,
    choices,
    nameErrorMsg,
    descriptionErrorMsg,
    choicesTags,
    choiceId,
    description,
    postDescription,
    gooddealDescription,
    gooddealPostDescription
  } = state.templateReducer;

  return {
    name,
    choices,
    nameErrorMsg,
    descriptionErrorMsg,
    choicesTags,
    choiceId,
    description,
    postDescription,
    gooddealDescription,
    gooddealPostDescription
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step2_Activities));
