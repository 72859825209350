import React, { useState, useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardFooter from "components/Card/CardFooter.js";
import Activity from "components/DigiHapi/Activity.js";

import {
  fetchLogin,
  fetchForgetPassword,
  fetchLoginWithFB,
  fetchLoginUsingToken
} from "../../actions/login";
import { fetchGetUser } from "../../actions/profile";
import {
  SET_EMAIL_OR_PSEUDO,
  SET_PASSWORD,
  CLEAR_VALIDATE,
  VALIDATE_INPUTS,
  SET_LOGIN_WITH_FB_RESULT,
  SET_LOGIN_WITH_FB_ERROR,
  ROLE_WEBMASTER,
  ROLE_ADMINISTRATOR,
  ROLE_EMPLOYEE,
  CLEAR_FORGET_PWD_VALIDATE,
  VALIDATE_FORGET_PWD_INPUT
} from "../../actions/types";
import { parse } from "search-params";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// Redux
import { useDispatch, useSelector } from "react-redux";

import styles from "../../assets/jss/DigiHapi/components/adminLoginPageStyle";

import FullPageImage from "../../components/DigiHapi/FullPageImage";
import CustomFilledInput from "../../components/DigiHapi/CustomFilledInput";
import HomeHeader from "components/DigiHapi/HomeHeader";
import HomeFooter from "components/DigiHapi/HomeFooter";

const { REACT_APP_FB_CLIENT_ID, REACT_APP_SERVER_URL } = process.env;

const useStyles = makeStyles(styles);

export default function AdminLoginPage(props) {
  const classes = useStyles();

  const { location, history } = props;

  const cNames = elts => {
    return classNames(elts.split(" ").map(elt => classes[elt]));
  };

  const dispatch = useDispatch();

  const {
    isFetching,
    emailOrPseudo,
    emailOrPseudoErrorMsg,
    password,
    passwordErrorMsg,
    valid,
    snackInfoMsg,
    snackErrorMsg,
    clearValidate,
    strategy,
    validForgetPwd,
    clearForgetPwdValidate
  } = useSelector(state => state.loginReducer);

  const { user } = useSelector(state => state.profileReducer);
  const error = emailOrPseudoErrorMsg || passwordErrorMsg || snackErrorMsg;

  useEffect(() => {
    if (location.search) {
      const { token, communityId } = parse(location.search);
      if (token) {
        dispatch(fetchLoginUsingToken({ token, communityId }));
      }
    } else {
      dispatch(fetchGetUser());
      const emailOrPseudo = localStorage.getItem("emailOrPseudo");
      if (emailOrPseudo) {
        dispatch({
          type: SET_EMAIL_OR_PSEUDO,
          value: emailOrPseudo
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clearForgetPwdValidate) {
      dispatch({
        type: VALIDATE_FORGET_PWD_INPUT
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearForgetPwdValidate]);

  useEffect(() => {
    if (clearValidate) {
      dispatch({
        type: VALIDATE_INPUTS
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearValidate]);

  useEffect(() => {
    if (valid) {
      dispatch(fetchLogin(emailOrPseudo, password));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  useEffect(() => {
    if (validForgetPwd) {
      dispatch(fetchForgetPassword(emailOrPseudo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validForgetPwd]);

  useEffect(() => {
    if (!error && user) {
      if (user.role && user.role === ROLE_WEBMASTER) {
        history.push("/webmaster/audits");
      } else if (
        user.role === ROLE_ADMINISTRATOR ||
        user.role === ROLE_EMPLOYEE
      ) {
        if (strategy === "jwt") {
          history.push("/team/change-password");
        } else {
          history.push("/team/community");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [bc, setBc] = useState(false);
  const [message, setMessage] = useState(null);
  const [color, setColor] = useState("info");

  useEffect(() => {
    if (snackInfoMsg || snackErrorMsg) {
      setBc(true);
      setMessage(snackInfoMsg ? snackInfoMsg : snackErrorMsg);
      setColor(snackInfoMsg ? "info" : "danger");
      if (snackInfoMsg) {
        setTimeout(() => setBc(false), 5000);
      }
    }
  }, [snackInfoMsg, snackErrorMsg]);

  const responseFacebook = response => {
    if (!response || !response.accessToken) {
      dispatch({
        type: SET_LOGIN_WITH_FB_ERROR,
        error: "Erreur lors de la connexion avec FB"
      });
      return;
    }
    dispatch({
      type: SET_LOGIN_WITH_FB_RESULT,
      data: response
    });
    dispatch(fetchLoginWithFB(null, response.accessToken, response.email));
  };

  return (
    <FullPageImage
      bgImage="home"
      child={
        <GridContainer justify="center">
          <HomeHeader bgDarkGray />

          <GridItem xs={12} sm={12} md={6} lg={4}>
            <form
              onSubmit={event => {
                event.preventDefault();
                dispatch({
                  type: CLEAR_VALIDATE
                });
              }}
            >
              <Card plain noaos>
                <CardBody className={classes.flexColumn}>
                  <div className={cNames("flex column alignItemsCenter")}>
                    <div className={classes.socialLine}>
                      <FacebookLogin
                        appId={REACT_APP_FB_CLIENT_ID}
                        className={classes.w100}
                        callback={responseFacebook}
                        redirectUri={REACT_APP_SERVER_URL + "/auth/connexion"}
                        fields="name,email,picture"
                        render={renderProps => (
                          <Button
                            className={classes.w100}
                            color="facebook"
                            disabled={isFetching}
                            onClick={renderProps.onClick}
                          >
                            <i className="fab fa-facebook-f" />
                            {"Continuer avec Facebook"}
                          </Button>
                        )}
                        disableMobileRedirect={true}
                      />
                    </div>
                    <h4 className={classes.socialTitle}>ou</h4>
                  </div>
                  <CustomFilledInput
                    labelText="Email"
                    id="email"
                    error={emailOrPseudoErrorMsg !== null}
                    helperText={
                      emailOrPseudoErrorMsg ? emailOrPseudoErrorMsg : undefined
                    }
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: emailOrPseudo ? emailOrPseudo : "",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      disabled: isFetching,
                      onChange: e =>
                        dispatch({
                          type: SET_EMAIL_OR_PSEUDO,
                          value: e.target.value
                        })
                    }}
                  />
                  <CustomFilledInput
                    labelText="Mot de passe"
                    id="password"
                    error={passwordErrorMsg !== null}
                    helperText={passwordErrorMsg ? passwordErrorMsg : undefined}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: password ? password : "",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                      disabled: isFetching,
                      onChange: e =>
                        dispatch({
                          type: SET_PASSWORD,
                          value: e.target.value
                        })
                    }}
                  />
                  <Snackbar
                    place="bc"
                    color={color}
                    icon={AddAlert}
                    message={message ? message : ""}
                    open={bc}
                    closeNotification={() => setBc(false)}
                    close
                  />
                </CardBody>
                <CardFooter className={cNames("justifyContentCenter column")}>
                  <Button
                    disabled={isFetching}
                    className={cNames("h50")}
                    type="submit"
                    color="primary"
                    size="lg"
                    block
                  >
                    {isFetching ? (
                      <Activity />
                    ) : (
                      <Icon className={classes.fontWeight900}>
                        arrow_forward
                      </Icon>
                    )}
                  </Button>
                  <Button
                    disabled={isFetching}
                    className={cNames(
                      "darkgrey fs16 textTransformNone mb60 hoverRed"
                    )}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_FORGET_PWD_VALIDATE
                      })
                    }
                    simple
                  >
                    {"Perte de mot de passe ?"}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
          <HomeFooter bgDarkGray />
        </GridContainer>
      }
    />
  );
}
