import {
  FETCH_ADD_TEMPLATE,
  SET_ADD_TEMPLATE_RESULT,
  SET_ADD_TEMPLATE_FAILURE,
  FETCH_GET_TEMPLATES_CATEGORIES_NAMES,
  FETCH_GET_TEMPLATES_CATEGORIES_IDS,
  SET_TEMPLATES_CATEGORIES_NAMES_RESULT,
  SET_TEMPLATES_CATEGORIES_IDS_RESULT,
  SET_TEMPLATES_DATA_FAILURE,
  FETCH_GET_TEMPLATES_DATES,
  SET_TEMPLATES_DATES_RESULT,
  FETCH_GET_TEMPLATES_TIMES,
  SET_TEMPLATES_TIMES_RESULT,
  FETCH_ADD_TEMPLATE_CATEGORY,
  SET_ADD_TEMPLATE_CATEGORY_RESULT,
  SET_ADD_TEMPLATE_CATEGORY_FAILURE,
  FETCH_GET_OWN_TEMPLATE_DETAILS,
  SET_GET_TEMPLATE_DETAILS_RESULT,
  SET_GET_TEMPLATE_DETAILS_FAILURE,
  FETCH_UPDATE_TEMPLATE,
  SET_UPDATE_TEMPLATE_RESULT,
  SET_UPDATE_TEMPLATE_FAILURE,
  FETCH_DELETE_TEMPLATE,
  SET_DELETE_TEMPLATE_RESULT,
  SET_DELETE_TEMPLATE_FAILURE,
  FETCH_DELETE_TEMPLATE_CATEGORY,
  SET_DELETE_TEMPLATE_CATEGORY_RESULT,
  SET_DELETE_TEMPLATE_CATEGORY_FAILURE,
  FETCH_UPDATE_TEMPLATE_CATEGORY,
  SET_UPDATE_TEMPLATE_CATEGORY_RESULT,
  SET_UPDATE_TEMPLATE_CATEGORY_FAILURE,
  FETCH_GET_TEMPLATES_CATEGORIES,
  SET_TEMPLATES_CATEGORIES,
  SET_TEMPLATES_CATEGORIES_FAILURE,
  FETCH_REORDER_TEMPLATES_CATEGORIES,
  SET_REORDER_TEMPLATES_CATEGORIES_RESULT,
  SET_REORDER_TEMPLATES_CATEGORIES_FAILURE,
  FETCH_GET_TEMPLATES,
  SET_TEMPLATES_RESULT,
  SET_TEMPLATES_FAILURE,
  FETCH_CLONE_TEMPLATE_CATEGORY,
  SET_CLONE_TEMPLATE_CATEGORY_RESULT,
  SET_CLONE_TEMPLATE_CATEGORY_FAILURE,
  FETCH_CLONE_TEMPLATE,
  SET_CLONE_TEMPLATE_RESULT,
  SET_CLONE_TEMPLATE_FAILURE,
  FETCH_GET_GOOD_DEALS_TEMPLATES,
  SET_GOOD_DEALS_TEMPLATES,
  SET_GOOD_DEALS_TEMPLATES_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

// Define a templates schema
const template = new schema.Entity(
  "templates",
  {},
  {
    idAttribute: "_id"
  }
);

const templateUsedByGooddeals = new schema.Entity(
  "templatesUsedByGooddeals",
  {},
  {
    idAttribute: "_id"
  }
);

// Define your categories
export const categorie = new schema.Entity(
  "categories",
  {
    templateIds: [template]
  },
  {
    idAttribute: "_id"
  }
);

const templateDates = new schema.Entity(
  "templateDates",
  {},
  {
    idAttribute: "_id"
  }
);

const templateTimes = new schema.Entity(
  "templateTimes",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchGetTemplatesCategoriesNames(type, communityId) {
  return apiAction({
    url: `/templates/categories/names?type=${type}${
      communityId ? "&communityId=" + communityId : ""
    }`,
    method: "GET",
    onSuccess: setTemplatesCategoriesNamesResult,
    onFailure: setTemplatesDataFailure,
    label: FETCH_GET_TEMPLATES_CATEGORIES_NAMES
  });
}

export function fetchGetTemplatesCategoriesIds(
  type,
  system = false,
  communityId
) {
  return apiAction({
    url: "/templates/categories/ids",
    method: "GET",
    data: {
      system: system ? true : "",
      type,
      communityId
    },
    onSuccess: setTemplatesCategoriesIdsResult,
    onFailure: setTemplatesDataFailure,
    label: FETCH_GET_TEMPLATES_CATEGORIES_IDS
  });
}

export function fetchGetTemplatesCategories(type, system, communityId) {
  return apiAction({
    url: `/templates/categories/order?type=${type}${
      system ? "&system=" + system : ""
    }${communityId ? "&communityId=" + communityId : ""}`,
    method: "GET",
    schema: [categorie],
    onSuccess: normalized => [setTemplatesCategories(normalized)],
    onFailure: setTemplatesCategoriesFailure,
    label: FETCH_GET_TEMPLATES_CATEGORIES
  });
}

function setTemplatesCategories(normalized) {
  return {
    type: SET_TEMPLATES_CATEGORIES,
    entities: normalized.entities
  };
}

function setTemplatesCategoriesFailure(error) {
  return {
    type: SET_TEMPLATES_CATEGORIES_FAILURE,
    payload: error
  };
}

export function fetchReorderTemplatesCategories(data) {
  return apiAction({
    url: "/templates/categories/order",
    method: "POST",
    data,
    onSuccess: setReorderTemplatesCategoriesResult,
    onFailure: setReorderTemplatesCategoriesFailure,
    label: FETCH_REORDER_TEMPLATES_CATEGORIES
  });
}

function setReorderTemplatesCategoriesResult(data) {
  return {
    type: SET_REORDER_TEMPLATES_CATEGORIES_RESULT,
    payload: data
  };
}

function setReorderTemplatesCategoriesFailure(error) {
  return {
    type: SET_REORDER_TEMPLATES_CATEGORIES_FAILURE,
    payload: error
  };
}

function setTemplatesCategoriesIdsResult(data) {
  return {
    type: SET_TEMPLATES_CATEGORIES_IDS_RESULT,
    payload: data
  };
}

function setTemplatesCategoriesNamesResult(data) {
  return {
    type: SET_TEMPLATES_CATEGORIES_NAMES_RESULT,
    payload: data
  };
}

export function fetchGetTemplatesDates() {
  return apiAction({
    url: "/templates/dates",
    method: "GET",
    schema: [templateDates],
    onSuccess: setTemplatesDates,
    onFailure: setTemplatesDataFailure,
    label: FETCH_GET_TEMPLATES_DATES
  });
}

function setTemplatesDates(normalized) {
  return {
    type: SET_TEMPLATES_DATES_RESULT,
    entities: normalized.entities
  };
}

export function fetchGetTemplatesTimes() {
  return apiAction({
    url: "/templates/times",
    method: "GET",
    schema: [templateTimes],
    onSuccess: setTemplatesTimes,
    onFailure: setTemplatesDataFailure,
    label: FETCH_GET_TEMPLATES_TIMES
  });
}

function setTemplatesTimes(normalized) {
  return {
    type: SET_TEMPLATES_TIMES_RESULT,
    entities: normalized.entities
  };
}

function setTemplatesDataFailure(error) {
  return {
    type: SET_TEMPLATES_DATA_FAILURE,
    payload: error
  };
}

export function fetchAddTemplate(template) {
  return apiAction({
    url: "/templates",
    method: "POST",
    data: template,
    onSuccess: setAddTemplateResult,
    onFailure: setAddTemplateFailure,
    label: FETCH_ADD_TEMPLATE
  });
}

function setAddTemplateResult(data) {
  return {
    type: SET_ADD_TEMPLATE_RESULT,
    payload: data
  };
}

function setAddTemplateFailure(error) {
  return {
    type: SET_ADD_TEMPLATE_FAILURE,
    payload: error
  };
}

export function fetchAddTemplateCategory(data) {
  return apiAction({
    url: "/templates/category",
    method: "POST",
    data,
    onSuccess: setAddTemplateCategoryResult,
    onFailure: setAddTemplateCategoryFailure,
    label: FETCH_ADD_TEMPLATE_CATEGORY
  });
}

function setAddTemplateCategoryResult(data) {
  return {
    type: SET_ADD_TEMPLATE_CATEGORY_RESULT,
    payload: data
  };
}

function setAddTemplateCategoryFailure(error) {
  return {
    type: SET_ADD_TEMPLATE_CATEGORY_FAILURE,
    payload: error
  };
}

export function fetchDeleteTemplateCategory(id) {
  return apiAction({
    url: "/templates/category/" + id,
    method: "DELETE",
    onSuccess: setDeleteTemplateCategoryResult,
    onFailure: setDeleteTemplateCategoryFailure,
    label: FETCH_DELETE_TEMPLATE_CATEGORY
  });
}

function setDeleteTemplateCategoryResult(data) {
  return {
    type: SET_DELETE_TEMPLATE_CATEGORY_RESULT,
    payload: data
  };
}

function setDeleteTemplateCategoryFailure(error) {
  return {
    type: SET_DELETE_TEMPLATE_CATEGORY_FAILURE,
    payload: error
  };
}

export function fetchCloneTemplateCategory(id, communityId) {
  return apiAction({
    url: "/templates/category/" + id,
    method: "POST",
    data: {
      communityId
    },
    onSuccess: setCloneTemplateCategoryResult,
    onFailure: setCloneTemplateCategoryFailure,
    label: FETCH_CLONE_TEMPLATE_CATEGORY
  });
}

function setCloneTemplateCategoryResult(data) {
  return {
    type: SET_CLONE_TEMPLATE_CATEGORY_RESULT,
    payload: data
  };
}

function setCloneTemplateCategoryFailure(error) {
  return {
    type: SET_CLONE_TEMPLATE_CATEGORY_FAILURE,
    payload: error
  };
}

export function fetchCloneTemplate(categoryId, id, communityId) {
  return apiAction({
    url: "/templates/template/" + categoryId + "/" + id,
    method: "POST",
    data: {
      communityId
    },
    onSuccess: setCloneTemplateResult,
    onFailure: setCloneTemplateFailure,
    label: FETCH_CLONE_TEMPLATE
  });
}

function setCloneTemplateResult(data) {
  return {
    type: SET_CLONE_TEMPLATE_RESULT,
    payload: data
  };
}

function setCloneTemplateFailure(error) {
  return {
    type: SET_CLONE_TEMPLATE_FAILURE,
    payload: error
  };
}

export function fetchUpdateTemplateCategory(id, name, lang) {
  return apiAction({
    url: "/templates/category/" + id,
    method: "PUT",
    data: {
      name,
      lang
    },
    onSuccess: setUpdateTemplateCategoryResult,
    onFailure: setUpdateTemplateCategoryFailure,
    label: FETCH_UPDATE_TEMPLATE_CATEGORY
  });
}

function setUpdateTemplateCategoryResult(data) {
  return {
    type: SET_UPDATE_TEMPLATE_CATEGORY_RESULT,
    payload: data
  };
}

function setUpdateTemplateCategoryFailure(error) {
  return {
    type: SET_UPDATE_TEMPLATE_CATEGORY_FAILURE,
    payload: error
  };
}

export function fetchGetTemplates(type, communityId) {
  return apiAction({
    url: `/templates?type=${type}${
      communityId ? "&communityId=" + communityId : ""
    }`,
    method: "GET",
    schema: [template],
    onSuccess: setTemplatesResult,
    onFailure: setTemplatesFailure,
    label: FETCH_GET_TEMPLATES
  });
}

function setTemplatesResult(normalized) {
  return {
    type: SET_TEMPLATES_RESULT,
    entities: normalized.entities
  };
}

function setTemplatesFailure(error) {
  return {
    type: SET_TEMPLATES_FAILURE,
    payload: error
  };
}

export function fetchGetTemplateDetails(id, communityId) {
  return apiAction({
    url: "/templates/own/" + id,
    data: {
      communityId
    },
    method: "GET",
    onSuccess: setGetTemplateDetailsResult,
    onFailure: setGetTemplateDetailsFailure,
    label: FETCH_GET_OWN_TEMPLATE_DETAILS
  });
}

function setGetTemplateDetailsResult(data) {
  return {
    type: SET_GET_TEMPLATE_DETAILS_RESULT,
    payload: data
  };
}

function setGetTemplateDetailsFailure(error) {
  return {
    type: SET_GET_TEMPLATE_DETAILS_FAILURE,
    payload: error
  };
}

export function fetchUpdateTemplate(template) {
  return apiAction({
    url: "/templates",
    method: "PUT",
    data: template,
    onSuccess: setUpdateTemplateResult,
    onFailure: setUpdateTemplateFailure,
    label: FETCH_UPDATE_TEMPLATE
  });
}

function setUpdateTemplateResult(data) {
  return {
    type: SET_UPDATE_TEMPLATE_RESULT,
    payload: data
  };
}

function setUpdateTemplateFailure(error) {
  return {
    type: SET_UPDATE_TEMPLATE_FAILURE,
    payload: error
  };
}

export function fetchDeleteTemplate(categoryId, id) {
  if (categoryId) {
    return apiAction({
      url: "/templates/template/" + categoryId + "/" + id,
      method: "DELETE",
      onSuccess: setDeleteTemplateResult,
      onFailure: setDeleteTemplateFailure,
      label: FETCH_DELETE_TEMPLATE
    });
  }
  return apiAction({
    url: "/templates/template/" + id,
    method: "DELETE",
    onSuccess: setDeleteTemplateResult,
    onFailure: setDeleteTemplateFailure,
    label: FETCH_DELETE_TEMPLATE
  });
}

function setDeleteTemplateResult(data) {
  return {
    type: SET_DELETE_TEMPLATE_RESULT,
    payload: data
  };
}

function setDeleteTemplateFailure(error) {
  return {
    type: SET_DELETE_TEMPLATE_FAILURE,
    payload: error
  };
}

export function fetchGetGoodDealsTemplates() {
  return apiAction({
    url: "/templates/usedByGooddeals",
    method: "GET",
    schema: [templateUsedByGooddeals],
    onSuccess: setGoodDealsTemplates,
    onFailure: setGoodDealsTemplatesFailure,
    label: FETCH_GET_GOOD_DEALS_TEMPLATES
  });
}

function setGoodDealsTemplates(normalized) {
  return {
    type: SET_GOOD_DEALS_TEMPLATES,
    entities: normalized.entities
  };
}

function setGoodDealsTemplatesFailure(error) {
  return {
    type: SET_GOOD_DEALS_TEMPLATES_FAILURE,
    payload: error
  };
}
