import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import Activity from "components/DigiHapi/Activity";
import Snackbar from "components/Snackbar/Snackbar";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import Language from "@material-ui/icons/Language";
import Apartment from "@material-ui/icons/Apartment";

import { MdLocationCity } from "react-icons/md";
import { GiCampingTent } from "react-icons/gi";

import { fetchGetCurrentCommunityDetails } from "actions/community";
import { fetchGetCityDetails } from "actions/city";
import { fetchGetCampsiteDetails } from "actions/campsite";
import { fetchGetNeighborhoodDetails } from "actions/neighborhood";

// Redux
import { useDispatch, useSelector } from "react-redux";

import {
  TYPE_CITY,
  TYPE_NEIGHBORHOOD,
  RESET_CAMPSITE,
  RESET_NEIGHBORHOOD,
  RESET_CITY,
  ROLE_ADMINISTRATOR,
  ROLE_WEBMASTER
} from "actions/types";
import Cities from "./Cities/CitiesPage";
import Campsites from "./Campsites/CampsitesPage";
import Neighborhoods from "./Neighborhoods/NeighborhoodsPage";

const styles = {
  ...dashboardStyle,
  image: {
    marginTop: 20,
    width: 200,
    height: 200
  },
  editButton: {
    position: "absolute",
    zIndex: "1",
    top: "-50px",
    width: "calc(100% - 60px)",
    left: "30px",
    right: "30px",
    textAlign: "right"
  }
};

const useStyles = makeStyles(styles);

export default function CommunityPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { community } = useSelector(state => state.entitiesReducer);

  const { user } = useSelector(state => state.profileReducer);

  let icon, resetType, component;

  switch (community.communitytype) {
    case TYPE_CITY:
      icon = <MdLocationCity />;
      resetType = RESET_CITY;
      component = <Cities.CitiesWizard />;
      break;
    case TYPE_NEIGHBORHOOD:
      icon = <Apartment />;
      resetType = RESET_NEIGHBORHOOD;
      component = <Neighborhoods.NeihborhoodsWizard />;
      break;
    default:
      icon = <GiCampingTent />;
      resetType = RESET_CAMPSITE;
      component = <Campsites.CampsitesWizard />;
      break;
  }

  const { isFetching, newWizard, snackInfoMsg, snackErrorMsg } = useSelector(
    state =>
      state[
        community.communitytype === TYPE_CITY
          ? "cityReducer"
          : community.communitytype === TYPE_NEIGHBORHOOD
          ? "neighborhoodReducer"
          : "campsiteReducer"
      ]
  );

  const [br, setBr] = useState(false);
  const [message, setMessage] = useState(null);
  const [color, setColor] = useState("info");
  const [wizard, setWizard] = useState(null);

  const fetchGetCommunityDetails = () => {
    switch (community.communitytype) {
      case TYPE_CITY:
        dispatch(fetchGetCityDetails(community._id));
        break;
      case TYPE_NEIGHBORHOOD:
        dispatch(fetchGetNeighborhoodDetails(community._id));
        break;
      default:
        dispatch(fetchGetCampsiteDetails(community._id));
        break;
    }
  };

  useEffect(() => {
    if (snackInfoMsg || snackErrorMsg) {
      if (snackInfoMsg) {
        setWizard(null);
        dispatch(fetchGetCurrentCommunityDetails());
      }
      setBr(true);
      setMessage(snackInfoMsg ? snackInfoMsg : snackErrorMsg);
      setColor(snackInfoMsg ? "info" : "danger");
      if (snackInfoMsg) {
        setTimeout(() => setBr(false), 5000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackInfoMsg, snackErrorMsg]);

  const onClose = () => {
    setWizard(null);
    dispatch({
      type: resetType
    });
  };

  useEffect(() => {
    if (newWizard) {
      const wizard = React.cloneElement(
        component,
        { update: true, onClose },
        null
      );
      setWizard(wizard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newWizard]);

  const {
    logoUri,
    name,
    phone,
    mail,
    webSiteUrl,
    qrcodeUri,
    address,
    zipCode,
    city
  } = community;

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={4}>
        {isFetching ? (
          <Activity />
        ) : (
          <Card className={classes.cardHover}>
            <CardHeader
              stats
              icon
              className={
                (user.role === ROLE_ADMINISTRATOR ||
                  user.role === ROLE_WEBMASTER) &&
                wizard === null &&
                classes.cardHeaderHover
              }
            >
              <CardIcon color={logoUri ? "beige" : "blue"}>
                {logoUri ? (
                  <img
                    style={{ height: "100px" }}
                    src={logoUri}
                    alt="Community logo"
                  />
                ) : (
                  icon
                )}
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <small>{name}</small>
              </h3>
              {qrcodeUri && (
                <img
                  className={classes.image}
                  src={qrcodeUri}
                  alt="Community qrcode"
                />
              )}
              {/* {(user.role === ROLE_ADMINISTRATOR ||
                user.role === ROLE_WEBMASTER) && (
                <Button
                  simple
                  color="primary"
                  disabled={wizard !== null}
                  // className={classes.editButton}
                  onClick={fetchGetCommunityDetails}
                >
                  <Edit />
                </Button>
              )} */}
            </CardHeader>
            <CardBody>
              <div className={classes.editButton}>
                {(user.role === ROLE_ADMINISTRATOR ||
                  user.role === ROLE_WEBMASTER) && (
                  <Tooltip
                    id="tooltip-update"
                    title="Modifier"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="brown"
                      simple
                      justIcon
                      disabled={wizard !== null}
                      onClick={fetchGetCommunityDetails}
                    >
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </CardBody>
            <CardFooter stats>
              <div className={classes.product}>
                {address && (
                  <div>
                    <p>{address}</p>
                  </div>
                )}
                {zipCode && city && (
                  <div>
                    <p>{zipCode + " " + city}</p>
                  </div>
                )}
                {phone && (
                  <div>
                    <Phone /> <a href={`tel:${phone}`}>{phone}</a>
                    <br />
                  </div>
                )}
                {mail && (
                  <div>
                    <Email /> <a href={`mailto:${mail}`}>{mail}</a>
                    <br />
                  </div>
                )}
                {webSiteUrl && (
                  <div>
                    <Language />{" "}
                    <a
                      href={webSiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {webSiteUrl}
                    </a>
                    <br />
                  </div>
                )}
              </div>
            </CardFooter>
          </Card>
        )}
      </GridItem>
      {wizard}
      <Snackbar
        place="br"
        color={color}
        icon={AddAlert}
        message={message ? message : ""}
        open={br}
        closeNotification={() => setBr(true)}
        close
      />
    </GridContainer>
  );
}
