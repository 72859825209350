import {
  API_END,
  API_START,
  FETCH_GET_EQUIPMENTS,
  RESET_EQUIPMENT,
  SET_EQUIPMENT,
  SET_EQUIPMENTS_RESULT,
  SET_EQUIPMENT_NAME_EN,
  SET_EQUIPMENT_NAME_ERROR,
  SET_EQUIPMENT_NAME_FR,
  FETCH_ADD_EQUIPMENT,
  SET_ADD_EQUIPMENT_RESULT,
  SET_ADD_EQUIPMENT_FAILURE,
  FETCH_UPDATE_EQUIPMENT,
  SET_UPDATE_EQUIPMENT_RESULT,
  SET_UPDATE_EQUIPMENT_FAILURE,
  FETCH_DELETE_EQUIPMENT,
  SET_DELETE_EQUIPMENT_RESULT,
  SET_DELETE_EQUIPMENT_FAILURE,
  SET_EQUIPMENT_ORDER,
  SET_EQUIPMENT_SYSTEM,
  EQUIPMENTS_ON_DRAG_END,
  FETCH_REORDER_EQUIPMENTS,
  SET_REORDER_EQUIPMENTS_RESULT,
  SET_REORDER_EQUIPMENTS_FAILURE,
  SET_EQUIPMENT_WIZARD_STEP,
  RESET_DATA
} from "../actions/types";
import { t } from "../services/i18n";

const initialState = {
  isFetchingList: false,
  isFetching: false,
  equipments: {},
  _id: null,
  name: null,
  lang: null,
  error: null,
  nameError: null,
  nameErrorMsg: null,
  snackInfoMsg: null,
  snackErrorMsg: null,
  order: 0,
  system: false,
  newWizard: null,
  areEquipmentsReordered: false,
  wizardCurrentStep: 0
};

export default function equipmentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (
        action.payload === FETCH_ADD_EQUIPMENT ||
        action.payload === FETCH_UPDATE_EQUIPMENT ||
        action.payload === FETCH_DELETE_EQUIPMENT ||
        action.payload === FETCH_REORDER_EQUIPMENTS
      ) {
        return {
          ...state,
          isFetching: true,
          snackInfoMsg: null,
          snackErrorMsg: null
        };
      }
      if (action.payload === FETCH_GET_EQUIPMENTS) {
        return {
          ...state,
          isFetchingList: true,
          newWizard: null,
          snackInfoMsg: null,
          snackErrorMsg: null,
          areEquipmentsReordered: false
        };
      }
      break;

    case RESET_DATA:
      return initialState;

    case RESET_EQUIPMENT:
      return { ...initialState, equipments: state.equipments };

    case EQUIPMENTS_ON_DRAG_END: {
      const { source, destination } = action.result;
      if (destination) {
        const equipmentsIds = Object.keys(state.equipments);
        // Add 1 to index due to unused items
        const equipmentId = equipmentsIds.splice(source.index, 1);
        equipmentsIds.splice(destination.index, 0, equipmentId);
        const equipments = {};
        // eslint-disable-next-line
        for (const id of equipmentsIds) {
          equipments[id] = { ...state.equipments[id] };
        }
        return {
          ...state,
          equipments: equipments,
          areEquipmentsReordered: true
        };
      }
      // dropped outside the list
      break;
    }

    case SET_EQUIPMENT_WIZARD_STEP:
      return {
        ...state,
        wizardCurrentStep: action.payload
      };

    case SET_EQUIPMENT_SYSTEM:
      return {
        ...state,
        system: action.payload,
        newWizard: state.newWizard ? state.newWizard : "add"
      };

    case SET_EQUIPMENT:
      return {
        ...state,
        _id: action.payload,
        newWizard: "update"
      };

    case SET_EQUIPMENT_NAME_ERROR:
      return {
        ...state,
        nameErrorMsg: t("template:namemandatory")
      };

    case SET_EQUIPMENTS_RESULT:
      return {
        ...state,
        equipments: action.entities.equipments
      };

    case SET_EQUIPMENT_NAME_FR:
      return {
        ...state,
        nameErrorMsg: null,
        name: action.payload
      };

    case SET_EQUIPMENT_ORDER:
      return {
        ...state,
        order: action.payload
      };

    case SET_EQUIPMENT_NAME_EN: {
      let en = state.lang ? state.lang.en : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            name: action.payload
          }
        };
      }
      return {
        ...state,
        lang: en
      };
    }

    case SET_REORDER_EQUIPMENTS_RESULT:
      return {
        ...initialState,
        snackInfoMsg: t("equipment:reordersuccess")
      };

    case SET_ADD_EQUIPMENT_RESULT:
      return {
        ...initialState,
        snackInfoMsg: t("equipment:addsuccess")
      };

    case SET_UPDATE_EQUIPMENT_RESULT:
      return {
        ...initialState,
        snackInfoMsg: t("equipment:updatesuccess")
      };

    case SET_DELETE_EQUIPMENT_RESULT:
      return {
        ...initialState,
        snackInfoMsg: t("equipment:deletesuccess")
      };

    case SET_REORDER_EQUIPMENTS_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("equipment:reordererror")
      };

    case SET_UPDATE_EQUIPMENT_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("equipment:updateerror")
      };

    case SET_DELETE_EQUIPMENT_FAILURE: {
      const errorMsg =
        action.error && action.error.message ? action.error.message : "";

      if (errorMsg.endsWith("409")) {
        return {
          ...state,
          snackErrorMsg: t("equipment:resourceused")
        };
      } else {
        return {
          ...state,
          snackErrorMsg: t("equipment:deleteerror")
        };
      }
    }

    case SET_ADD_EQUIPMENT_FAILURE: {
      return {
        ...state,
        snackErrorMsg: t("equipment:adderror")
      };
    }

    case API_END:
      if (action.payload === FETCH_GET_EQUIPMENTS) {
        return {
          ...state,
          isFetchingList: false
        };
      }
      if (
        action.payload === FETCH_ADD_EQUIPMENT ||
        action.payload === FETCH_UPDATE_EQUIPMENT ||
        action.payload === FETCH_DELETE_EQUIPMENT ||
        action.payload === FETCH_REORDER_EQUIPMENTS
      ) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
