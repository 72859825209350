import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";

import { confirmAlert } from "react-confirm-alert";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Wizard from "components/DigiHapi/Wizard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";

import { VscDebugStepInto } from "react-icons/vsc";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchGetEquipments,
  fetchAddEquipment,
  fetchUpdateEquipment,
  fetchDeleteEquipment
} from "actions/equipment";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { beigeColor } from "assets/jss/material-dashboard-pro-react";

import Step1 from "./Step1";
import Step2 from "./Step2";

import {
  RESET_EQUIPMENT,
  SET_EQUIPMENT,
  SET_EQUIPMENT_WIZARD_STEP,
  SET_EQUIPMENT_NAME_FR,
  SET_EQUIPMENT_NAME_EN,
  SET_EQUIPMENT_ORDER,
  SET_EQUIPMENT_SYSTEM,
  ROLE_WEBMASTER
} from "actions/types";

import Components from "./PlacesPage";

const style = {
  ...extendedTablesStyle,
  ...commonStyles,
  customUI: {
    backgroundColor: beigeColor[1],
    borderRadius: "10px",
    padding: 10
  },
  descriptionContainer: {
    height: "60px"
  }
};

class PlaceWizard extends React.Component {
  fetchAddEquipment = () => {
    const { fetchAddEquipment, name, lang, system, community } = this.props;

    fetchAddEquipment({ name, lang, system, communityId: community._id });
  };

  fetchUpdateEquipment = () => {
    const { fetchUpdateEquipment, _id, name, lang, order, system } = this.props;

    fetchUpdateEquipment({ _id, name, lang, order, system });
  };

  render() {
    const {
      update,
      isFetching,
      onClose,
      onChangeCurrentStep,
      wizardCurrentStep
    } = this.props;

    return (
      <GridItem xs={12} sm={12}>
        <Wizard
          ref={this._wizard}
          onClose={onClose}
          onChangeCurrentStep={onChangeCurrentStep}
          currentStep={wizardCurrentStep}
          isFetching={isFetching}
          validate
          steps={[
            {
              stepName: "Français",
              stepComponent: Step1,
              stepId: "french"
            },
            {
              stepName: "Anglais",
              stepComponent: Step2,
              stepId: "english"
            }
          ]}
          title={
            update ? "Mise à jour d'un équipement" : "Ajout d'un équipement"
          }
          subtitle=""
          finishButtonClick={
            update ? this.fetchUpdateEquipment : this.fetchAddEquipment
          }
        />
      </GridItem>
    );
  }
}

class PlacesPage extends React.Component {
  state = {
    br: false,
    color: "info",
    message: null,
    wizard: null
  };

  componentDidMount() {
    const { fetchGetEquipments, community, newWizard } = this.props;
    fetchGetEquipments(community._id);
    if (newWizard) {
      this.addWizard(newWizard === "update");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchGetEquipments,
      snackErrorMsg,
      snackInfoMsg,
      newWizard,
      community
    } = this.props;

    if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
      this.autoCloseMessage();
    }

    if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
      this.setState({
        wizard: null
      });
      fetchGetEquipments(community._id);
      this.autoCloseMessage();
    }

    if (newWizard && prevProps.newWizard !== newWizard) {
      this.addWizard(newWizard === "update");
    }
  }

  hideAlert = () => {
    this.setState({
      br: false
    });
  };

  autoCloseMessage = () => {
    const { snackErrorMsg, snackInfoMsg } = this.props;

    this.setState({
      br: true,
      message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
      color: snackInfoMsg ? "info" : "danger"
    });

    if (snackInfoMsg) {
      setTimeout(this.hideAlert, 5000);
    }
  };

  onClose = () => {
    this.setState({
      wizard: null
    });
    this.props.dispatch({
      type: RESET_EQUIPMENT
    });
  };

  onChangeCurrentStep = currentStep => {
    this.props.dispatch({
      type: SET_EQUIPMENT_WIZARD_STEP,
      payload: currentStep
    });
  };

  addWizard = (update = true) => {
    this.setState({
      wizard: (
        <Components.PlaceWizard
          update={update}
          onClose={this.onClose}
          onChangeCurrentStep={this.onChangeCurrentStep}
        />
      )
    });
  };

  editEquipment = (id, name, lang, order, system) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_EQUIPMENT,
      payload: id
    });
    dispatch({
      type: SET_EQUIPMENT_NAME_FR,
      payload: name
    });
    if (lang && lang.en) {
      dispatch({
        type: SET_EQUIPMENT_NAME_EN,
        payload: lang.en.name
      });
    }
    dispatch({
      type: SET_EQUIPMENT_ORDER,
      payload: order
    });
    dispatch({
      type: SET_EQUIPMENT_SYSTEM,
      payload: system
    });
  };

  deleteEquipment = id => {
    this.props.fetchDeleteEquipment(id);
  };

  getRoundButtons = (id, name, lang, order, communityId, system) => {
    const { classes, user } = this.props;
    if ((system && user.role === ROLE_WEBMASTER) || !system) {
      return [
        {
          color: system ? "blue" : "brown",
          icon: Edit,
          onClick: this.editEquipment
        },
        { color: "danger", icon: Delete, onClick: this.delete }
      ].map((prop, key) => {
        return (
          (user.role === ROLE_WEBMASTER || communityId) && (
            <Button
              round
              color={prop.color}
              className={classes.actionButton + " " + classes.actionButtonRound}
              disabled={this.state && this.state.wizard ? true : false}
              key={key}
              onClick={() => prop.onClick(id, name, lang, order, system)}
            >
              <prop.icon className={classes.icon} />
            </Button>
          )
        );
      });
    }
  };

  getPlaces = () => {
    const { equipments } = this.props;

    return (
      equipments &&
      equipments.map(({ _id, name, lang, order, communityId, system }) => {
        return {
          name,
          actions: (
            <div className="actions-right">
              {this.getRoundButtons(
                _id,
                name,
                lang,
                order,
                communityId,
                system
              )}
            </div>
          )
        };
      })
    );
  };

  delete = equipmentId => {
    const { classes } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUI}>
            <h2>Confirmation</h2>
            <p>Êtes-vous sûr de vouloir supprimer le lieu de rendez-vous ?</p>
            <div className={classes.justifyContentSpaceAround}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Annuler
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.deleteEquipment(equipmentId);
                  onClose();
                }}
              >
                Supprimer
              </Button>
            </div>
          </div>
        );
      }
    });
  };

  render() {
    const { color, message } = this.state;
    const { dispatch, classes, isFetchingList, user } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color="primary">
                <VscDebugStepInto />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {"Lieux de rendez-vous"}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.getPlaces()}
                filterable
                columns={[
                  {
                    Header: "Nom",
                    accessor: "name",
                    sortable: true,
                    filterable: false
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                loading={isFetchingList}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={false}
                previousText="Précédent"
                nextText="Suivant"
                rowsText="Lignes"
                noDataText="Aucuns lieux de rendez-vous"
                ofText="sur"
                loadingText="Chargement..."
                className="-striped -highlight"
              />
            </CardBody>
            <CardFooter product>
              <div className={classes.w100}>
                {user.role === ROLE_WEBMASTER && (
                  <Button
                    color={"blue"}
                    className={classes.floatRight}
                    onClick={() =>
                      dispatch({
                        type: SET_EQUIPMENT_SYSTEM,
                        payload: true
                      })
                    }
                    disabled={this.state.wizard}
                  >
                    <Add /> {"Ajout d'un lieu de rdv sytème"}
                  </Button>
                )}
                <Button
                  color={"primary"}
                  className={classes.floatRight}
                  onClick={() =>
                    dispatch({
                      type: SET_EQUIPMENT_SYSTEM,
                      payload: false
                    })
                  }
                  disabled={this.state.wizard}
                >
                  <Add /> {"Ajout d'un lieu de rdv"}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {this.state.wizard}
        <Snackbar
          place="br"
          color={color}
          icon={AddAlert}
          message={message ? message : ""}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
      </GridContainer>
    );
  }
}

PlacesPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    isFetchingList,
    isFetching,
    _id,
    equipments,
    name,
    lang,
    order,
    error,
    nameError,
    snackInfoMsg,
    snackErrorMsg,
    newWizard,
    system,
    wizardCurrentStep
  } = state.equipmentReducer;
  const { community } = state.entitiesReducer;
  const { user } = state.profileReducer;
  return {
    isFetchingList,
    isFetching,
    _id,
    equipments: equipments && Object.values(equipments),
    name,
    lang,
    order,
    error,
    nameError,
    snackInfoMsg,
    snackErrorMsg,
    newWizard,
    wizardCurrentStep,
    user,
    system,
    community
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetEquipments,
      fetchAddEquipment,
      fetchUpdateEquipment,
      fetchDeleteEquipment
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default {
  PlacesPage: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(PlacesPage)),
  PlaceWizard: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(PlaceWizard))
};
