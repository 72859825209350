// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// material ui icons
import Close from "@material-ui/icons/Close";
import {
  fetchGetAddressUsingZipCode,
  fetchGetCityUsingZipCode,
  fetchGetAddressUsingCoordinates
} from "actions/datagouv";

import {
  CLEAR_CAMPSITE_FAX,
  CLEAR_CAMPSITE_MAIL,
  CLEAR_CAMPSITE_PHONE,
  CLEAR_CAMPSITE_WEB_SITE_URL,
  SET_CAMPSITE_ADDRESS,
  SET_CAMPSITE_CITY,
  SET_CAMPSITE_FAX,
  SET_CAMPSITE_FAX_ERROR,
  SET_CAMPSITE_MAIL,
  SET_CAMPSITE_MAIL_ERROR,
  SET_CAMPSITE_PHONE,
  SET_CAMPSITE_PHONE_ERROR,
  SET_CAMPSITE_WEB_SITE_URL,
  SET_CAMPSITE_WEB_SITE_URL_ERROR,
  SET_CAMPSITE_ZIP_CODE,
  SET_CAMPSITE_SELECTED_CITY,
  SET_CAMPSITE_SELECTED_ADDRESS,
  SET_CAMPSITE_SELECTED_ZIPCODE
} from "actions/types";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react";
import { PhoneMask } from "utils/masks";

const OTHER = "Autre ...";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle,
  ...customSelectStyle,
  myInputLabelStyle: {
    ...defaultFont,
    textTransform: "none",
    fontWeight: "400",
    fontSize: "14px"
  },
  grayColor: {
    color: grayColor[3] + " !important"
  }
};

class Step2 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const {
      dispatch,
      phoneState,
      faxState,
      webSiteUrlState,
      mailState
    } = this.props;

    let success = true;
    if (phoneState === "error") {
      dispatch({
        type: SET_CAMPSITE_PHONE_ERROR
      });
      success = false;
    }
    if (faxState === "error") {
      dispatch({
        type: SET_CAMPSITE_FAX_ERROR
      });
      success = false;
    }
    if (webSiteUrlState === "error") {
      dispatch({
        type: SET_CAMPSITE_WEB_SITE_URL_ERROR
      });
      success = false;
    }
    if (mailState === "error") {
      dispatch({
        type: SET_CAMPSITE_MAIL_ERROR
      });
      success = false;
    }
    return success;
  }

  renderItems = data => {
    const { classes } = this.props;
    const components = [];

    if (data) {
      // eslint-disable-next-line
      for (const obj of data) {
        components.push(
          <MenuItem
            key={obj}
            classes={{
              root: classes.selectMenuItem + " " + classes.myInputLabelStyle,
              selected:
                classes.selectMenuItemSelected + " " + classes.myInputLabelStyle
            }}
            value={obj}
          >
            {obj}
          </MenuItem>
        );
      }
    }
    return components;
  };

  render() {
    const {
      classes,
      dispatch,
      phone,
      phoneState,
      phoneErrorMsg,
      fax,
      faxState,
      faxErrorMsg,
      customAddress,
      address,
      addresses,
      selectedAddress,
      addressUpdated,
      zipCodes,
      selectedZipCode,
      customZipCode,
      zipCode,
      zipCodeUpdated,
      cities,
      selectedCity,
      customCity,
      webSiteUrl,
      webSiteUrlState,
      webSiteUrlErrorMsg,
      mail,
      mailState,
      mailErrorMsg,
      longitude,
      latitude
    } = this.props;
    return (
      <GridContainer justify="center">
        {selectedAddress === OTHER ? (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Adresse"
              id="address"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: customAddress ? customAddress : "",
                onChange: e =>
                  dispatch({
                    type: SET_CAMPSITE_ADDRESS,
                    value: e.target.value
                  }),
                onBlur: () => {
                  if (addressUpdated && address) {
                    if (zipCode) {
                      this.props.fetchGetAddressUsingZipCode({
                        address,
                        zipCode
                      });
                    } else if (longitude && latitude) {
                      this.props.fetchGetAddressUsingCoordinates({
                        address,
                        latitude,
                        longitude
                      });
                    }
                  }
                }
              }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={
                  classes.selectLabel +
                  " " +
                  classes.myInputLabelStyle +
                  " " +
                  classes.grayColor
                }
              >
                Adresse
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select + " " + classes.myInputLabelStyle
                }}
                value={selectedAddress ? selectedAddress : ""}
                onChange={(e, value) =>
                  dispatch({
                    type: SET_CAMPSITE_SELECTED_ADDRESS,
                    payload: value.props.children
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  classes={{
                    root:
                      classes.selectMenuItem + " " + classes.myInputLabelStyle
                  }}
                >
                  {OTHER}
                </MenuItem>
                {this.renderItems(Object.keys(addresses))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        {selectedZipCode === OTHER ? (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Code postal"
              id="zipcode"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: customZipCode ? customZipCode : "",
                onChange: e =>
                  dispatch({
                    type: SET_CAMPSITE_ZIP_CODE,
                    value: e.target.value
                  }),
                onBlur: () =>
                  zipCodeUpdated &&
                  zipCode &&
                  this.props.fetchGetCityUsingZipCode({ address, zipCode })
              }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={
                  classes.selectLabel +
                  " " +
                  classes.myInputLabelStyle +
                  " " +
                  classes.grayColor
                }
              >
                Code postal
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select + " " + classes.myInputLabelStyle
                }}
                value={selectedZipCode ? selectedZipCode : ""}
                onChange={(e, value) =>
                  dispatch({
                    type: SET_CAMPSITE_SELECTED_ZIPCODE,
                    payload: value.props.children
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  classes={{
                    root:
                      classes.selectMenuItem + " " + classes.myInputLabelStyle
                  }}
                >
                  {OTHER}
                </MenuItem>
                {this.renderItems(Object.keys(zipCodes))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        {selectedCity === OTHER ? (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Ville"
              id="city"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: customCity ? customCity : "",
                onChange: e =>
                  dispatch({
                    type: SET_CAMPSITE_CITY,
                    value: e.target.value
                  })
              }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={
                  classes.selectLabel +
                  " " +
                  classes.myInputLabelStyle +
                  " " +
                  classes.grayColor
                }
              >
                Ville
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select + " " + classes.myInputLabelStyle
                }}
                value={selectedCity ? selectedCity : ""}
                onChange={(e, value) =>
                  dispatch({
                    type: SET_CAMPSITE_SELECTED_CITY,
                    payload: value.props.children
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  classes={{
                    root:
                      classes.selectMenuItem + " " + classes.myInputLabelStyle
                  }}
                >
                  {OTHER}
                </MenuItem>
                {this.renderItems(Object.keys(cities))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Téléphone"
            id="phone"
            formControlProps={{
              fullWidth: true
            }}
            success={phone && phoneState === "success"}
            error={phoneState === "error"}
            helperText={phoneErrorMsg}
            inputProps={{
              placeholder: "+33 (0)X XX XX XX XX",
              inputComponent: PhoneMask,
              value: phone ? phone : "",
              onChange: e =>
                dispatch({
                  type: SET_CAMPSITE_PHONE,
                  value: e.target.value
                }),
              endAdornment: phone !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_CAMPSITE_PHONE
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Fax"
            id="fax"
            formControlProps={{
              fullWidth: true
            }}
            success={fax && faxState === "success"}
            error={faxState === "error"}
            helperText={faxErrorMsg}
            inputProps={{
              placeholder: "+33 (0)X XX XX XX XX",
              inputComponent: PhoneMask,
              value: fax ? fax : "",
              onChange: e =>
                dispatch({
                  type: SET_CAMPSITE_FAX,
                  value: e.target.value
                }),
              endAdornment: fax !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_CAMPSITE_FAX
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Url site internet"
            id="url"
            formControlProps={{
              fullWidth: true
            }}
            helperText={webSiteUrlErrorMsg}
            success={webSiteUrl && webSiteUrlState === "success"}
            error={webSiteUrlState === "error"}
            inputProps={{
              value: webSiteUrl ? webSiteUrl : "",
              onChange: e =>
                dispatch({
                  type: SET_CAMPSITE_WEB_SITE_URL,
                  value: e.target.value
                }),
              type: "text",
              endAdornment: webSiteUrl !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_CAMPSITE_WEB_SITE_URL
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Email de contact"
            id="mail"
            formControlProps={{
              fullWidth: true
            }}
            helperText={mailErrorMsg}
            success={mail && mailState === "success"}
            error={mailState === "error"}
            inputProps={{
              value: mail ? mail : "",
              onChange: e =>
                dispatch({
                  type: SET_CAMPSITE_MAIL,
                  value: e.target.value
                }),
              type: "text",
              endAdornment: mail !== null && (
                <InputAdornment position="end">
                  <Close
                    className={classes.danger}
                    onClick={() =>
                      dispatch({
                        type: CLEAR_CAMPSITE_MAIL
                      })
                    }
                  />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    _id,
    isFetching,
    isFetchingList,
    name,
    nameErrorMsg,
    addressUpdated,
    customAddress,
    addresses,
    selectedAddress,
    address,
    customZipCode,
    zipCodes,
    selectedZipCode,
    zipCode,
    zipCodeUpdated,
    customCity,
    city,
    cities,
    selectedCity,
    webSiteUrl,
    mail,
    latitude,
    longitude,
    phone,
    fax,
    registerkey,
    files,
    logoUri,
    mapUri,
    mapSmallUri,
    phoneErrorMsg,
    phoneState,
    faxErrorMsg,
    faxState,
    webSiteUrlState,
    webSiteUrlErrorMsg,
    mailState,
    mailErrorMsg
  } = state.campsiteReducer;
  return {
    _id,
    isFetching,
    isFetchingList,
    name,
    nameErrorMsg,
    address,
    customAddress,
    addresses,
    selectedAddress,
    addressUpdated,
    zipCode,
    customZipCode,
    zipCodes,
    selectedZipCode,
    zipCodeUpdated,
    customCity,
    city,
    cities,
    selectedCity,
    webSiteUrl,
    mail,
    latitude,
    longitude,
    phone,
    fax,
    registerkey,
    files,
    logoUri,
    mapUri,
    mapSmallUri,
    phoneErrorMsg,
    phoneState,
    faxErrorMsg,
    faxState,
    webSiteUrlState,
    webSiteUrlErrorMsg,
    mailState,
    mailErrorMsg
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetAddressUsingZipCode,
      fetchGetCityUsingZipCode,
      fetchGetAddressUsingCoordinates
    },
    dispatch
  );
  return { ...actions, dispatch };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Step2));
