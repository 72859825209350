import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ImageUpload from "components/CustomUpload/ImageUpload";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

import { SET_POST_FILES_IMAGES, REMOVE_POST_IMAGE } from "actions/types";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle
};

class Step2 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }

  onImageLoaded = (name, imagePreviewUrl) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_POST_FILES_IMAGES,
      payload: [{ name, base64: imagePreviewUrl }]
    });
  };

  onImageRemoved = () => {
    this.props.dispatch({
      type: REMOVE_POST_IMAGE
    });
  };

  render() {
    const { photoUri } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={4} md={4}>
          <legend>Image</legend>
          <ImageUpload
            ref={this.child}
            onImageLoaded={this.onImageLoaded}
            onImageRemoved={this.onImageRemoved}
            photoUri={photoUri}
            addButtonProps={{
              color: "brown",
              round: true
            }}
            changeButtonProps={{
              color: "brown",
              round: true
            }}
            removeButtonProps={{
              color: "danger",
              round: true
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const { photoUri } = state.eventReducer;
  return {
    photoUri
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step2));
