import merge from "lodash/merge";
import {
  SET_LIVE_EVENTS,
  RESET_DATA,
  SET_CAMPSITES,
  SET_AUDITS,
  SET_OWN_GOOD_DEAL_RESULT,
  SET_TEMPLATES_RESULT,
  SET_EQUIPMENTS_RESULT,
  SET_TEMPLATES_DATES_RESULT,
  SET_CURRENT_CAMPSITE_DETAILS_RESULT,
  SET_OWN_EVENTS_RESULT,
  SET_CITIES,
  SET_NEIGHBORHOODS,
  SET_CITY_CHANNELS,
  SET_CAMPSITE_CHANNELS,
  SET_NEIGHBORHOOD_CHANNELS,
  SET_CURRENT_COMMUNITY_DETAILS_RESULT,
  SET_COMMUNITY_DETAILS_RESULT
} from "../actions/types";

const initialState = {
  locations: {},
  templates: {},
  templateTimes: {},
  categories: {},
  goodDealsCategories: {},
  hobbies: {},
  campsites: {},
  cities: {},
  neighborhoods: {},
  eventTypes: {},
  community: {}
};

export default function entities(state = initialState, action) {
  switch (action.type) {
    case RESET_DATA:
      return {
        ...initialState,
        eventTypes: state.eventTypes
      };

    case SET_CAMPSITES:
      return {
        ...state,
        campsites: { ...action.entities?.campsites }
      };

    case SET_CURRENT_COMMUNITY_DETAILS_RESULT:
    case SET_COMMUNITY_DETAILS_RESULT:
      if (
        action.entities.community &&
        action.entities.community &&
        Object.values(action.entities.community).length > 0
      ) {
        return {
          ...state,
          community: Object.values(action.entities.community)[0]
        };
      }
      break;

    case SET_CITIES:
      return {
        ...state,
        cities: { ...action.entities.cities }
      };

    case SET_NEIGHBORHOODS:
      return {
        ...state,
        neighborhoods: { ...action.entities.neighborhoods }
      };

    case SET_CITY_CHANNELS:
    case SET_CAMPSITE_CHANNELS:
    case SET_NEIGHBORHOOD_CHANNELS:
    case SET_OWN_GOOD_DEAL_RESULT:
    case SET_LIVE_EVENTS:
    case SET_EQUIPMENTS_RESULT:
    case SET_AUDITS:
    case SET_TEMPLATES_RESULT:
    case SET_TEMPLATES_DATES_RESULT:
    case SET_CURRENT_CAMPSITE_DETAILS_RESULT:
    case SET_OWN_EVENTS_RESULT:
      break;

    default:
      if (action.entities) {
        return merge({}, state, action.entities);
      }
      break;
  }
  return state;
}
