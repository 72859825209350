import {
  API_START,
  API_END,
  FETCH_GET_CAMPSITES,
  REMOVE_CAMPSITE_IMAGE,
  SET_CAMPSITE_FILES_IMAGES,
  SET_CAMPSITE_NAME,
  SET_CAMPSITE_NAME_ERROR,
  SET_CAMPSITE_LATITUDE,
  SET_CAMPSITE_LONGITUDE,
  FETCH_ADD_CAMPSITE,
  SET_FETCH_ADD_CAMPSITE_RESULT,
  SET_FETCH_ADD_CAMPSITE_FAILURE,
  SET_CAMPSITEMAP_FILES_IMAGES,
  REMOVE_CAMPSITEMAP_IMAGE,
  FETCH_UPDATE_CAMPSITE,
  SET_FETCH_UPDATE_CAMPSITE_FAILURE,
  SET_FETCH_UPDATE_CAMPSITE_RESULT,
  RESET_CAMPSITE,
  FETCH_GET_CAMPSITE_DETAILS,
  SET_CAMPSITE_DETAILS_RESULT,
  SET_CAMPSITE_PHONE,
  SET_CAMPSITE_PHONE_ERROR,
  CLEAR_CAMPSITE_PHONE,
  SET_CAMPSITE_ADDRESS,
  SET_CAMPSITE_ZIP_CODE,
  SET_CAMPSITE_CITY,
  SET_CAMPSITE_FAX,
  CLEAR_CAMPSITE_FAX,
  SET_CAMPSITE_FAX_ERROR,
  SET_CAMPSITE_WEB_SITE_URL,
  CLEAR_CAMPSITE_WEB_SITE_URL,
  SET_CAMPSITE_WEB_SITE_URL_ERROR,
  SET_CAMPSITE_MAIL,
  CLEAR_CAMPSITE_MAIL,
  SET_CAMPSITE_MAIL_ERROR,
  FETCH_GET_CURRENT_CAMPSITE_DETAILS,
  RESET_DATA,
  SET_CITY_USING_ZIPCODE_RESULT,
  SET_ADDRESS_USING_ZIPCODE_RESULT,
  SET_CAMPSITE_SELECTED_CITY,
  SET_CAMPSITE_SELECTED_ADDRESS,
  SET_ADDRESS_USING_COORDINATES_RESULT,
  SET_CAMPSITE_SELECTED_ZIPCODE,
  SET_FETCH_DELETE_CAMPSITE_FAILURE,
  SET_FETCH_DELETE_CAMPSITE_RESULT,
  FETCH_DELETE_CAMPSITE,
  SET_CAMPSITE_REGISTER_KEY,
  CLEAR_CAMPSITE_VALIDATE,
  VALIDATE_CAMPSITE_INPUTS,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  FETCH_REGISTER_CAMPSITE,
  SET_REGISTER_CAMPSITE_RESULT,
  SET_REGISTER_CAMPSITE_FAILURE,
  SET_DELETE_CAMPSITE_CONFIRMATION_NAME,
  SET_CAMPSITE_CHANNELS,
  ADD_CAMPSITE_CHANNEL,
  DELETE_CAMPSITE_CHANNEL,
  ADD_CAMPSITE_CITY,
  DELETE_CAMPSITE_CITY,
  SET_CITIES
} from "../actions/types";
import { t } from "../services/i18n";
const OTHER = "Autre ...";

const initialState = {
  isFetching: false,
  isFetchingList: false,
  campsites: [],
  _id: null,
  name: null,
  nameErrorMsg: null,
  addresses: {},
  customAddress: null,
  address: null,
  selectedAddress: OTHER,
  addressUpdated: false,
  zipCode: null,
  zipCodes: {},
  customZipCode: null,
  selectedZipCode: OTHER,
  zipCodeUpdated: false,
  customCity: null,
  city: null,
  selectedCity: OTHER,
  cities: {},
  webSiteUrl: null,
  mail: null,
  mailErrorMsg: null,
  latitude: null,
  longitude: null,
  phone: null,
  phoneErrorMsg: null,
  phoneState: null,
  fax: null,
  faxState: null,
  faxErrorMsg: null,
  registerkey: null,
  files: [],
  snackErrorMsg: null,
  snackInfoMsg: null,
  newWizard: null,
  logoUri: null,
  mapUri: null,
  mapSmallUri: null,
  areEquipmentsReordered: false,
  source: null,
  destination: null,
  clearValidate: false,
  isValid: false,
  firstname: null,
  lastname: null,
  lastnameErrorMsg: null,
  firstnameErrorMsg: null,
  addressErrorMsg: null,
  zipCodeErrorMsg: null,
  cityErrorMsg: null,
  deleteConfirmationName: "",
  channelsIds: [],
  channels: [],
  channelsSuggestions: [],
  channelsEntities: {},
  citiesIds: [],
  citiesTags: [],
  citiesSuggestions: [],
  citiesEntities: {}
};

function validatePhone(phone) {
  let phoneState = "success";
  const phoneRegex = /^[+][0-9]{1,4}[" "][(][0][)][1-9][" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}$/g;
  if (phone && !phoneRegex.test(phone)) {
    phoneState = "error";
  }
  return phoneState;
}

function validateUrl(url) {
  try {
    new URL(url);
    return "success";
  } catch (_) {
    return "error";
  }
}

function validateEmail(email) {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(email)) {
    return "success";
  }
  return "error";
}

function keepState(state) {
  return {
    ...initialState,
    channelsSuggestions: Object.values(state.channelsEntities).map(obj => ({
      name: obj.name,
      id: obj._id
    })),
    channelsEntities: state.channelsEntities,
    citiesSuggestions: Object.values(state.citiesEntities).map(obj => ({
      name: obj.name,
      id: obj._id
    })),
    citiesEntities: state.citiesEntities
  };
}

export default function campsiteReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_CAMPSITES) {
        return {
          ...state,
          isFetchingList: true
        };
      }
      if (
        action.payload === FETCH_GET_CAMPSITE_DETAILS ||
        action.payload === FETCH_ADD_CAMPSITE ||
        action.payload === FETCH_UPDATE_CAMPSITE ||
        action.payload === FETCH_DELETE_CAMPSITE ||
        action.payload === FETCH_GET_CURRENT_CAMPSITE_DETAILS ||
        action.payload === FETCH_REGISTER_CAMPSITE
      ) {
        return {
          ...state,
          isFetching: true,
          newWizard: null,
          snackInfoMsg: null,
          snackErrorMsg: null
        };
      }
      break;

    case RESET_DATA:
    case RESET_CAMPSITE:
      return keepState(state);

    case SET_CAMPSITE_CHANNELS: {
      return {
        ...state,
        channelsSuggestions: Object.values(action.entities.channels).map(
          obj => ({
            name: obj.name,
            id: obj._id
          })
        ),
        channelsEntities: action.entities.channels
      };
    }

    case SET_CITIES: {
      return {
        ...state,
        citiesSuggestions: Object.values(action.entities.cities).map(obj => ({
          name: obj.name,
          id: obj._id
        })),
        citiesEntities: action.entities.cities
      };
    }

    case ADD_CAMPSITE_CHANNEL: {
      const channelsSuggestions = state.channelsSuggestions.filter(
        suggestion => suggestion.id !== action.value.id
      );
      return {
        ...state,
        channels: [...state.channels, action.value],
        channelsIds: [...state.channelsIds, action.value.id],
        channelsSuggestions
      };
    }

    case ADD_CAMPSITE_CITY: {
      const citiesSuggestions = state.citiesSuggestions.filter(
        suggestion => suggestion.id !== action.value.id
      );
      return {
        ...state,
        citiesTags: [...state.citiesTags, action.value],
        citiesIds: [...state.citiesIds, action.value.id],
        citiesSuggestions
      };
    }

    case DELETE_CAMPSITE_CHANNEL: {
      const channelsSuggestions = [
        ...state.channelsSuggestions,
        state.channels[action.value]
      ];
      return {
        ...state,
        channels: state.channels.filter(
          (channel, index) => index !== action.value
        ),
        channelsIds: state.channelsIds.filter(
          (channel, index) => index !== action.value
        ),
        channelsSuggestions
      };
    }

    case DELETE_CAMPSITE_CITY: {
      const citiesSuggestions = [
        ...state.citiesSuggestions,
        state.citiesTags[action.value]
      ];
      return {
        ...state,
        citiesTags: state.citiesTags.filter(
          (city, index) => index !== action.value
        ),
        citiesIds: state.citiesIds.filter(
          (city, index) => index !== action.value
        ),
        citiesSuggestions
      };
    }

    case SET_DELETE_CAMPSITE_CONFIRMATION_NAME:
      return {
        ...state,
        deleteConfirmationName: action.value
      };

    case SET_ADDRESS_USING_ZIPCODE_RESULT:
    case SET_ADDRESS_USING_COORDINATES_RESULT: {
      const gouvData = action.payload;
      if (gouvData && gouvData.features && gouvData.features.length > 0) {
        // Sort data by distance
        const sortedData = gouvData.features.sort(function(obj1, obj2) {
          if (obj1.properties && obj2.properties) {
            return obj1.properties.distance - obj2.properties.distance;
          } else {
            return 0;
          }
        });
        const addresses = {};
        const zipCodes = {};
        const cities = {};
        let address = null;
        // eslint-disable-next-line
        for (const val of sortedData) {
          if (
            val.properties !== null &&
            val.properties.postcode !== null &&
            val.properties.name !== null &&
            val.properties.city !== null
          ) {
            if (!address) {
              address = val.properties.name;
            }
            addresses[val.properties.name] = {
              city: val.properties.city,
              zipCode: val.properties.postcode,
              longitude: val.geometry.coordinates[0],
              latitude: val.geometry.coordinates[1]
            };
            zipCodes[val.properties.postcode] = {
              city: val.properties.city,
              longitude: val.geometry.coordinates[0],
              latitude: val.geometry.coordinates[1]
            };
            cities[val.properties.city] = {
              longitude: val.geometry.coordinates[0],
              latitude: val.geometry.coordinates[1]
            };
          }
        }
        if (Object.keys(addresses).length > 0) {
          return {
            ...state,
            addressUpdated: false,
            addresses,
            selectedAddress: address,
            address,
            zipCodes,
            selectedZipCode: addresses[address].zipCode,
            zipCode: addresses[address].zipCode,
            cities,
            selectedCity: addresses[address].city,
            city: addresses[address].city,
            longitude: addresses[address].longitude,
            latitude: addresses[address].latitude
          };
        }
      }
      break;
    }

    case SET_CITY_USING_ZIPCODE_RESULT: {
      const gouvData = action.payload;
      if (gouvData && gouvData.features && gouvData.features.length > 0) {
        const zipCodes = {};
        const cities = {};
        let zipCode = null;
        // eslint-disable-next-line
        for (const val of gouvData.features) {
          if (
            val.properties !== null &&
            val.properties.postcode === state.zipCode &&
            val.geometry !== null &&
            val.geometry.coordinates !== null &&
            val.geometry.coordinates.length === 2 &&
            !cities[val.properties.city]
          ) {
            zipCodes[val.properties.postcode] = {
              city: val.properties.city,
              longitude: val.geometry.coordinates[0],
              latitude: val.geometry.coordinates[1]
            };
            if (!zipCode) {
              zipCode = val.properties.postcode;
            }
            cities[val.properties.city] = {
              longitude: val.geometry.coordinates[0],
              latitude: val.geometry.coordinates[1]
            };
          }
        }
        if (Object.keys(cities).length > 0) {
          return {
            ...state,
            zipCodeUpdated: false,
            zipCodes,
            selectedZipCode: zipCode,
            zipCode,
            cities,
            selectedCity: zipCodes[zipCode].city,
            city: zipCodes[zipCode].city,
            longitude: zipCodes[zipCode].longitude,
            latitude: zipCodes[zipCode].latitude
          };
        }
      }
      return {
        ...state,
        zipCodeUpdated: false
      };
    }

    case SET_CAMPSITE_SELECTED_ADDRESS: {
      if (action.payload === OTHER) {
        return {
          ...state,
          selectedAddress: action.payload,
          address: state.customAddress
        };
      } else {
        const address = state.addresses[action.payload];
        return {
          ...state,
          selectedZipCode: address.zipCode,
          zipCode: address.zipCode,
          city: address.city,
          selectedCity: address.city,
          selectedAddress: action.payload,
          address: action.payload,
          longitude: address.longitude,
          latitude: address.latitude
        };
      }
    }

    case SET_CAMPSITE_SELECTED_ZIPCODE: {
      if (action.payload === OTHER) {
        return {
          ...state,
          selectedZipCode: action.payload,
          zipCode: state.customZipCode
        };
      } else {
        const zipCode = state.zipCodes[action.payload];
        return {
          ...state,
          selectedZipCode: action.payload,
          zipCode: action.payload,
          city: zipCode.city,
          selectedCity: zipCode.city,
          longitude: zipCode.longitude,
          latitude: zipCode.latitude
        };
      }
    }

    case SET_CAMPSITE_SELECTED_CITY:
      if (action.payload === OTHER) {
        return {
          ...state,
          selectedCity: action.payload,
          city: state.customCity
        };
      } else {
        return {
          ...state,
          selectedCity: action.payload,
          city: action.payload,
          longitude: state.cities[action.payload].longitude,
          latitude: state.cities[action.payload].latitude
        };
      }

    case SET_CAMPSITE_DETAILS_RESULT: {
      const {
        _id,
        name,
        address,
        zipCode,
        city,
        webSiteUrl,
        mail,
        latitude,
        longitude,
        phone,
        fax,
        registerkey,
        logoUri,
        mapUri,
        mapSmallUri,
        channelsIds,
        citiesIds
      } = action.payload;

      const channels = [];
      const channelsSuggestions = [...state.channelsSuggestions];
      if (channelsIds) {
        for (const channelId of channelsIds) {
          channels.push({
            name: state.channelsEntities[channelId].name,
            id: state.channelsEntities[channelId]._id
          });
          const index = channelsSuggestions.findIndex(
            obj => obj.id === state.channelsEntities[channelId]._id
          );
          if (index >= 0) {
            channelsSuggestions.splice(index, 1);
          }
        }
      }
      const citiesTags = [];
      const citiesSuggestions = [...state.citiesSuggestions];
      if (citiesIds) {
        for (const cityId of citiesIds) {
          citiesTags.push({
            name: state.citiesEntities[cityId].name,
            id: state.citiesEntities[cityId]._id
          });
          const index = citiesSuggestions.findIndex(
            obj => obj.id === state.citiesEntities[cityId]._id
          );
          if (index >= 0) {
            citiesSuggestions.splice(index, 1);
          }
        }
      }
      return {
        ...state,
        _id,
        name,
        address,
        customAddress: address,
        zipCode,
        customZipCode: zipCode,
        city,
        customCity: city,
        webSiteUrl,
        mail,
        latitude,
        longitude,
        phone,
        fax,
        registerkey,
        logoUri,
        mapUri,
        mapSmallUri,
        newWizard: "update",
        channelsIds: channelsIds ? channelsIds : [],
        citiesIds: citiesIds ? citiesIds : [],
        channels,
        channelsSuggestions,
        citiesTags,
        citiesSuggestions
      };
    }

    case SET_CAMPSITE_LATITUDE:
      return {
        ...state,
        latitude: action.value
      };

    case SET_CAMPSITE_LONGITUDE:
      return {
        ...state,
        longitude: action.value
      };

    case SET_CAMPSITE_PHONE:
      return {
        ...state,
        phone: action.value,
        phoneState: validatePhone(action.value),
        phoneErrorMsg: null
      };

    case CLEAR_CAMPSITE_PHONE:
      return {
        ...state,
        phone: null,
        phoneState: null,
        phoneErrorMsg: null
      };

    case SET_CAMPSITE_PHONE_ERROR:
      return {
        ...state,
        phoneErrorMsg: t("profile:invalidphone")
      };

    case SET_CAMPSITE_FAX:
      return {
        ...state,
        fax: action.value,
        faxState: validatePhone(action.value),
        faxErrorMsg: null
      };

    case CLEAR_CAMPSITE_FAX:
      return {
        ...state,
        fax: null,
        faxState: null,
        faxErrorMsg: null
      };

    case SET_CAMPSITE_FAX_ERROR:
      return {
        ...state,
        faxErrorMsg: t("campsite:invalidfax")
      };

    case SET_CAMPSITE_WEB_SITE_URL:
      return {
        ...state,
        webSiteUrl: action.value,
        webSiteUrlState: validateUrl(action.value),
        webSiteUrlErrorMsg: null
      };

    case CLEAR_CAMPSITE_WEB_SITE_URL:
      return {
        ...state,
        webSiteUrl: null,
        webSiteUrlState: null,
        webSiteUrlErrorMsg: null
      };

    case SET_CAMPSITE_WEB_SITE_URL_ERROR:
      return {
        ...state,
        webSiteUrlErrorMsg: t("gooddeal:invalidurl")
      };

    case SET_CAMPSITE_MAIL:
      return {
        ...state,
        mail: action.value,
        mailState: validateEmail(action.value),
        mailErrorMsg: null
      };

    case CLEAR_CAMPSITE_MAIL:
      return {
        ...state,
        mail: null,
        mailState: null,
        mailErrorMsg: null
      };

    case CLEAR_CAMPSITE_VALIDATE:
      return {
        ...state,
        nameErrorMsg: null,
        phoneErrorMsg: null,
        mailErrorMsg: null,
        webSiteUrlErrorMsg: null,
        lastnameErrorMsg: null,
        firstnameErrorMsg: null,
        addressErrorMsg: null,
        zipCodeErrorMsg: null,
        cityErrorMsg: null,
        clearValidate: true,
        isValid: false
      };

    case SET_FIRST_NAME:
      if (
        !action.value ||
        RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
          action.value[action.value.length - 1]
        )
      ) {
        return {
          ...state,
          firstname: action.value,
          firstnameErrorMsg: null
        };
      }
      break;

    case SET_LAST_NAME:
      if (
        !action.value ||
        RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
          action.value[action.value.length - 1]
        )
      ) {
        return {
          ...state,
          lastname: action.value,
          lastnameErrorMsg: null
        };
      }
      break;

    case VALIDATE_CAMPSITE_INPUTS: {
      const firstnameErrorMsg =
        state.firstname && state.firstname.trim().length > 0
          ? null
          : t("profile:firstnamemandatory");
      const lastnameErrorMsg =
        state.lastname && state.lastname.trim().length > 0
          ? null
          : t("profile:lastnamemandatory");
      const addressErrorMsg =
        state.address && state.address.trim().length > 0
          ? null
          : t("campsite:addressmandatory");
      const zipCodeErrorMsg =
        state.zipCode && state.zipCode.trim().length > 0
          ? null
          : t("campsite:zipCodemandatory");
      const cityErrorMsg =
        state.city && state.city.trim().length > 0
          ? null
          : t("campsite:citymandatory");
      const nameErrorMsg =
        state.name && state.name.trim().length > 0
          ? null
          : t("campsite:namemandatory");

      let mailErrorMsg = null;
      if (!state.mail) {
        mailErrorMsg = t("login:missingemail");
      } else if (validateEmail(state.mail) === "error") {
        mailErrorMsg = t("campsite:invalidmail");
      }
      let phoneErrorMsg = null;
      if (!state.phone) {
        phoneErrorMsg = t("profile:phonemandatory");
      } else if (validatePhone(state.phone) === "error") {
        phoneErrorMsg = t("profile:invalidphone");
      }
      return {
        ...state,
        clearValidate: false,
        firstnameErrorMsg,
        lastnameErrorMsg,
        addressErrorMsg,
        zipCodeErrorMsg,
        cityErrorMsg,
        mailErrorMsg,
        nameErrorMsg,
        phoneErrorMsg,
        isValid:
          !firstnameErrorMsg &&
          !lastnameErrorMsg &&
          !addressErrorMsg &&
          !zipCodeErrorMsg &&
          !cityErrorMsg &&
          !mailErrorMsg &&
          !nameErrorMsg &&
          !phoneErrorMsg
      };
    }

    case SET_CAMPSITE_MAIL_ERROR:
      return {
        ...state,
        mailErrorMsg: t("campsite:invalidmail")
      };

    case SET_CAMPSITE_ADDRESS:
      return {
        ...state,
        customAddress: action.value,
        address: action.value,
        addressErrorMsg: null,
        addressUpdated: true
      };

    case SET_CAMPSITE_ZIP_CODE:
      return {
        ...state,
        customZipCode: action.value,
        zipCode: action.value,
        zipCodeUpdated: true,
        zipCodeErrorMsg: null
      };

    case SET_CAMPSITE_CITY:
      return {
        ...state,
        customCity: action.value,
        city: action.value,
        cityErrorMsg: null
      };

    case SET_CAMPSITE_FILES_IMAGES:
      return {
        ...state,
        files: [
          ...state.files.filter(file => file.out !== "logoUri"),
          ...action.payload
        ],
        logoUri: null
      };

    case REMOVE_CAMPSITE_IMAGE: {
      return {
        ...state,
        files: [...state.files.filter(file => file.out !== "logoUri")],
        logoUri: null
      };
    }

    case SET_CAMPSITEMAP_FILES_IMAGES:
      return {
        ...state,
        files: [
          ...state.files.filter(
            file => file.out !== "mapUri" && file.out !== "mapSmallUri"
          ),
          ...action.payload
        ],
        mapUri: null,
        mapSmallUri: null
      };

    case REMOVE_CAMPSITEMAP_IMAGE:
      return {
        ...state,
        files: [
          ...state.files.filter(
            file => file.out !== "mapUri" && file.out !== "mapSmallUri"
          )
        ],
        mapUri: null,
        mapSmallUri: null
      };

    case SET_CAMPSITE_NAME:
      return {
        ...state,
        name: action.value,
        nameErrorMsg: null
      };

    case SET_CAMPSITE_REGISTER_KEY:
      return {
        ...state,
        registerkey: action.value
      };

    case SET_CAMPSITE_NAME_ERROR:
      return {
        ...state,
        nameErrorMsg: t("campsite:namemandatory")
      };

    case SET_FETCH_ADD_CAMPSITE_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg: t("campsite:addsuccess")
      };

    case SET_REGISTER_CAMPSITE_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg: t("campsite:registersuccess"),
        longitude: state.longitude,
        latitude: state.latitude
      };

    case SET_REGISTER_CAMPSITE_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("campsite:registererror")
      };

    case SET_FETCH_DELETE_CAMPSITE_RESULT:
      return {
        ...state,
        snackInfoMsg: t("campsite:deletesuccess")
      };

    case SET_FETCH_UPDATE_CAMPSITE_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg: t("campsite:updatesuccess")
      };

    case SET_FETCH_ADD_CAMPSITE_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("campsite:adderror")
      };

    case SET_FETCH_UPDATE_CAMPSITE_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("campsite:updateerror")
      };

    case SET_FETCH_DELETE_CAMPSITE_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("campsite:deleteerror")
      };

    case API_END:
      if (action.payload === FETCH_GET_CAMPSITES) {
        return {
          ...state,
          isFetchingList: false
        };
      }
      if (
        action.payload === FETCH_GET_CAMPSITE_DETAILS ||
        action.payload === FETCH_ADD_CAMPSITE ||
        action.payload === FETCH_UPDATE_CAMPSITE ||
        action.payload === FETCH_DELETE_CAMPSITE ||
        action.payload === FETCH_GET_CURRENT_CAMPSITE_DETAILS ||
        action.payload === FETCH_REGISTER_CAMPSITE
      ) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
