import {
  FETCH_GET_CITY_USING_ZIPCODE,
  SET_CITY_USING_ZIPCODE_RESULT,
  SET_CITY_USING_ZIPCODE_FAILURE,
  FETCH_GET_ADDRESS_USING_ZIPCODE,
  SET_ADDRESS_USING_ZIPCODE_RESULT,
  SET_ADDRESS_USING_ZIPCODE_FAILURE,
  FETCH_GET_ADDRESS_USING_COORDINATES,
  SET_ADDRESS_USING_COORDINATES_RESULT,
  SET_ADDRESS_USING_COORDINATES_FAILURE
} from "./types";
import { apiAction } from "./api";

export function fetchGetCityUsingZipCode({ address, zipCode }) {
  const addressComputed = address
    ? address.trim().replace(/ /g, "+") + "&"
    : "";
  return apiAction({
    customUrl: `https://api-adresse.data.gouv.fr/search/?q=${addressComputed}postcode=${zipCode}&limit=10`,
    method: "GET",
    onSuccess: setAddressUsingZipCodeResult,
    onFailure: setAddressUsingZipCodeFailure,
    label: FETCH_GET_CITY_USING_ZIPCODE,
    withCredentials: false
  });
}

function setAddressUsingZipCodeResult(data) {
  return {
    type: SET_CITY_USING_ZIPCODE_RESULT,
    payload: data
  };
}

function setAddressUsingZipCodeFailure(error) {
  return {
    type: SET_CITY_USING_ZIPCODE_FAILURE,
    error
  };
}

export function fetchGetAddressUsingZipCode({ zipCode, address, postcode }) {
  const addressComputed = address.trim().replace(/ /g, "+");
  return apiAction({
    customUrl:
      "https://api-adresse.data.gouv.fr/search/?q=" +
      addressComputed +
      (zipCode
        ? "&postcode=" + zipCode
        : postcode
        ? "&postcode=" + postcode
        : ""),
    method: "GET",
    onSuccess: setAddressResult,
    onFailure: setAddressFailure,
    label: FETCH_GET_ADDRESS_USING_ZIPCODE,
    withCredentials: false
  });
}

function setAddressResult(data) {
  return {
    type: SET_ADDRESS_USING_ZIPCODE_RESULT,
    payload: data
  };
}

function setAddressFailure(error) {
  return {
    type: SET_ADDRESS_USING_ZIPCODE_FAILURE,
    error
  };
}

export function fetchGetAddressUsingCoordinates({
  address,
  longitude,
  latitude
}) {
  return apiAction({
    customUrl: `https://api-adresse.data.gouv.fr/reverse/?lat=${latitude}&lon=${longitude}`,
    method: "GET",
    onSuccess: data => [
      data && data.features && data.features[0] && data.features[0].properties
        ? fetchGetAddressUsingZipCode({
            ...data.features[0].properties,
            address
          })
        : setAddressUsingCoordinatesResult(data)
    ],
    onFailure: setAddressUsingCoordinatesFailure,
    label: FETCH_GET_ADDRESS_USING_COORDINATES,
    withCredentials: false
  });
}

function setAddressUsingCoordinatesResult(data) {
  return {
    type: SET_ADDRESS_USING_COORDINATES_RESULT,
    payload: data
  };
}

function setAddressUsingCoordinatesFailure(error) {
  return {
    type: SET_ADDRESS_USING_COORDINATES_FAILURE,
    error
  };
}
