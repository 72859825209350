import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import apiMiddleware from "../middleware/api";
import actionArrayMiddleware from "../middleware/actionArray";
import { routerMiddleware } from "connected-react-router";
import Reactotron from "../ReactotronConfig";

// import createLogger from "redux-logger";
import createReducers from "../reducers";

export default function configureStore(history) {
  const appReducer = createReducers(history);

  const store = createStore(
    appReducer,
    composeWithDevTools(
      Reactotron.createEnhancer(),
      applyMiddleware(
        routerMiddleware(history),
        actionArrayMiddleware,
        apiMiddleware(history)
      )
    )
  );

  return store;
}
