// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import {
  SET_GOODDEALS_TITLE_FR,
  SET_GOODDEALS_PROMOTION_FR,
  SET_GOODDEALS_DESCRIPTION_FR,
  SET_TITLE_ERROR,
  SET_DESCRIPTION_ERROR,
  SET_GOODDEALS_OPENING_TIME_FR,
  SET_GOODDEALS_PRICE_FR,
  SET_GOODDEALS_CHOICES_TAGS,
  SELECT_GOODDEALS_ACTIVITY_CHOICE_FR,
  SET_GOODDEALS_ACTIVITY_DESCRIPTION_FR,
  SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_FR,
  SET_GOODDEALS_ACTIVITY_DESCRIPTION_ERROR,
  GOODDEALS_STEP2_VALIDATED
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle,
  ...customSelectStyle,
  ...regularFormsStyle
};

class Step2 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const {
      dispatch,
      title,
      description,
      choices,
      activityDescription,
      templateId
    } = this.props;

    let success = true;
    if (!title) {
      dispatch({
        type: SET_TITLE_ERROR
      });
      success = false;
    }

    if (!description) {
      dispatch({
        type: SET_DESCRIPTION_ERROR
      });
      success = false;
    }

    if (choices) {
      for (const choice of Object.values(choices)) {
        if (!choice.description) {
          dispatch({
            type: SET_GOODDEALS_ACTIVITY_DESCRIPTION_ERROR,
            choiceId: choice.name
          });
          success = false;
          break;
        }
      }
    } else if (templateId && !activityDescription) {
      dispatch({
        type: SET_GOODDEALS_ACTIVITY_DESCRIPTION_ERROR
      });
      success = false;
    }

    if (success) {
      dispatch({
        type: GOODDEALS_STEP2_VALIDATED
      });
    }

    return success;
  }

  handleTags = choices => {
    const { dispatch } = this.props;

    dispatch({
      type: SET_GOODDEALS_CHOICES_TAGS,
      payload: choices
    });
  };

  renderMenuItems = choices => {
    const { classes } = this.props;
    const components = [];

    // eslint-disable-next-line
    for (const obj of Object.values(choices)) {
      components.push(
        <MenuItem
          key={obj.name}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={obj.name}
        >
          {obj.name}
        </MenuItem>
      );
    }
    return components;
  };

  render() {
    const {
      dispatch,
      classes,
      title,
      titleErrorMsg,
      promotion,
      description,
      descriptionErrorMsg,
      price,
      openingTime,
      templateId,
      choices,
      choicesTags,
      choiceId,
      activityDescriptionErrorMsg,
      activityDescription,
      activityPostDescription
    } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Titre *"
            id="title"
            formControlProps={{
              fullWidth: true
            }}
            error={titleErrorMsg !== null}
            helperText={titleErrorMsg ? titleErrorMsg : undefined}
            inputProps={{
              value: title ? title : "",
              onChange: e =>
                dispatch({
                  type: SET_GOODDEALS_TITLE_FR,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Description *"
            id="descrption-fr"
            formControlProps={{
              fullWidth: true
            }}
            error={descriptionErrorMsg !== null}
            helperText={descriptionErrorMsg ? descriptionErrorMsg : undefined}
            inputProps={{
              value: description ? description : "",
              onChange: e =>
                dispatch({
                  type: SET_GOODDEALS_DESCRIPTION_FR,
                  value: e.target.value
                }),
              multiline: true,
              rows: 5
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Prix"
            id="price-fr"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: price ? price : "",
              onChange: e =>
                dispatch({
                  type: SET_GOODDEALS_PRICE_FR,
                  value: e.target.value
                }),
              multiline: true,
              rows: 3
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Promotion"
            id="promotion"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: promotion ? promotion : "",
              onChange: e =>
                dispatch({
                  type: SET_GOODDEALS_PROMOTION_FR,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Période d'ouverture / Horaires"
            id="opening-time-fr"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: openingTime ? openingTime : "",
              onChange: e =>
                dispatch({
                  type: SET_GOODDEALS_OPENING_TIME_FR,
                  value: e.target.value
                }),
              multiline: true,
              rows: 3
            }}
          />
        </GridItem>
        {templateId && (
          <Fragment>
            <GridItem xs={12} sm={12} md={12}>
              <legend>Choix pour la recherche de partenaire</legend>
              <TagsInput
                value={choicesTags}
                onChange={this.handleTags}
                tagProps={{
                  className: "react-tagsinput-tag info"
                }}
                inputProps={{ placeholder: "Ajouter un choix" }}
              />
            </GridItem>
            {choices && (
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Sélectionner le choix à mettre à jour
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={choiceId ? choiceId : ""}
                    onChange={e =>
                      dispatch({
                        type: SELECT_GOODDEALS_ACTIVITY_CHOICE_FR,
                        payload: e.target.value
                      })
                    }
                    inputProps={{
                      required: true,
                      name: "simpleSelect",
                      id: "simple-select"
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choix
                    </MenuItem>
                    {this.renderMenuItems(choices)}
                  </Select>
                </FormControl>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Description *"
                id="description-fr"
                formControlProps={{
                  fullWidth: true
                }}
                error={activityDescriptionErrorMsg !== null}
                helperText={activityDescriptionErrorMsg}
                inputProps={{
                  value: activityDescription ? activityDescription : "",
                  required: true,
                  onChange: e =>
                    dispatch({
                      type: SET_GOODDEALS_ACTIVITY_DESCRIPTION_FR,
                      payload: e.target.value
                    }),
                  multiline: true,
                  rows: 5
                }}
              />
              <CustomInput
                labelText="Post photo description"
                id="post-description-fr"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: activityPostDescription ? activityPostDescription : "",
                  onChange: e =>
                    dispatch({
                      type: SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_FR,
                      payload: e.target.value
                    }),
                  multiline: true,
                  rows: 3
                }}
              />
            </GridItem>
          </Fragment>
        )}
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    title,
    titleErrorMsg,
    promotion,
    description,
    descriptionErrorMsg,
    price,
    openingTime,
    templateId,
    choices,
    choicesTags,
    choiceId,
    activityDescriptionErrorMsg,
    activityDescription,
    activityPostDescription
  } = state.gooddealReducer;
  return {
    title,
    titleErrorMsg,
    promotion,
    description,
    descriptionErrorMsg,
    price,
    openingTime,
    templateId,
    choices,
    choicesTags,
    choiceId,
    activityDescriptionErrorMsg,
    activityDescription,
    activityPostDescription
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step2));
