import React from "react";

import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
// DigiHapi
import Activity from "components/DigiHapi/Activity.js";

// @material-ui/icons
import ListAlt from "@material-ui/icons/ListAlt";

import { fetchGetAudits } from "actions/audit";

// Redux
import { useDispatch, useSelector } from "react-redux";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

const styles = {
  ...extendedTablesStyle,
  ...commonStyles
};
const useStyles = makeStyles(styles);

export default function AuditsPage(props) {
  const classes = useStyles();

  const table = React.createRef();

  const dispatch = useDispatch();

  const {
    isFetchingList,
    audits: auditsParent,
    communities,
    users,
    totalPages
  } = useSelector(state => state.auditReducer);

  const audits = Object.values(auditsParent);

  const get2Chars = param => {
    return ("" + param).length === 1 ? "0" + param : param;
  };

  const formatDate = d => {
    const date = new Date(d);
    return `${get2Chars(date.getDate())}/${get2Chars(
      date.getMonth() + 1
    )}/${date.getFullYear()} ${get2Chars(date.getHours())}:${get2Chars(
      date.getMinutes()
    )}`;
  };

  const getAudits = () => {
    const result = audits.map(
      ({ type, userId, communityId, dateAdded, appname }) => {
        return {
          dateAdded: formatDate(dateAdded),
          pseudo: users[userId] ? users[userId].pseudo : "",
          type,
          community:
            communities && communities[communityId]
              ? communities[communityId].name
              : "",
          appname
        };
      }
    );
    return result;
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <ListAlt />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{"Traces d'audit"}</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={table}
              data={getAudits()}
              filterable
              columns={[
                {
                  Header: "Application",
                  accessor: "appname",
                  sortable: true,
                  filterable: false
                },
                {
                  Header: "Date",
                  accessor: "dateAdded",
                  sortable: true,
                  filterable: false
                },
                {
                  Header: "Pseudo",
                  accessor: "pseudo",
                  sortable: false,
                  filterable: false
                },
                {
                  Header: "Type",
                  accessor: "type",
                  sortable: true,
                  filterable: false
                },
                {
                  Header: "Communauté",
                  accessor: "community",
                  sortable: false,
                  filterable: false
                },
                {
                  Header: "",
                  accessor: "empty",
                  sortable: false,
                  filterable: false
                }
              ]}
              loading={isFetchingList}
              defaultPageSize={20}
              manual
              showPaginationTop
              showPaginationBottom={false}
              previousText="Précédent"
              nextText="Suivant"
              rowsText="Lignes"
              noDataText={"Aucunes traces d'audit"}
              ofText="sur"
              loadingText="Chargement..."
              className="-striped -highlight"
              pages={totalPages}
              onFetchData={(state, instance) => {
                dispatch(fetchGetAudits(state));
              }}
            />
          </CardBody>
          <CardFooter product>
            <div className={classes.w100}>
              <Button
                color="primary"
                onClick={() => dispatch(fetchGetAudits(table.current.state))}
                className={classes.floatRight}
                disabled={isFetchingList}
              >
                {isFetchingList ? <Activity /> : "Recharger"}
              </Button>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
