import { createSelector } from "reselect";
import _ from "lodash";

const getEquipments = state => state.equipmentReducer.equipments;
const getCommunityEquipments = state => state.communityReducer.equipments;

export const getUnusedEquipments = createSelector(
  [getEquipments, getCommunityEquipments],
  (equipments, communityEquipments) =>
    _.pickBy(equipments, equipment => !communityEquipments[equipment._id])
);
