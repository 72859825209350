import {
  SET_PROFILE_DETAILS,
  FETCH_GET_USER,
  SET_USER_FAILURE,
  SET_UPDATE_PROFILE_SUCCESS,
  SET_UPDATE_PROFILE_FAILURE,
  FETCH_UPDATE_PROFILE,
  ROLE_WEBMASTER,
  ROLE_ADMINISTRATOR
} from "./types";
import { apiAction } from "./api";
import { fetchGetGoodDealsCategories } from "./gooddeal";
import { fetchGetCampsites } from "./campsite";
import { fetchGetCurrentCommunityDetails } from "./community";
import { fetchGetGlobalInformationsVersions } from "./index";
import { fetchGetTemplatesDates, fetchGetTemplatesTimes } from "./template";
import { fetchGetEventTypes } from "./event";
import { fetchGetCities } from "./city";
import { fetchGetNeighborhoods } from "./neighborhood";
import {
  fetchGetCampsiteChannels,
  fetchGetCityChannels,
  fetchGetNeighborhoodChannels,
  fetchGetGooddealChannels,
  fetchGetEventChannels
} from "./channel";

export function fetchGetUser(alldata = true) {
  let onSuccess;

  if (alldata) {
    onSuccess = data => [
      setPseudo(data.pseudo),
      data.role === ROLE_WEBMASTER && fetchGetEventTypes(),
      fetchGetTemplatesDates(),
      fetchGetTemplatesTimes(),
      data.role === ROLE_WEBMASTER && fetchGetGoodDealsCategories(),
      (data.role === ROLE_WEBMASTER || data.role === ROLE_ADMINISTRATOR) &&
        fetchGetCampsiteChannels(),
      data.role === ROLE_WEBMASTER && fetchGetGooddealChannels(),
      data.role === ROLE_WEBMASTER && fetchGetEventChannels(),
      (data.role === ROLE_WEBMASTER || data.role === ROLE_ADMINISTRATOR) &&
        fetchGetCityChannels(),
      (data.role === ROLE_WEBMASTER || data.role === ROLE_ADMINISTRATOR) &&
        fetchGetNeighborhoodChannels(),
      data.role === ROLE_WEBMASTER && fetchGetCampsites(),
      fetchGetCities(),
      data.role === ROLE_WEBMASTER && fetchGetNeighborhoods(),
      fetchGetCurrentCommunityDetails(),
      fetchGetGlobalInformationsVersions(),
      setProfileDetails(data)
    ];
  } else {
    onSuccess = data => [setProfileDetails(data), setPseudo(data.pseudo)];
  }

  return apiAction({
    url: "/api/auth",
    method: "GET",
    onSuccess,
    onFailure: setUserFailure,
    label: FETCH_GET_USER
  });
}

function setProfileDetails(data) {
  return {
    type: SET_PROFILE_DETAILS,
    payload: data
  };
}

function setUserFailure(error) {
  return {
    type: SET_USER_FAILURE,
    payload: error
  };
}

function setPseudo(pseudo) {
  if (pseudo) {
    localStorage.setItem("emailOrPseudo", pseudo);
  }
}

export function fetchUpdateProfile(user) {
  return apiAction({
    url: "/api/user",
    data: user,
    method: "PUT",
    onSuccess: data => [setUpdateProfileSuccess(data), fetchGetUser(false)],
    onFailure: setUpdateProfileFailure,
    label: FETCH_UPDATE_PROFILE
  });
}

function setUpdateProfileSuccess(data) {
  return {
    type: SET_UPDATE_PROFILE_SUCCESS,
    payload: data
  };
}

function setUpdateProfileFailure(error) {
  return {
    type: SET_UPDATE_PROFILE_FAILURE,
    error
  };
}
