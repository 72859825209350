import axios from "axios";
import { API } from "../actions/types";
import { accessDenied, apiError, apiStart, apiEnd } from "../actions/api";
import { normalize } from "normalizr";

let lastRoutePathname = "";

const apiMiddleware = history => {
  return ({ dispatch }) => next => action => {
    next(action);

    if (
      action.type === "@@router/LOCATION_CHANGE" &&
      action.payload.location &&
      action.payload.location.pathname
    ) {
      lastRoutePathname = action.payload.location.pathname;
    }
    if (action.type !== API) return;

    const {
      url,
      method,
      data,
      onSuccess,
      onFailure,
      label,
      headers,
      schema,
      withCredentials
    } = action.payload;
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    // axios default configs
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
    axios.defaults.headers.common["Content-Type"] = "application/json";

    if (label) {
      dispatch(apiStart(label));
    }

    axios
      .request({
        url,
        method,
        headers,
        [dataOrParams]: data,
        withCredentials
      })
      .then(({ data }) => {
        if (schema) {
          dispatch(onSuccess(normalize(data, schema)));
        } else {
          dispatch(onSuccess(data));
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 503) {
          history.push("/maintenance");
        }

        dispatch(apiError(error, label));
        dispatch(onFailure(error));

        if (error.response && error.response.status === 403) {
          dispatch(accessDenied(window.location.pathname));
          if (lastRoutePathname !== "/") {
            history.push("/");
          }
          // if (lastRoutePathname.startsWith("/pro")) {
          //   history.push("/auth/connexion-pro");
          // } else {
          //   history.push("/auth/connexion");
          // }
        }

        if (
          error.message === "Network Error" &&
          withCredentials &&
          lastRoutePathname !== "/"
        ) {
          history.push("/");
          // if (lastRoutePathname.startsWith("/pro")) {
          //   history.push("/auth/connexion-pro");
          // } else {
          //   history.push("/auth/connexion");
          // }
        }
      })
      .then(() => {
        if (label) {
          dispatch(apiEnd(label));
        }
      });
  };
};

export default apiMiddleware;
