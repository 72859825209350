import {
  FETCH_GET_EQUIPMENTS,
  SET_EQUIPMENTS_RESULT,
  SET_EQUIPMENTS_FAILURE,
  FETCH_ADD_EQUIPMENT,
  SET_ADD_EQUIPMENT_RESULT,
  SET_ADD_EQUIPMENT_FAILURE,
  FETCH_UPDATE_EQUIPMENT,
  SET_UPDATE_EQUIPMENT_RESULT,
  SET_UPDATE_EQUIPMENT_FAILURE,
  FETCH_DELETE_EQUIPMENT,
  SET_DELETE_EQUIPMENT_RESULT,
  SET_DELETE_EQUIPMENT_FAILURE,
  FETCH_REORDER_EQUIPMENTS,
  SET_REORDER_EQUIPMENTS_RESULT,
  SET_REORDER_EQUIPMENTS_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

export const equipment = new schema.Entity(
  "equipments",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchReorderEquipments(data) {
  return apiAction({
    url: "/equipments/order",
    method: "POST",
    data,
    onSuccess: setReorderEquipmentsResult,
    onFailure: setReorderEquipmentsFailure,
    label: FETCH_REORDER_EQUIPMENTS
  });
}

function setReorderEquipmentsResult(data) {
  return {
    type: SET_REORDER_EQUIPMENTS_RESULT,
    payload: data
  };
}

function setReorderEquipmentsFailure(error) {
  return {
    type: SET_REORDER_EQUIPMENTS_FAILURE,
    error
  };
}

export function fetchGetEquipments(communityId) {
  return apiAction({
    url: `/equipments${communityId ? "?communityId=" + communityId : ""}`,
    method: "GET",
    schema: [equipment],
    onSuccess: setEquipmentsResult,
    onFailure: setEquipmentsFailure,
    label: FETCH_GET_EQUIPMENTS
  });
}

function setEquipmentsResult(normalized) {
  return {
    type: SET_EQUIPMENTS_RESULT,
    entities: normalized.entities
  };
}

function setEquipmentsFailure(error) {
  return {
    type: SET_EQUIPMENTS_FAILURE,
    error
  };
}

export function fetchAddEquipment(equipment) {
  return apiAction({
    url: "/equipments",
    method: "POST",
    data: equipment,
    onSuccess: setAddEquipmentsResult,
    onFailure: setAddEquipmentsFailure,
    label: FETCH_ADD_EQUIPMENT
  });
}

function setAddEquipmentsResult(data) {
  return {
    type: SET_ADD_EQUIPMENT_RESULT,
    payload: data
  };
}

function setAddEquipmentsFailure(error) {
  return {
    type: SET_ADD_EQUIPMENT_FAILURE,
    error
  };
}

export function fetchUpdateEquipment(equipment) {
  return apiAction({
    url: "/equipments/" + equipment._id,
    method: "PUT",
    data: equipment,
    onSuccess: setUpdateEquipmentsResult,
    onFailure: setUpdateEquipmentsFailure,
    label: FETCH_UPDATE_EQUIPMENT
  });
}

function setUpdateEquipmentsResult(data) {
  return {
    type: SET_UPDATE_EQUIPMENT_RESULT,
    payload: data
  };
}

function setUpdateEquipmentsFailure(error) {
  return {
    type: SET_UPDATE_EQUIPMENT_FAILURE,
    error
  };
}

export function fetchDeleteEquipment(equipmentId) {
  return apiAction({
    url: "/equipments/" + equipmentId,
    method: "DELETE",
    onSuccess: setDeleteEquipmentsResult,
    onFailure: setDeleteEquipmentsFailure,
    label: FETCH_DELETE_EQUIPMENT
  });

  function setDeleteEquipmentsResult(data) {
    return {
      type: SET_DELETE_EQUIPMENT_RESULT,
      payload: data
    };
  }

  function setDeleteEquipmentsFailure(error) {
    return {
      type: SET_DELETE_EQUIPMENT_FAILURE,
      error
    };
  }
}
