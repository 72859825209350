import {
  FETCH_ADD_NEIGHBORHOOD,
  SET_FETCH_ADD_NEIGHBORHOOD_RESULT,
  SET_FETCH_ADD_NEIGHBORHOOD_FAILURE,
  FETCH_UPDATE_NEIGHBORHOOD,
  SET_FETCH_UPDATE_NEIGHBORHOOD_RESULT,
  SET_FETCH_UPDATE_NEIGHBORHOOD_FAILURE,
  SET_NEIGHBORHOOD_DETAILS_RESULT,
  SET_NEIGHBORHOOD_DETAILS_FAILURE,
  FETCH_GET_NEIGHBORHOOD_DETAILS,
  SET_NEIGHBORHOODS,
  SET_NEIGHBORHOODS_FAILURE,
  FETCH_GET_NEIGHBORHOODS,
  FETCH_GET_CURRENT_NEIGHBORHOOD_DETAILS,
  SET_CURRENT_NEIGHBORHOOD_DETAILS_RESULT,
  SET_CURRENT_NEIGHBORHOOD_DETAILS_FAILURE,
  FETCH_DELETE_NEIGHBORHOOD,
  SET_FETCH_DELETE_NEIGHBORHOOD_RESULT,
  SET_FETCH_DELETE_NEIGHBORHOOD_FAILURE,
  FETCH_REORDER_NEIGHBORHOOD_EQUIPMENTS,
  SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_RESULT,
  SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_FAILURE,
  FETCH_REGISTER_NEIGHBORHOOD,
  SET_REGISTER_NEIGHBORHOOD_RESULT,
  SET_REGISTER_NEIGHBORHOOD_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";

const equipment = new schema.Entity(
  "equipments",
  {},
  {
    idAttribute: "_id"
  }
);

const city = new schema.Entity(
  "cities",
  {},
  {
    idAttribute: "_id"
  }
);

export const neighborhood = new schema.Entity(
  "neighborhood",
  {
    equipmentsIds: [equipment],
    citiesIds: [city]
  },
  {
    idAttribute: "_id"
  }
);

const neighborhoods = new schema.Entity(
  "neighborhoods",
  {},
  {
    idAttribute: "_id"
  }
);

export function fetchGetNeighborhoods() {
  return apiAction({
    url: "/neighborhoods",
    method: "GET",
    schema: [neighborhoods],
    onSuccess: setNeighborhoods,
    onFailure: setNeighborhoodsFailure,
    label: FETCH_GET_NEIGHBORHOODS
  });
}

function setNeighborhoods(normalized) {
  return {
    type: SET_NEIGHBORHOODS,
    entities: normalized.entities
  };
}

function setNeighborhoodsFailure(data) {
  return {
    type: SET_NEIGHBORHOODS_FAILURE,
    payload: data
  };
}

export function fetchGetCurrentNeighborhoodDetails() {
  return apiAction({
    url: "/neighborhoods/neighborhood",
    method: "GET",
    schema: neighborhood,
    onSuccess: setCurrentNeighborhoodDetailsResult,
    onFailure: setCurrentNeighborhoodDetailsFailure,
    label: FETCH_GET_CURRENT_NEIGHBORHOOD_DETAILS
  });
}

function setCurrentNeighborhoodDetailsResult(normalized) {
  return {
    type: SET_CURRENT_NEIGHBORHOOD_DETAILS_RESULT,
    entities: normalized.entities
  };
}

function setCurrentNeighborhoodDetailsFailure(error) {
  return {
    type: SET_CURRENT_NEIGHBORHOOD_DETAILS_FAILURE,
    error
  };
}

export function fetchGetNeighborhoodDetails(id) {
  return apiAction({
    url: "/neighborhoods/neighborhood/" + id,
    method: "GET",
    onSuccess: setNeighborhoodDetailsResult,
    onFailure: setNeighborhoodDetailsFailure,
    label: FETCH_GET_NEIGHBORHOOD_DETAILS
  });
}

function setNeighborhoodDetailsResult(data) {
  return {
    type: SET_NEIGHBORHOOD_DETAILS_RESULT,
    payload: data
  };
}

function setNeighborhoodDetailsFailure(error) {
  return {
    type: SET_NEIGHBORHOOD_DETAILS_FAILURE,
    error
  };
}

export function fetchDeleteNeighborhood(id, name) {
  return apiAction({
    url: "/neighborhoods/neighborhood/" + id + "?name=" + name,
    method: "DELETE",
    onSuccess: setDeleteNeighborhoodResult,
    onFailure: setDeleteNeighborhoodFailure,
    label: FETCH_DELETE_NEIGHBORHOOD
  });
}

function setDeleteNeighborhoodResult(data) {
  return {
    type: SET_FETCH_DELETE_NEIGHBORHOOD_RESULT,
    payload: data
  };
}

function setDeleteNeighborhoodFailure(error) {
  return {
    type: SET_FETCH_DELETE_NEIGHBORHOOD_FAILURE,
    error
  };
}

export function fetchAddNeighborhood(neighborhood) {
  return apiAction({
    url: "/neighborhoods",
    method: "POST",
    data: neighborhood,
    onSuccess: setFetchAddNeighborhoodResult,
    onFailure: setFetchAddNeighborhoodFailure,
    label: FETCH_ADD_NEIGHBORHOOD
  });
}

function setFetchAddNeighborhoodResult(data) {
  return {
    type: SET_FETCH_ADD_NEIGHBORHOOD_RESULT,
    payload: data
  };
}

function setFetchAddNeighborhoodFailure(error) {
  return {
    type: SET_FETCH_ADD_NEIGHBORHOOD_FAILURE,
    error
  };
}

export function fetchUpdateNeighborhood(neighborhood) {
  return apiAction({
    url: "/neighborhoods/neighborhood/" + neighborhood._id,
    method: "PUT",
    data: neighborhood,
    onSuccess: setFetchUpdateNeighborhoodResult,
    onFailure: setFetchUpdateNeighborhoodFailure,
    label: FETCH_UPDATE_NEIGHBORHOOD
  });
}

function setFetchUpdateNeighborhoodResult(data) {
  return {
    type: SET_FETCH_UPDATE_NEIGHBORHOOD_RESULT,
    payload: data
  };
}

function setFetchUpdateNeighborhoodFailure(error) {
  return {
    type: SET_FETCH_UPDATE_NEIGHBORHOOD_FAILURE,
    error
  };
}

export function fetchReorderNeighborhoodEquipments(communityId, equipmentsIds) {
  return apiAction({
    url: "/neighborhoods/neighborhood/" + communityId + "/equipments",
    method: "PUT",
    data: { equipmentsIds },
    onSuccess: setReorderNeighborhoodEquipmentsResult,
    onFailure: setReorderNeighborhoodEquipmentsFailure,
    label: FETCH_REORDER_NEIGHBORHOOD_EQUIPMENTS
  });
}

function setReorderNeighborhoodEquipmentsResult(data) {
  return {
    type: SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_RESULT,
    payload: data
  };
}

function setReorderNeighborhoodEquipmentsFailure(error) {
  return {
    type: SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_FAILURE,
    error
  };
}

export function fetchRegisterNeighborhood(data) {
  return apiAction({
    url: "/neighborhoods/register",
    method: "POST",
    data,
    onSuccess: setRegisterNeighborhoodResult,
    onFailure: setRegisterNeighborhoodFailure,
    label: FETCH_REGISTER_NEIGHBORHOOD
  });
}

function setRegisterNeighborhoodResult(data) {
  return {
    type: SET_REGISTER_NEIGHBORHOOD_RESULT,
    payload: data
  };
}

function setRegisterNeighborhoodFailure(error) {
  return {
    type: SET_REGISTER_NEIGHBORHOOD_FAILURE,
    error
  };
}
