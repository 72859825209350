import CommunityPage from "views/Pages/CommunityPage";
import UploadImagePage from "views/Pages/UploadImagePage";
import Templates from "views/Pages/Templates/TemplatesPage";
import TemplatesOrder from "views/Pages/Templates/TemplatesOrderPage";
import TemplatesCategories from "views/Pages/TemplatesCategories/TemplatesCategoriesPage";
import Places from "views/Pages/Places/PlacesPage";
import AboutPage from "views/Pages/AboutPage";
import Cities from "views/Pages/Cities/CitiesPage";
import Neighborhoods from "views/Pages/Neighborhoods/NeighborhoodsPage";
import Employees from "views/Pages/Employees/EmployeesPage";
import GoodDeals from "views/Pages/GoodDeals/GoodDealsPage";
import Posts from "views/Pages/Posts/PostsPage";
import ProfilePage from "views/Pages/ProfilePage";
import PasswordPage from "views/Pages/PasswordPage";
import AuditsPage from "views/Pages/Audits/AuditsPage";
import CommunityPlacesPage from "views/Pages/Places/CommunityPlacesPage";

// @material-ui/icons
import ListAlt from "@material-ui/icons/ListAlt";
import Image from "@material-ui/icons/Image";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import Loyalty from "@material-ui/icons/Loyalty";
import PlacesOrderPage from "views/Pages/Places/PlacesOrderPage";
import SupervisedUser from "@material-ui/icons/SupervisedUserCircle";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import Apartment from "@material-ui/icons/Apartment";

import { IoIosPeople } from "react-icons/io";
import { GiPaperTray } from "react-icons/gi";
import { FaRegNewspaper, FaHandHoldingHeart } from "react-icons/fa";
import { MdLocationCity } from "react-icons/md";
import { RiHeartsFill } from "react-icons/ri";
import { VscDebugStepInto } from "react-icons/vsc";

var dashRoutes = [
  {
    path: "/about",
    name: "À propos",
    component: AboutPage,
    layout: "/team",
    invisible: true
  },
  {
    path: "/community",
    name: "Communauté",
    component: CommunityPage,
    layout: "/team",
    invisible: true
  },
  {
    path: "/profile",
    name: "Profil",
    component: ProfilePage,
    layout: "/team",
    invisible: true
  },
  {
    path: "/change-password",
    name: "Mot de passe",
    component: PasswordPage,
    layout: "/team",
    invisible: true
  },
  {
    path: "/audits",
    name: "Traces d'audit",
    icon: ListAlt,
    component: AuditsPage,
    layout: "/webmaster"
  },
  {
    path: "/cities",
    name: "Villes",
    icon: MdLocationCity,
    component: Cities.CitiesPage,
    layout: "/webmaster"
  },
  {
    path: "/neighborhoods",
    name: "Voisinage",
    icon: Apartment,
    component: Neighborhoods.NeighborhoodsPage,
    layout: "/webmaster"
  },
  {
    collapse: true,
    name: "Réorganiser",
    icon: VerticalSplit,
    state: "reorderSystemCollapse",
    views: [
      {
        path: "/organize-system-places",
        name: "Lieux de rdv",
        icon: VscDebugStepInto,
        component: PlacesOrderPage,
        layout: "/webmaster"
      },
      {
        path: "/organize-system-templates",
        name: "Modèles",
        icon: FaRegNewspaper,
        component: TemplatesOrder.SystemTemplatesOrderPage,
        layout: "/webmaster"
      },
      {
        path: "/organize-system-sharing",
        name: "Instants partage",
        icon: RiHeartsFill,
        component: TemplatesOrder.SystemActivitiesOrderPage,
        layout: "/webmaster"
      },
      {
        path: "/organize-system-needs",
        name: "Besoins",
        icon: FaHandHoldingHeart,
        component: TemplatesOrder.SystemProposalsOrderPage,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/upload-image-page",
    name: "Téléverser images",
    icon: Image,
    component: UploadImagePage,
    layout: "/webmaster"
  },
  {
    path: "/posts",
    name: "Publications",
    icon: PlaylistAdd,
    component: Posts.PostsPage,
    layout: "/webmaster"
  },
  {
    path: "/gooddeals",
    name: "Vie locale",
    icon: Loyalty,
    component: GoodDeals.GoodDealsPage,
    layout: "/webmaster"
  },
  {
    collapse: true,
    name: "Configuration",
    icon: SupervisedUser,
    state: "webmasterCollapse",
    views: [
      {
        path: "/places",
        name: "Lieux de rdv",
        icon: VscDebugStepInto,
        component: Places.PlacesPage,
        layout: "/admin"
      },
      {
        path: "/members",
        name: "Membres",
        icon: IoIosPeople,
        component: Employees.EmployeesPage,
        layout: "/admin"
      },
      {
        collapse: true,
        name: "Modèles",
        icon: FaRegNewspaper,
        state: "templateCollapse",
        views: [
          {
            path: "/categories",
            name: "Catégories",
            icon: GiPaperTray,
            component: TemplatesCategories.TemplatesCategoriesPage,
            layout: "/team"
          },
          {
            path: "/templates",
            name: "Modèles",
            icon: FaRegNewspaper,
            component: Templates.TemplatesPage,
            layout: "/team"
          }
        ]
      },
      {
        collapse: true,
        name: "Instants partage",
        icon: RiHeartsFill,
        state: "sharingCollapse",
        views: [
          {
            path: "/sharing-categories",
            name: "Catégories",
            icon: GiPaperTray,
            component: TemplatesCategories.ActivitiesCategoriesPage,
            layout: "/admin"
          },
          {
            path: "/instant-sharing",
            name: "Instants partage",
            icon: RiHeartsFill,
            component: Templates.ActivitiesPage,
            layout: "/admin"
          }
        ]
      },
      {
        collapse: true,
        name: "Besoins",
        icon: FaHandHoldingHeart,
        state: "proposalsCollapse",
        views: [
          {
            path: "/need-categories",
            name: "Catégories",
            icon: GiPaperTray,
            component: TemplatesCategories.ProposalsCategoriesPage,
            layout: "/admin"
          },
          {
            path: "/needs",
            name: "Besoins",
            icon: FaHandHoldingHeart,
            component: Templates.ProposalsPage,
            layout: "/admin"
          }
        ]
      },
      {
        collapse: true,
        name: "Réorganiser",
        icon: VerticalSplit,
        state: "reorderCollapse",
        views: [
          {
            path: "/organize-places",
            name: "Lieux de rdv",
            icon: VscDebugStepInto,
            component: CommunityPlacesPage,
            layout: "/admin"
          },
          {
            path: "/organize-templates",
            name: "Modèles",
            icon: FaRegNewspaper,
            component: TemplatesOrder.TemplatesOrderPage,
            layout: "/admin"
          },
          {
            path: "/organize-sharing",
            name: "Instants partage",
            icon: RiHeartsFill,
            component: TemplatesOrder.ActivitiesOrderPage,
            layout: "/admin"
          },
          {
            path: "/organize-needs",
            name: "Besoins",
            icon: FaHandHoldingHeart,
            component: TemplatesOrder.ProposalsOrderPage,
            layout: "/admin"
          }
        ]
      }
    ]
  }
];
export default dashRoutes;
