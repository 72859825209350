import React from "react";

import defaultAvatar from "assets/img/default-avatar.png";

const fileTypes = ["jpg", "jpeg", "png", "gif"]; //acceptable file types

export default function PictureUpload(props) {
  let { disabled = false, onImageLoaded, onImageRemoved, photoUri } = props;

  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultAvatar);
  let fileInput = React.createRef();

  const handleImageChange = e => {
    e.preventDefault();

    const { files } = e.target;
    if (files && files[0]) {
      var extension = files[0].name
          .split(".")
          .pop()
          .toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types

      if (isSuccess) {
        let reader = new FileReader();
        reader.onloadend = () => {
          if (onImageLoaded) {
            const filename = files[0].name;
            const index = filename.indexOf(".");
            const length = filename.length;

            onImageLoaded(
              filename.substring(0, index < 0 ? length - 1 : index),
              reader.result
            );
          }
          setFile(files[0]);
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(files[0]);
      } else {
        //no
        //warning
      }
    }
  };

  const handleClick = e => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleRemove = e => {
    e.preventDefault();
    if (onImageRemoved) {
      onImageRemoved();
    }
    photoUri = null;
    setFile(null);
    setImagePreviewUrl(defaultAvatar);
    fileInput.current.value = null;
  };

  let accept = "";
  fileTypes.map(fileType => (accept += `${accept ? ",." : "."}${fileType}`));

  return (
    <div className="fileinput text-center">
      <input
        ref={fileInput}
        type="file"
        accept={accept}
        onChange={handleImageChange}
      />
      <div className="picture-container">
        <div
          className={
            "picture" +
            (disabled
              ? " picture-grey"
              : photoUri || file
              ? " picture-red"
              : "")
          }
          onClick={e =>
            disabled
              ? e.preventDefault()
              : photoUri || file
              ? handleRemove(e)
              : handleClick(e)
          }
        >
          <img
            className="picture-src"
            src={photoUri ? photoUri : imagePreviewUrl}
            alt="..."
          />
        </div>
      </div>
    </div>
  );
}
