import { Record } from "immutable";
import {
  API_END,
  API_START,
  SET_TEMPLATE_DESCRIPTION_FR,
  SET_TEMPLATE_DESCRIPTION_EN,
  SET_TEMPLATE_POST_DESCRIPTION_FR,
  SET_TEMPLATE_POST_DESCRIPTION_EN,
  SET_TEMPLATE_NAME_FR,
  SET_TEMPLATE_NAME_EN,
  SET_TEMPLATE_FILES_IMAGES,
  REMOVE_TEMPLATE_IMAGE,
  SET_ADD_TEMPLATE_RESULT,
  FETCH_ADD_TEMPLATE,
  SET_TEMPLATE_CATEGORY,
  SET_TEMPLATES_DATES_RESULT,
  SET_TEMPLATE_TIME,
  SET_TEMPLATE_DATE,
  SET_TEMPLATE_EQUIPMENT,
  TOGGLE_ACCEPT_NOTIF,
  RESET_TEMPLATE,
  SET_TEMPLATE_IMAGE_ERROR,
  SET_TEMPLATE_NAME_ERROR,
  SET_TEMPLATE_DESCRIPTION_ERROR,
  SET_TEMPLATE_CATEGORY_ERROR,
  SET_GET_TEMPLATE_DETAILS_RESULT,
  SET_TEMPLATE_NEW_CATEGORY,
  SET_UPDATE_TEMPLATE_RESULT,
  FETCH_GET_OWN_TEMPLATE_DETAILS,
  FETCH_UPDATE_TEMPLATE,
  FETCH_DELETE_TEMPLATE,
  SET_DELETE_TEMPLATE_RESULT,
  SET_ADD_TEMPLATE_FAILURE,
  SET_UPDATE_TEMPLATE_FAILURE,
  SET_DELETE_TEMPLATE_FAILURE,
  SET_TEMPLATE_PICTO,
  SET_TEMPLATE_SYSTEM,
  SET_TEMPLATES_RESULT,
  FETCH_GET_TEMPLATES,
  RESET_DATA,
  SET_CLONE_TEMPLATE_FAILURE,
  FETCH_CLONE_TEMPLATE,
  SET_CLONE_TEMPLATE_RESULT,
  TYPE_ACTIVITY,
  SET_CHOICES_TAGS,
  SELECT_ACTIVITY_CHOICE_FR,
  SET_ACTIVITY_DESCRIPTION_FR,
  SET_ACTIVITY_POST_DESCRIPTION_FR,
  SET_ACTIVITY_GOODDEAL_DESCRIPTION_FR,
  SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_FR,
  SET_ACTIVITY_DESCRIPTION_EN,
  SET_ACTIVITY_POST_DESCRIPTION_EN,
  SET_ACTIVITY_GOODDEAL_DESCRIPTION_EN,
  SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_EN,
  SELECT_ACTIVITY_CHOICE_EN,
  SET_ACTIVITY_NAME_EN,
  STEP2_VALIDATED
} from "actions/types";
import { t } from "../services/i18n";
import { Images } from "assets/Images";
import _ from "lodash";

const InitialState = new Record({
  isFetchingList: false,
  isFetching: false,
  _id: null,
  addTemplateResult: null,
  categoryId: null,
  newCategoryId: null,
  name: null,
  nameEn: null,
  description: "",
  postDescription: "",
  gooddealDescription: "",
  gooddealPostDescription: "",
  descriptionEn: "",
  postDescriptionEn: "",
  gooddealDescriptionEn: "",
  gooddealPostDescriptionEn: "",
  lang: null,
  choices: null,
  choicesTags: [],
  templates: {},
  photoUri: null,
  picto: null,
  files: [],
  dates: {},
  displayTime: false,
  dateId: null,
  timeId: null,
  equipmentId: null,
  acceptNotif: false,
  imageErrorMsg: null,
  nameErrorMsg: null,
  descriptionErrorMsg: null,
  categoryErrorMsg: null,
  snackInfoMsg: null,
  snackErrorMsg: null,
  newWizard: null,
  pictos: Object.keys(Images),
  type: null,
  system: false,
  choiceId: null,
  usedByGooddeals: false
});

const initialState = new InitialState();
export default function uploadReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_TEMPLATES) {
        return state.set("isFetchingList", true).set("templates", {});
      }
      if (
        action.payload === FETCH_ADD_TEMPLATE ||
        action.payload === FETCH_UPDATE_TEMPLATE ||
        action.payload === FETCH_DELETE_TEMPLATE ||
        action.payload === FETCH_CLONE_TEMPLATE
      ) {
        return state
          .set("isFetching", true)
          .set("snackInfoMsg", null)
          .set("snackErrorMsg", null);
      }
      if (action.payload === FETCH_GET_OWN_TEMPLATE_DETAILS) {
        return state
          .set("isFetching", true)
          .set("newWizard", null)
          .set("imageErrorMsg", null)
          .set("descriptionErrorMsg", null)
          .set("snackInfoMsg", null)
          .set("snackErrorMsg", null);
      }
      break;

    case RESET_DATA:
      return initialState;

    case SET_CHOICES_TAGS: {
      if (state.choicesTags.length < action.payload.length) {
        const value = action.payload[action.payload.length - 1];
        if (state.choicesTags.indexOf(value) === -1) {
          // We add a choice only if not already present
          let choices = {
            ...(state.choices || {
              [state.name]: {
                name: state.name,
                description: state.description,
                postDescription: state.postDescription
              }
            }),
            [value]: {
              name: value
            }
          };
          return state
            .set(
              "choicesTags",
              state.choices ? action.payload : [state.name, ...action.payload]
            )
            .set("choices", choices)
            .set("choiceId", value)
            .set("description", null)
            .set("postDescription", null)
            .set("descriptionErrorMsg", null)
            .set("gooddealDescription", null)
            .set("gooddealPostDescription", null);
        }
      } else {
        // We remove a choice
        // Array.prototype.diff = function(arr2) {
        //   return this.filter(x => !arr2.includes(x));
        // };

        const removedChoice = _.difference(state.choicesTags, action.payload);
        const obj = Object.values(state.choices).find(
          obj => obj.name === removedChoice[0]
        );
        let { [obj.name]: ignored, ...choices } = state.choices;
        const choicesValues = Object.values(choices);
        if (choicesValues.length <= 1) {
          const {
            description,
            postDescription,
            gooddealDescription,
            gooddealPostDescription,
            lang
          } = choicesValues.length === 0 ? ignored : choicesValues[0];
          return state
            .set("choicesTags", [])
            .set("choices", null)
            .set("choiceId", null)
            .set("lang", lang)
            .set("description", description)
            .set("postDescription", postDescription)
            .set("gooddealDescription", gooddealDescription)
            .set("gooddealPostDescription", gooddealPostDescription)
            .set("descriptionErrorMsg", null);
        }
        return state
          .set("choicesTags", action.payload)
          .set("choices", choices)
          .set(
            "choiceId",
            state.choiceId === removedChoice[0]
              ? action.payload[0]
              : state.choiceId
          )
          .set(
            "description",
            state.choiceId === removedChoice[0]
              ? choices[action.payload[0]].description
              : state.description
          )
          .set(
            "postDescription",
            state.choiceId === removedChoice[0]
              ? choices[action.payload[0]].postDescription
              : state.postDescription
          )
          .set(
            "gooddealDescription",
            state.choiceId === removedChoice[0]
              ? choices[action.payload[0]].gooddealDescription
              : state.gooddealDescription
          )
          .set(
            "gooddealPostDescription",
            state.choiceId === removedChoice[0]
              ? choices[action.payload[0]].gooddealPostDescription
              : state.gooddealPostDescription
          )
          .set("descriptionErrorMsg", null);
      }
      break;
    }

    case SET_ACTIVITY_DESCRIPTION_FR: {
      if (state.choiceId) {
        return state
          .setIn(["choices", state.choiceId, "description"], action.payload)
          .set("description", action.payload)
          .set("descriptionErrorMsg", null);
      }
      return state
        .set("description", action.payload)
        .set("descriptionErrorMsg", null);
    }

    case SET_ACTIVITY_GOODDEAL_DESCRIPTION_FR: {
      if (state.choiceId) {
        return state
          .setIn(
            ["choices", state.choiceId, "gooddealDescription"],
            action.payload
          )
          .set("usedByGooddeals", true)
          .set("gooddealDescription", action.payload);
      }
      return state
        .set("gooddealDescription", action.payload)
        .set("usedByGooddeals", true);
    }

    case SET_ACTIVITY_POST_DESCRIPTION_FR: {
      if (state.choiceId) {
        return state
          .setIn(["choices", state.choiceId, "postDescription"], action.payload)
          .set("postDescription", action.payload);
      }
      return state.set("postDescription", action.payload);
    }

    case SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_FR: {
      if (state.choiceId) {
        return state
          .setIn(
            ["choices", state.choiceId, "gooddealPostDescription"],
            action.payload
          )
          .set("usedByGooddeals", true)
          .set("gooddealPostDescription", action.payload);
      }
      return state
        .set("gooddealPostDescription", action.payload)
        .set("usedByGooddeals", true);
    }

    case SET_ACTIVITY_DESCRIPTION_EN: {
      let en;
      if (state.choiceId) {
        en =
          state.choiceId &&
          state.choices[state.choiceId] &&
          state.choices[state.choiceId].lang
            ? state.choices[state.choiceId].lang.en
            : {};
      } else {
        en = state.lang ? state.lang.en : {};
      }
      en = {
        en: {
          ...en,
          description: action.payload
        }
      };
      if (state.choiceId) {
        return state
          .setIn(["choices", state.choiceId, "lang"], en)
          .set("descriptionEn", action.payload);
      }
      return state.set("lang", en).set("descriptionEn", action.payload);
    }

    case SET_ACTIVITY_GOODDEAL_DESCRIPTION_EN: {
      let en =
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang
          ? state.choices[state.choiceId].lang.en
          : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            gooddealDescription: action.payload
          }
        };
      }
      if (state.choiceId) {
        return state
          .setIn(["choices", state.choiceId, "lang"], en)
          .set("gooddealDescriptionEn", action.payload);
      }
      return state.set("gooddealDescriptionEn", action.payload);
    }

    case SET_ACTIVITY_POST_DESCRIPTION_EN: {
      let en;
      if (state.choiceId) {
        en =
          state.choiceId &&
          state.choices[state.choiceId] &&
          state.choices[state.choiceId].lang
            ? state.choices[state.choiceId].lang.en
            : {};
      } else {
        en = state.lang ? state.lang.en : {};
      }
      en = {
        en: {
          ...en,
          postDescription: action.payload
        }
      };
      if (state.choiceId) {
        return state
          .setIn(["choices", state.choiceId, "lang"], en)
          .set("postDescriptionEn", action.payload);
      }
      return state.set("lang", en).set("postDescriptionEn", action.payload);
    }

    case SET_ACTIVITY_GOODDEAL_POST_DESCRIPTION_EN: {
      let en =
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang
          ? state.choices[state.choiceId].lang.en
          : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            gooddealPostDescription: action.payload
          }
        };
      }
      if (state.choiceId) {
        return state
          .setIn(["choices", state.choiceId, "lang"], en)
          .set("gooddealPostDescriptionEn", action.payload);
      }
      return state.set("gooddealPostDescriptionEn", action.payload);
    }

    case SET_ACTIVITY_NAME_EN: {
      let en =
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang
          ? state.choices[state.choiceId].lang.en
          : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            name: action.payload
          }
        };
      }
      if (state.choiceId) {
        return state
          .setIn(["choices", state.choiceId, "lang"], en)
          .set("nameEn", action.payload);
      }
      return state.set("nameEn", action.payload);
    }

    case STEP2_VALIDATED: {
      let name,
        description,
        postDescription,
        gooddealDescription,
        gooddealPostDescription = null;
      if (
        state.choices &&
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang &&
        state.choices[state.choiceId].lang.en
      ) {
        name = state.choices[state.choiceId].lang.en.name;
        description = state.choices[state.choiceId].lang.en.description;
        postDescription = state.choices[state.choiceId].lang.en.postDescription;
        gooddealDescription =
          state.choices[state.choiceId].lang.en.gooddealDescription;
        gooddealPostDescription =
          state.choices[state.choiceId].lang.en.gooddealPostDescription;
      } else if (state.lang && state.lang.en) {
        name = state.lang.en.name;
        description = state.lang.en.description;
        postDescription = state.lang.en.postDescription;
        gooddealDescription = state.lang.en.gooddealDescription;
        gooddealPostDescription = state.lang.en.gooddealPostDescription;
      }
      return state
        .set("nameEn", name)
        .set("descriptionEn", description)
        .set("postDescriptionEn", postDescription)
        .set("gooddealDescriptionEn", gooddealDescription)
        .set("gooddealPostDescriptionEn", gooddealPostDescription);
    }

    case SELECT_ACTIVITY_CHOICE_FR: {
      const {
        description,
        postDescription,
        gooddealDescription,
        gooddealPostDescription
      } = state.choices[action.payload];
      return state
        .set("choiceId", action.payload)
        .set("description", description)
        .set("postDescription", postDescription)
        .set("gooddealDescription", gooddealDescription)
        .set("gooddealPostDescription", gooddealPostDescription)
        .set("descriptionErrorMsg", null);
    }

    case SELECT_ACTIVITY_CHOICE_EN: {
      let name,
        description,
        postDescription,
        gooddealDescription,
        gooddealPostDescription = null;
      if (
        state.choices[action.payload] &&
        state.choices[action.payload].lang &&
        state.choices[action.payload].lang.en
      ) {
        name = state.choices[action.payload].lang.en.name;
        description = state.choices[action.payload].lang.en.description;
        postDescription = state.choices[action.payload].lang.en.postDescription;
        gooddealDescription =
          state.choices[action.payload].lang.en.gooddealDescription;
        gooddealPostDescription =
          state.choices[action.payload].lang.en.gooddealPostDescription;
      }
      return state
        .set("choiceId", action.payload)
        .set("nameEn", name)
        .set("descriptionEn", description)
        .set("postDescriptionEn", postDescription)
        .set("gooddealDescriptionEn", gooddealDescription)
        .set("gooddealPostDescriptionEn", gooddealPostDescription);
    }

    case RESET_TEMPLATE:
      return initialState
        .set("templates", state.templates)
        .set("dates", state.dates);

    case REMOVE_TEMPLATE_IMAGE:
      return state
        .set("files", [])
        .set("photoUri", null)
        .set("imageErrorMsg", null);

    case SET_TEMPLATE_IMAGE_ERROR:
      return state.set("imageErrorMsg", t("template:imagemandatory"));

    case SET_TEMPLATE_NAME_ERROR:
      return state.set("nameErrorMsg", t("template:namemandatory"));

    case SET_TEMPLATE_CATEGORY_ERROR:
      return state.set("categoryErrorMsg", t("template:categorymandatory"));

    case SET_TEMPLATE_SYSTEM:
      return state.set("system", action.payload);

    case SET_GET_TEMPLATE_DETAILS_RESULT: {
      const {
        _id,
        name,
        description,
        postDescription,
        lang,
        dateId,
        timeId,
        equipmentId,
        photoUri,
        picto,
        acceptNotif,
        system,
        choices,
        gooddealDescription,
        gooddealPostDescription,
        usedByGooddeals
      } = action.payload;

      let choicesTags = [];
      let choiceId = null;
      if (choices) {
        const choicesValues = Object.values(choices);
        choicesTags = choicesValues.map(choice => choice.name);
        if (!choicesTags[0]) {
          // TO REMOVE after migration
          // Missing name => migration procedure : add name to each items
          choicesTags = Object.keys(choices);
          for (let index = 0; index < choicesValues.length; index++) {
            choicesValues[index].name = choicesTags[index];
          }
        }
        choiceId = choicesTags[0];
      }
      return state
        .set("_id", _id)
        .set("newWizard", "update")
        .set("name", name)
        .set("acceptNotif", acceptNotif)
        .set(
          "description",
          choiceId ? choices[choiceId].description : description
        )
        .set(
          "postDescription",
          choiceId ? choices[choiceId].postDescription : postDescription
        )
        .set(
          "gooddealDescription",
          choiceId ? choices[choiceId].gooddealDescription : gooddealDescription
        )
        .set(
          "gooddealPostDescription",
          choiceId
            ? choices[choiceId].gooddealPostDescription
            : gooddealPostDescription
        )
        .set("photoUri", photoUri)
        .set("picto", picto)
        .set("lang", lang)
        .set("dateId", dateId)
        .set("timeId", timeId)
        .set("displayTime", timeId !== null)
        .set("choices", choices)
        .set("choicesTags", choicesTags)
        .set("choiceId", choiceId)
        .set("system", system)
        .set("equipmentId", equipmentId)
        .set("usedByGooddeals", usedByGooddeals);
    }

    case SET_TEMPLATE_DESCRIPTION_ERROR:
      if (action.choiceId) {
        return state
          .set("descriptionErrorMsg", t("template:descriptionmandatory"))
          .set("choiceId", action.choiceId)
          .set("description", state.choices[action.choiceId].description)
          .set(
            "postDescription",
            state.choices[action.choiceId].postDescription
          );
      } else {
        return state.set(
          "descriptionErrorMsg",
          t("template:descriptionmandatory")
        );
      }

    case SET_TEMPLATES_RESULT:
      return state.set("templates", action.entities.templates);

    case SET_TEMPLATE_CATEGORY:
      return state
        .set("categoryId", action.categoryId)
        .set("categoryErrorMsg", null);

    case SET_TEMPLATE_NEW_CATEGORY:
      return state
        .set("newCategoryId", action.categoryId)
        .set("categoryErrorMsg", null);

    case SET_TEMPLATE_NAME_FR:
      return state.set("name", action.payload).set("nameErrorMsg", null);

    case SET_TEMPLATE_NAME_EN: {
      let en = state.get("lang") ? state.get("lang").en : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            name: action.payload
          }
        };
      }
      return state.set("lang", en);
    }

    case SET_TEMPLATE_DESCRIPTION_FR:
      return state
        .set("description", action.payload)
        .set("descriptionErrorMsg", null);

    case SET_TEMPLATE_POST_DESCRIPTION_FR:
      return state.set("postDescription", action.payload);

    case SET_TEMPLATE_DESCRIPTION_EN: {
      let en = state.lang ? state.lang.en : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            description: action.payload
          }
        };
      }
      return state.set("lang", en);
    }

    case SET_TEMPLATE_POST_DESCRIPTION_EN: {
      let en = state.lang ? state.lang.en : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            postDescription: action.payload
          }
        };
      }
      return state.set("lang", en);
    }

    case SET_TEMPLATE_FILES_IMAGES:
      return state
        .set("files", action.payload)
        .set("photoUri", null)
        .set("imageErrorMsg", null);

    case SET_TEMPLATE_PICTO:
      return state.set("picto", action.payload);

    case SET_ADD_TEMPLATE_RESULT:
      return initialState
        .set("snackInfoMsg", t("template:addsuccess"))
        .set("dates", state.dates)
        .set("type", state.type);

    case SET_UPDATE_TEMPLATE_RESULT:
      return initialState
        .set("snackInfoMsg", t("template:updatesuccess"))
        .set("dates", state.dates)
        .set("type", state.type);

    case SET_DELETE_TEMPLATE_RESULT:
      return state.set("snackInfoMsg", t("template:deletesuccess"));

    case SET_CLONE_TEMPLATE_RESULT:
      return state.set("snackInfoMsg", t("template:clonetemplatesuccess"));

    case SET_CLONE_TEMPLATE_FAILURE:
      return state.set(
        "snackErrorMsg",
        state.type === TYPE_ACTIVITY
          ? t("template:cloneactivityerror")
          : t("template:clonetemplateerror")
      );

    case SET_ADD_TEMPLATE_FAILURE:
      return state.set("snackErrorMsg", t("template:adderror"));

    case SET_UPDATE_TEMPLATE_FAILURE:
      return state.set("snackErrorMsg", t("template:updateerror"));

    case SET_DELETE_TEMPLATE_FAILURE:
      return state.set("snackErrorMsg", t("template:deleteerror"));

    case SET_TEMPLATES_DATES_RESULT:
      return state.set("dates", action.entities.templateDates);

    case SET_TEMPLATE_DATE:
      if (
        !action.dateId ||
        state.dates[action.dateId].name === "À définir ensemble" ||
        state.dates[action.dateId].name === "Maintenant"
      ) {
        return state
          .set("dateId", action.dateId)
          .set("timeId", null)
          .set("displayTime", false);
      } else {
        return state.set("displayTime", true).set("dateId", action.dateId);
      }

    case SET_TEMPLATE_TIME:
      return state.set("timeId", action.timeId);

    case SET_TEMPLATE_EQUIPMENT:
      return state.set("equipmentId", action.equipmentId);

    case TOGGLE_ACCEPT_NOTIF:
      return state.set("acceptNotif", !state.acceptNotif);

    case API_END:
      if (action.payload === FETCH_GET_TEMPLATES) {
        return state.set("isFetchingList", false);
      }
      if (
        action.payload === FETCH_ADD_TEMPLATE ||
        action.payload === FETCH_UPDATE_TEMPLATE ||
        action.payload === FETCH_DELETE_TEMPLATE ||
        action.payload === FETCH_GET_OWN_TEMPLATE_DETAILS ||
        action.payload === FETCH_CLONE_TEMPLATE
      ) {
        return state.set("isFetching", false);
      }
      break;

    default:
      return state;
  }
  return state;
}
