// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";

import {
  SET_TEMPLATE_DATE,
  SET_TEMPLATE_TIME,
  SET_TEMPLATE_EQUIPMENT
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle,
  ...customSelectStyle,
  ...regularFormsStyle
};

class Step4 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }

  renderItems = data => {
    const { classes } = this.props;
    const components = [];

    if (data) {
      // eslint-disable-next-line
      for (const obj of data) {
        components.push(
          <MenuItem
            key={obj._id}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={obj._id}
          >
            {obj.name}
          </MenuItem>
        );
      }
    }
    return components;
  };

  render() {
    const {
      classes,
      dispatch,
      isFetching,
      dates,
      times,
      equipments,
      dateId,
      timeId,
      equipmentId,
      displayTime
    } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Jour
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={dateId ? dateId : ""}
              onChange={(e, value) =>
                dispatch({
                  type: SET_TEMPLATE_DATE,
                  dateId: e.target.value,
                  text: value.props.children
                })
              }
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
              disabled={isFetching}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Sélectionner jour
              </MenuItem>
              {this.renderItems(dates)}
            </Select>
          </FormControl>
          {displayTime && (
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Heure
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={timeId ? timeId : ""}
                onChange={e =>
                  dispatch({
                    type: SET_TEMPLATE_TIME,
                    timeId: e.target.value
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
                disabled={isFetching}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Sélectionner heure
                </MenuItem>
                {this.renderItems(times)}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Lieu de rendez-vous
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={equipmentId ? equipmentId : ""}
              onChange={e =>
                dispatch({
                  type: SET_TEMPLATE_EQUIPMENT,
                  equipmentId: e.target.value
                })
              }
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
              disabled={isFetching}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Sélectionner lieu de rendez-vous
              </MenuItem>
              {this.renderItems(equipments)}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
    );
  }
}

Step4.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    dateId,
    timeId,
    equipmentId,
    dates,
    displayTime
  } = state.templateReducer;

  const { templateTimes } = state.entitiesReducer;
  const { equipments } = state.equipmentReducer;

  return {
    dates: Object.values(dates),
    times: Object.values(templateTimes),
    equipments: equipments && Object.values(equipments),
    dateId,
    timeId,
    equipmentId,
    displayTime
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step4));
