// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import { connect } from "react-redux";
import GoogleMapHandlerComponent from "views/Maps/GoogleMapHandlerComponent";

import {
  SET_NEIGHBORHOOD_LATITUDE,
  SET_NEIGHBORHOOD_LONGITUDE
} from "actions/types.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Step3 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }

  onMarkerChange = (latitude, longitude) => {
    const { dispatch } = this.props;

    dispatch({
      type: SET_NEIGHBORHOOD_LATITUDE,
      value: latitude
    });
    dispatch({
      type: SET_NEIGHBORHOOD_LONGITUDE,
      value: longitude
    });
  };

  render() {
    const { classes, latitude, longitude, dispatch } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Veuillez localiser la communauté de voisinage sur la carte en
            déplaçant le marqueur
          </h4>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <div
            style={{
              height: `480px`,
              borderRadius: "6px",
              overflow: "hidden"
            }}
          >
            <GoogleMapHandlerComponent
              classes={classes}
              dispatch={dispatch}
              zoom={15}
              latitude={latitude}
              longitude={longitude}
              onMarkerChange={this.onMarkerChange}
              onGetCurrentPosition={this.onMarkerChange}
            />
          </div>
        </GridItem>
        <GridItem xs={6} sm={3} md={3}>
          <CustomInput
            labelText="Latitude"
            id="latitude"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "number",
              value: latitude ? latitude : 0,
              onChange: e =>
                dispatch({
                  type: SET_NEIGHBORHOOD_LATITUDE,
                  value: Number(e.target.value)
                })
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={3} md={3}>
          <CustomInput
            labelText="Longitude"
            id="longitude"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "number",
              value: longitude ? longitude : 0,
              onChange: e =>
                dispatch({
                  type: SET_NEIGHBORHOOD_LONGITUDE,
                  value: Number(e.target.value)
                })
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const { latitude, longitude } = state.neighborhoodReducer;
  return {
    latitude,
    longitude
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step3));
