import {
  SET_EMPLOYEES_RESULT,
  SET_EMPLOYEES_FAILURE,
  FETCH_GET_EMPLOYEES,
  FETCH_REGISTER_EMPLOYEE,
  SET_REGISTER_EMPLOYEE_RESULT,
  SET_REGISTER_EMPLOYEE_FAILURE,
  FETCH_DELETE_EMPLOYEE,
  SET_DELETE_EMPLOYEE_RESULT,
  SET_DELETE_EMPLOYEE_FAILURE,
  SET_EMPLOYEE_GENDER,
  SET_EMPLOYEE_LAST_NAME,
  SET_EMPLOYEE_FIRST_NAME,
  SET_EMPLOYEE_EMAIL,
  CLEAR_EMPLOYEE_EMAIL,
  SET_ADD_EMPLOYEE_ROLE,
  CLEAR_EMPLOYEE_VALIDATE,
  VALIDATE_EMPLOYEE_INPUTS,
  RESET_EMPLOYEE
} from "./types";
import { apiAction } from "./api";

export function fetchGetEmployees(communityId) {
  return apiAction({
    url: `/api/employees${communityId ? "?communityId=" + communityId : ""}`,
    method: "GET",
    onSuccess: setEmployeesResult,
    onFailure: setEmployeesFailure,
    label: FETCH_GET_EMPLOYEES
  });
}

function setEmployeesResult(data) {
  return {
    type: SET_EMPLOYEES_RESULT,
    payload: data
  };
}

function setEmployeesFailure(error) {
  return {
    type: SET_EMPLOYEES_FAILURE,
    error
  };
}

export function fetchRegisterEmployee(data) {
  return apiAction({
    url: "/api/register",
    method: "POST",
    data,
    onSuccess: setRegisterEmployeeResult,
    onFailure: setRegisterEmployeeFailure,
    label: FETCH_REGISTER_EMPLOYEE
  });
}

function setRegisterEmployeeResult(message) {
  return {
    type: SET_REGISTER_EMPLOYEE_RESULT,
    payload: message
  };
}

function setRegisterEmployeeFailure(error) {
  return {
    type: SET_REGISTER_EMPLOYEE_FAILURE,
    payload: error
  };
}

export function fetchDeleteEmployee(id) {
  return apiAction({
    url: "/api/employee/" + id,
    method: "DELETE",
    onSuccess: setDeleteEmployeeResult,
    onFailure: setDeleteEmployeeFailure,
    label: FETCH_DELETE_EMPLOYEE
  });
}

function setDeleteEmployeeResult(message) {
  return {
    type: SET_DELETE_EMPLOYEE_RESULT,
    payload: message
  };
}

function setDeleteEmployeeFailure(error) {
  return {
    type: SET_DELETE_EMPLOYEE_FAILURE,
    payload: error
  };
}

export function setEmployeeGender(payload) {
  return {
    type: SET_EMPLOYEE_GENDER,
    payload
  };
}

export function setEmployeeEmail(payload) {
  return {
    type: SET_EMPLOYEE_EMAIL,
    payload
  };
}

export function setEmployeeFirstname(payload) {
  return {
    type: SET_EMPLOYEE_FIRST_NAME,
    payload
  };
}

export function setEmployeeLastname(payload) {
  return {
    type: SET_EMPLOYEE_LAST_NAME,
    payload
  };
}

export function clearEmployeeEmail() {
  return {
    type: CLEAR_EMPLOYEE_EMAIL
  };
}

export function addEmployeeRole(payload) {
  return {
    type: SET_ADD_EMPLOYEE_ROLE,
    payload
  };
}

export function clearEmployeeValidate() {
  return {
    type: CLEAR_EMPLOYEE_VALIDATE
  };
}

export function validateEmployeeInputs() {
  return {
    type: VALIDATE_EMPLOYEE_INPUTS
  };
}

export function resetEmployeeData() {
  return {
    type: RESET_EMPLOYEE
  };
}
