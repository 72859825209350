// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {
  SET_POST_TYPE_ERROR,
  SET_POST_TYPE,
  SET_POST_GOODDEAL,
  SET_POST_URL,
  SET_POST_URL_ERROR,
  CLEAR_POST_URL,
  SET_POST_GOODDEAL_ERROR,
  SET_POST_DATE,
  SET_POST_START_DATE_ERROR
} from "actions/types";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Datetime from "react-datetime";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// material ui icons
import Close from "@material-ui/icons/Close";

import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGetOwnGoodDealsTitles } from "actions/gooddeal";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle,
  ...customSelectStyle,
  ...regularFormsStyle
};

class Step1 extends React.Component {
  sendState() {
    return this.state;
  }

  componentDidMount() {
    this.props.fetchGetOwnGoodDealsTitles();
  }

  isValidated() {
    const {
      dispatch,
      eventType,
      urlState,
      isGoodDealType,
      goodDeal,
      isDateMandatory,
      startDate
    } = this.props;

    let success = true;
    if (!eventType) {
      dispatch({
        type: SET_POST_TYPE_ERROR
      });
      success = false;
    }
    if (urlState === "error") {
      dispatch({
        type: SET_POST_URL_ERROR
      });
      success = false;
    }
    if (isGoodDealType && !goodDeal) {
      dispatch({
        type: SET_POST_GOODDEAL_ERROR
      });
      success = false;
    }
    if (isDateMandatory && !startDate) {
      dispatch({
        type: SET_POST_START_DATE_ERROR
      });
      success = false;
    }
    return success;
  }

  renderMenuItems = data => {
    const { classes, goodDeals } = this.props;
    const components = [];

    // eslint-disable-next-line
    for (const obj of data) {
      if (obj.name !== "Bon plan" || goodDeals.length > 0) {
        components.push(
          <MenuItem
            key={obj._id}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={obj}
          >
            {obj.name}
          </MenuItem>
        );
      }
    }
    return components;
  };

  renderItems = goodDeals => {
    const { classes } = this.props;
    const components = [];

    if (goodDeals) {
      // eslint-disable-next-line
      for (const goodDeal of goodDeals) {
        components.push(
          <MenuItem
            key={goodDeal._id}
            classes={{
              root: classes.selectMenuItem + " " + classes.myInputLabelStyle,
              selected:
                classes.selectMenuItemSelected + " " + classes.myInputLabelStyle
            }}
            value={goodDeal}
          >
            {goodDeal.title}
          </MenuItem>
        );
      }
    }
    return components;
  };

  render() {
    const {
      dispatch,
      classes,
      eventTypes,
      eventType,
      typeErrorMsg,
      isGoodDealType,
      goodDeals,
      goodDeal,
      goodDealErrorMsg,
      urlState,
      url,
      urlErrorMsg,
      startDate,
      isDateMandatory,
      startDateErrorMsg
    } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Type *
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={eventType ? eventType : ""}
              onChange={e =>
                dispatch({
                  type: SET_POST_TYPE,
                  payload: e.target.value
                })
              }
              inputProps={{
                required: true,
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Sélectionner le type de publication
              </MenuItem>
              {this.renderMenuItems(eventTypes)}
            </Select>
            {typeErrorMsg && (
              <FormHelperText className={classes.labelRootError}>
                {typeErrorMsg}
              </FormHelperText>
            )}
          </FormControl>
        </GridItem>
        {isGoodDealType ? (
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Bon plan *
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={goodDeal ? goodDeal : ""}
                onChange={e =>
                  dispatch({
                    type: SET_POST_GOODDEAL,
                    payload: e.target.value
                  })
                }
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Sélectionner le bon plan
                </MenuItem>
                {this.renderItems(goodDeals)}
              </Select>
            </FormControl>
            {goodDealErrorMsg && (
              <FormHelperText className={classes.labelRootError}>
                {goodDealErrorMsg}
              </FormHelperText>
            )}
          </GridItem>
        ) : (
          <Fragment>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                helperText={urlErrorMsg}
                success={urlState === "success"}
                error={urlState === "error"}
                labelText="Url de redirection"
                id="url"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: url ? url : "",
                  onChange: e =>
                    dispatch({
                      type: SET_POST_URL,
                      payload: e.target.value
                    }),
                  type: "text",
                  endAdornment:
                    urlState === "error" ? (
                      <InputAdornment position="end">
                        <Close
                          className={classes.danger}
                          onClick={() =>
                            dispatch({
                              type: CLEAR_POST_URL
                            })
                          }
                        />
                      </InputAdornment>
                    ) : (
                      undefined
                    )
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <InputLabel className={classes.label}>
                {"Date" + (isDateMandatory ? " *" : "")}
              </InputLabel>
              <FormControl fullWidth>
                <Datetime
                  className={classes.underline}
                  inputProps={{
                    placeholder: "dd/MM/yyyy HH:mm"
                  }}
                  locale="fr_FR"
                  value={startDate}
                  onChange={value =>
                    dispatch({
                      type: SET_POST_DATE,
                      payload: value
                    })
                  }
                />
              </FormControl>
              {startDateErrorMsg && (
                <FormHelperText className={classes.labelRootError}>
                  {startDateErrorMsg}
                </FormHelperText>
              )}
            </GridItem>
          </Fragment>
        )}
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    eventTypeId,
    goodDealsTitles,
    typeErrorMsg,
    isGoodDealType,
    goodDealId,
    goodDealErrorMsg,
    urlState,
    url,
    urlErrorMsg,
    startDate,
    isDateMandatory,
    startDateErrorMsg
  } = state.eventReducer;

  const { eventTypes } = state.entitiesReducer;
  return {
    eventTypes: Object.values(eventTypes),
    eventType: eventTypes[eventTypeId],
    goodDeals: Object.values(goodDealsTitles),
    typeErrorMsg,
    isGoodDealType,
    goodDeal: goodDealsTitles[goodDealId],
    goodDealErrorMsg,
    urlState,
    url,
    urlErrorMsg,
    startDate,
    isDateMandatory,
    startDateErrorMsg
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetOwnGoodDealsTitles
    },
    dispatch
  );
  return { ...actions, dispatch };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Step1));
