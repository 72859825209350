import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert"; // Import

import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import Wizard from "components/DigiHapi/Wizard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import FileCopy from "@material-ui/icons/FileCopy";

import { GiPaperTray } from "react-icons/gi";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchGetTemplatesCategoriesNames,
  fetchAddTemplateCategory,
  fetchUpdateTemplateCategory,
  fetchDeleteTemplateCategory,
  fetchCloneTemplateCategory
} from "actions/template";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { beigeColor } from "assets/jss/material-dashboard-pro-react";

import Step1 from "./Step1";
import Step2 from "./Step2";

import {
  RESET_TEMPLATE_CATEGORY,
  SET_TEMPLATE_CATEGORY,
  SET_TEMPLATE_CATEGORY_NAME_FR,
  SET_TEMPLATE_CATEGORY_NAME_EN,
  SET_TEMPLATE_CATEGORY_SYSTEM,
  ROLE_WEBMASTER
} from "actions/types.js";

import Components from "./TemplatesCategoriesPage";

const style = {
  ...extendedTablesStyle,
  ...commonStyles,
  customUI: {
    backgroundColor: beigeColor[1],
    borderRadius: "10px",
    padding: 10
  }
};

class TemplateCategoryWizard extends React.Component {
  fetchAddTemplateCategory = () => {
    const {
      fetchAddTemplateCategory,
      name,
      lang,
      order,
      type,
      system,
      community
    } = this.props;

    fetchAddTemplateCategory({
      name,
      lang,
      order,
      system,
      type,
      communityId: community._id
    });
  };

  fetchUpdateTemplateCategory = () => {
    const { fetchUpdateTemplateCategory, _id, name, lang } = this.props;

    fetchUpdateTemplateCategory(_id, name, lang);
  };

  render() {
    const { update, isFetching, onClose, label } = this.props;
    return (
      <GridItem xs={12} sm={12}>
        <Wizard
          ref={this._wizard}
          onClose={onClose}
          isFetching={isFetching}
          validate
          steps={[
            {
              stepName: "Français",
              stepComponent: Step1,
              stepId: "french"
            },
            {
              stepName: "Anglais",
              stepComponent: Step2,
              stepId: "english"
            }
          ]}
          title={update ? `Mise à jour d'une ${label}` : `Ajout d'une ${label}`}
          subtitle=""
          finishButtonClick={
            update
              ? this.fetchUpdateTemplateCategory
              : this.fetchAddTemplateCategory
          }
        />
      </GridItem>
    );
  }
}

class TemplatesCategories extends React.Component {
  state = {
    br: false,
    color: "info",
    message: null,
    wizard: null
  };

  componentDidMount() {
    const { fetchGetTemplatesCategoriesNames, type, community } = this.props;
    fetchGetTemplatesCategoriesNames(type, community._id);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchGetTemplatesCategoriesNames,
      type,
      snackErrorMsg,
      snackInfoMsg,
      newWizard,
      community
    } = this.props;

    if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
      this.autoCloseMessage();
    }

    if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
      this.setState({
        wizard: null
      });
      fetchGetTemplatesCategoriesNames(type, community._id);
      this.autoCloseMessage();
    }

    if (newWizard && prevProps.newWizard !== newWizard) {
      this.addWizard(newWizard === "update");
    }
  }

  hideAlert = () => {
    this.setState({
      br: false
    });
  };

  autoCloseMessage = () => {
    const { snackErrorMsg, snackInfoMsg } = this.props;

    this.setState({
      br: true,
      message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
      color: snackInfoMsg ? "info" : "danger"
    });

    if (snackInfoMsg) {
      setTimeout(this.hideAlert, 5000);
    }
  };

  onClose = () => {
    const { fetchGetTemplatesCategoriesNames, type, community } = this.props;

    this.setState({
      wizard: null
    });
    this.props.dispatch({
      type: RESET_TEMPLATE_CATEGORY
    });
    fetchGetTemplatesCategoriesNames(type, community._id);
  };

  addWizard = (update = true, system = false) => {
    this.props.dispatch({
      type: SET_TEMPLATE_CATEGORY_SYSTEM,
      payload: system
    });
    this.setState({
      wizard: (
        <Components.TemplateCategoryWizard
          update={update}
          type={this.props.type}
          label={this.props.label}
          onClose={this.onClose}
        />
      )
    });
  };

  editCategory = (id, name, lang) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_TEMPLATE_CATEGORY,
      payload: id
    });
    dispatch({
      type: SET_TEMPLATE_CATEGORY_NAME_FR,
      payload: name
    });
    if (lang && lang.en) {
      dispatch({
        type: SET_TEMPLATE_CATEGORY_NAME_EN,
        payload: lang.en.name
      });
    }
  };

  deleteCategory = id => {
    this.props.fetchDeleteTemplateCategory(id);
  };

  cloneCategory = id => {
    const { fetchCloneTemplateCategory, community } = this.props;
    fetchCloneTemplateCategory(id, community._id);
  };

  getRoundButtons = (id, name, lang, system, used) => {
    const { classes, user } = this.props;

    const actions = [];
    if (system) {
      if (!used) {
        actions.push({
          color: "success",
          icon: FileCopy,
          tooltip: "Dupliquer",
          onClick: this.cloneCategory
        });
      }
      if (user.role === ROLE_WEBMASTER) {
        actions.push({
          color: "info",
          icon: Edit,
          tooltip: "Modifier",
          onClick: this.editCategory
        });
        actions.push({
          color: "danger",
          icon: Delete,
          tooltip: "Supprimer",
          onClick: this.delete
        });
      }
    } else {
      actions.push({
        color: "brown",
        icon: Edit,
        tooltip: "Modifier",
        onClick: this.editCategory
      });
      actions.push({
        color: "danger",
        icon: Delete,
        tooltip: "Supprimer",
        onClick: this.delete
      });
    }
    return actions.map((prop, key) => {
      return (
        <Tooltip
          key={key}
          title={prop.tooltip}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            round
            color={prop.color}
            className={classes.actionButton + " " + classes.actionButtonRound}
            disabled={this.state && this.state.wizard ? true : false}
            onClick={() => prop.onClick(id, name, lang)}
          >
            <prop.icon className={classes.icon} />
          </Button>
        </Tooltip>
      );
    });
  };

  getTemplatesCategories = () => {
    const { categories } = this.props;

    const categoriesObj = categories.map(
      ({ _id, name, lang, system, used }) => {
        return {
          name,
          actions: (
            <div className="actions-right">
              {this.getRoundButtons(_id, name, lang, system, used)}
            </div>
          )
        };
      }
    );
    return categoriesObj;
  };

  delete = id => {
    const { classes } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUI}>
            <h2>Confirmation</h2>
            <p>{"Êtes-vous sûr de vouloir supprimer la catégorie ?"}</p>
            <div className={classes.justifyContentSpaceAround}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Annuler
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.deleteCategory(id);
                  onClose();
                }}
              >
                Supprimer
              </Button>
            </div>
          </div>
        );
      }
    });
  };

  render() {
    const { color, message } = this.state;
    const { classes, isFetchingList, label, title, user } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader icon>
              <CardIcon color="primary">
                <GiPaperTray />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{title}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.getTemplatesCategories()}
                filterable
                columns={[
                  {
                    Header: "Nom",
                    accessor: "name",
                    sortable: true,
                    filterable: false
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                loading={isFetchingList}
                defaultPageSize={5}
                showPaginationTop
                showPaginationBottom={false}
                previousText="Précédent"
                nextText="Suivant"
                rowsText="Lignes"
                noDataText={"Aucunes " + label}
                ofText="sur"
                loadingText="Chargement..."
                className="-striped -highlight"
              />
            </CardBody>
            <CardFooter product>
              <div className={classes.w100}>
                {user.role === ROLE_WEBMASTER && (
                  <Button
                    color={"blue"}
                    className={classes.floatRight}
                    onClick={() => this.addWizard(false, true)}
                    disabled={this.state.wizard}
                  >
                    <Add />
                    {` Ajout d'une ${label} système`}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.floatRight}
                  onClick={() => this.addWizard(false)}
                  disabled={this.state.wizard}
                >
                  <Add />
                  {` Ajout d'une ${label}`}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {this.state.wizard}
        <Snackbar
          place="br"
          color={color}
          icon={AddAlert}
          message={message ? message : ""}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
      </GridContainer>
    );
  }
}

class TemplatesCategoriesPage extends TemplatesCategories {
  static defaultProps = {
    type: "Template",
    title: "Catégories de modèles",
    label: "catégorie de modèles"
  };
}

class ActivitiesCategoriesPage extends TemplatesCategories {
  static defaultProps = {
    type: "Activity",
    title: "Catégories d'instants partage",
    label: "catégorie d'instants partage"
  };
}

class ProposalsCategoriesPage extends TemplatesCategories {
  static defaultProps = {
    type: "Proposal",
    title: "Catégories de besoins",
    label: "catégorie de besoins"
  };
}

TemplatesCategoriesPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    isFetchingList,
    isFetching,
    _id,
    categoriesNames,
    name,
    lang,
    error,
    nameError,
    snackInfoMsg,
    snackErrorMsg,
    newWizard,
    system
  } = state.templateCategoryReducer;
  const { user } = state.profileReducer;
  const { community } = state.entitiesReducer;

  return {
    isFetchingList,
    isFetching,
    _id,
    categories: categoriesNames,
    name,
    lang,
    error,
    nameError,
    snackInfoMsg,
    snackErrorMsg,
    newWizard,
    system,
    user,
    community
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetTemplatesCategoriesNames,
      fetchAddTemplateCategory,
      fetchUpdateTemplateCategory,
      fetchDeleteTemplateCategory,
      fetchCloneTemplateCategory
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default {
  TemplatesCategoriesPage: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(TemplatesCategoriesPage)),
  ActivitiesCategoriesPage: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(ActivitiesCategoriesPage)),
  ProposalsCategoriesPage: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(ProposalsCategoriesPage)),
  TemplateCategoryWizard: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(TemplateCategoryWizard))
};
