import {
  defaultFont,
  whiteColor,
  grayColor,
  darkGrayColor
} from "assets/jss/material-dashboard-pro-react.js";

const headerStyle = {
  header: {
    top: "0",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "80px",
    backgroundColor: darkGrayColor,
    color: whiteColor,
    borderBottom: "1px solid " + grayColor[3],
    padding: "0 30px",
    ...defaultFont,
    zIndex: 4,
    "& img": {
      height: "60px"
    }
  }
};
export default headerStyle;
