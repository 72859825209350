import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import AddAlert from "@material-ui/icons/AddAlert";
import Image from "@material-ui/icons/Image";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody";
import ImageUpload from "components/CustomUpload/ImageUpload";
import Snackbar from "components/Snackbar/Snackbar";
import Activity from "components/DigiHapi/Activity";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUploadImages } from "actions";
import {
  SET_FILES_IMAGES,
  SET_SUBFOLDER_IMAGES,
  REMOVE_IMAGE
} from "../../actions/types";
import CardIcon from "components/Card/CardIcon";

const styles = {
  ...regularFormsStyle,
  ...commonStyles
};

class UploadImagePage extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      br: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { uploadImageResult } = this.props;

    if (uploadImageResult && !prevProps.uploadImageResult) {
      if (this.child.current) {
        //Remove picture
        this.child.current.handleRemove();
      }

      // Display success message
      this.setState({
        br: true
      });
      setTimeout(
        function() {
          this.setState({ br: false });
        }.bind(this),
        6000
      );
    }
  }

  onImageLoaded = (name, imagePreviewUrl) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_FILES_IMAGES,
      payload: [{ name, base64: imagePreviewUrl }]
    });
  };

  onImageRemoved = () => {
    this.props.dispatch({
      type: REMOVE_IMAGE
    });
  };

  fetchUploadImages = e => {
    e.preventDefault();
    const { fetchUploadImages, subFolderName, files } = this.props;
    fetchUploadImages(subFolderName, files);
  };

  render() {
    const { classes, dispatch, files, subFolderName, isFetching } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Image />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Téléverser des images</h4>
            </CardHeader>
            <CardBody>
              {isFetching ? (
                <Activity />
              ) : (
                <GridContainer justify="center">
                  <GridItem xs={12} sm={4} md={4}>
                    <legend>Image *</legend>
                    <ImageUpload
                      ref={this.child}
                      onImageLoaded={this.onImageLoaded}
                      onImageRemoved={this.onImageRemoved}
                      addButtonProps={{
                        color: "brown",
                        round: true
                      }}
                      changeButtonProps={{
                        color: "brown",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Sous dossier"
                      id="subfolder"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: subFolderName ? subFolderName : "",
                        autoComplete: "off",
                        onChange: e =>
                          dispatch({
                            type: SET_SUBFOLDER_IMAGES,
                            payload: e.target.value
                          })
                      }}
                    />
                  </GridItem>
                  <Snackbar
                    place="br"
                    color="info"
                    icon={AddAlert}
                    message="Votre image a été correctement téléversée."
                    open={this.state.br}
                    closeNotification={() => this.setState({ br: false })}
                    close
                  />
                </GridContainer>
              )}
            </CardBody>
            <CardFooter product>
              <div className={classes.w100}>
                <Button
                  className={classes.floatRight}
                  color="primary"
                  disabled={files.length === 0}
                  onClick={this.fetchUploadImages}
                >
                  Valider
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

UploadImagePage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    isFetching,
    subFolderName,
    uploadImageResult,
    files
  } = state.uploadReducer;

  return {
    isFetching,
    subFolderName,
    uploadImageResult,
    files
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchUploadImages
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UploadImagePage));
