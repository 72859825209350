export const Images = {
  // default avatar
  // avatar: require("./images/default-avatar.png"),

  // logo: require("./images/Logo_O_Camping.png"),

  // Maintenance
  // maintenance: require("./images/maintenance.png"),

  // Meteo
  // "01d": require("./images/01d.png"),
  // "01n": require("./images/01n.png"),
  // "02d": require("./images/02d.png"),
  // "02n": require("./images/02n.png"),
  // "03d": require("./images/03d.png"),
  // "03n": require("./images/03n.png"),
  // "04d": require("./images/04d.png"),
  // "04n": require("./images/04n.png"),
  // "09d": require("./images/09d.png"),
  // "09n": require("./images/09n.png"),
  // "10d": require("./images/10d.png"),
  // "10n": require("./images/10n.png"),
  // "11d": require("./images/11d.png"),
  // "11n": require("./images/11n.png"),
  // "13d": require("./images/13d.png"),
  // "13n": require("./images/13n.png"),
  // "50d": require("./images/50d.png"),
  // "50n": require("./images/50n.png"),

  home: require("./img/bg-home.jpg"),

  maintenance: require("./img/cookie-the-pom-gySMaocSdqs-unsplash.jpg"),

  ocamping: require("./img/bg-tent.jpg"),
  hapicolibri: require("./img/hapicolibri.jpg"),

  logo: require("./img/logo.png"),
  logodigihapi: require("./img/logoDigihapi.png"),
  logorounded: require("./img/logo-rounded.png"),
  logobicolor: require("./img/logo-bicolor.png"),
  icon: require("./img/icon.png"),

  dairy: require("./img/activities/dairy.png"),
  city: require("./img/activities/cityscape.png"),
  academic: require("./img/activities/academic.png"),
  settings: require("./img/activities/settings.png"),
  technology: require("./img/activities/technology.png"),
  foodprocessor: require("./img/activities/foodprocessor.png"),
  garment: require("./img/activities/garment.png"),
  elegant: require("./img/activities/elegant.png"),
  monitor: require("./img/activities/monitor.png"),
  food: require("./img/activities/food.png"),
  trains: require("./img/activities/trains.png"),
  dishes: require("./img/activities/dishes.png"),
  fruit: require("./img/activities/fruit.png"),
  education: require("./img/activities/education.png"),
  buildings: require("./img/activities/buildings.png"),

  manager: require("./img/home/water-165219_960_720.jpg"),
  pro: require("./img/home/girl-4181395_960_720.jpg"),
  camper: require("./img/home/people-821624_960_720.jpg"),
  app: require("./img/home/app.png"),
  appstore: require("./img/home/download-on-the-app-store-badge.png"),
  googleplay: require("./img/home/google-play-badge.png"),
  underconstruction: require("./img/home/underconstruction.png"),
  // Activities
  cheers: require("./img/activities/cheers.png"),
  restaurant: require("./img/activities/restaurant.png"),
  bbq: require("./img/activities/bbq.png"),
  disco: require("./img/activities/disco.png"),
  picnic: require("./img/activities/picnic-basket.png"),
  icecream: require("./img/activities/ice-cream.png"),
  coffee: require("./img/activities/coffee.png"),

  tennis: require("./img/activities/tennis.png"),
  badminton: require("./img/activities/badminton.png"),
  tabletennis: require("./img/activities/table-tennis.png"),
  petanque: require("./img/activities/petanque.png"),
  golf: require("./img/activities/golf.png"),
  lotus: require("./img/activities/lotus-flower.png"),
  running: require("./img/activities/running.png"),
  hiking: require("./img/activities/hiking.png"),
  climbingrope: require("./img/activities/climbing-with-rope.png"),
  foot: require("./img/activities/soccer-ball-variant.png"),
  basketball: require("./img/activities/basket-ball.png"),
  volley: require("./img/activities/volley-ball.png"),
  roller: require("./img/activities/roller-skate.png"),
  bike: require("./img/activities/bike.png"),
  swim: require("./img/activities/swim.png"),

  boardinggame: require("./img/activities/boarding-game.png"),
  cards: require("./img/activities/cards.png"),
  chess: require("./img/activities/chess.png"),
  gamepad: require("./img/activities/gamepad.png"),
  billiard: require("./img/activities/billiard.png"),
  babyfoot: require("./img/activities/baby-foot.png"),

  beach: require("./img/activities/beach.png"),
  field: require("./img/activities/field.png"),
  basket: require("./img/activities/basket.png"),
  cinema: require("./img/activities/cinema.png"),
  canoe: require("./img/activities/man-in-canoe.png"),
  waterski: require("./img/activities/water-ski.png"),
  paddle: require("./img/activities/paddle.png"),
  pedalo: require("./img/activities/pedalo.png"),
  bowling: require("./img/activities/bowling.png"),
  parachute: require("./img/activities/parachute.png"),
  climbing: require("./img/activities/climbing.png"),
  casino: require("./img/activities/casino.png"),
  museum: require("./img/activities/museum.png"),

  playground: require("./img/activities/playground.png"),
  inflatable: require("./img/activities/inflatable-castle.png"),
  trampoline: require("./img/activities/trampoline.png"),
  swimmingpool: require("./img/activities/swimming-pool.png"),
  kids: require("./img/activities/kids.png"),
  children: require("./img/activities/children.png"),

  // gooddeals
  carousel: require("./img/activities/carousel.png"),
  cart: require("./img/activities/cart.png"),
  bag: require("./img/activities/shopping-bag.png"),
  cap: require("./img/activities/cap.png"),
  camera: require("./img/activities/camera.png"),
  discovery: require("./img/activities/discovery.png"),
  car: require("./img/activities/car.png"),
  jacuzzi: require("./img/activities/jacuzzi.png")

  // Home view
  // live: require("./img/Live.png"),
  // chat: require("./img/Discussion.png"),
  // gooddeals: require("./img/Bon_plan.png"),
  // meteo: require("./img/Meteo.png"),
  // infos: require("./img/Carte.png"),
  // profile: require("./img/Profil.png"),
  // settings: require("./img/Setting.png")

  // gooddeals
  // carousel: require("./img/gooddeals/carousel.png"),
  // cartwhite: require("./img/gooddeals/cart.png"),
  // cap: require("./img/gooddeals/cap.png"),
  // museumwhite: require("./img/gooddeals/museum.png"),
  // camera: require("./img/gooddeals/camera.png"),
  // discovery: require("./img/gooddeals/discovery.png"),
  // car: require("./img/gooddeals/car.png"),
  // jacuzziwhite: require("./img/gooddeals/jacuzzi.png"),
  // restaurantwhite: require("./img/gooddeals/restaurant.png"),
  // cheerswhite: require("./img/gooddeals/cheers.png"),
  // icecreamwhite: require("./img/gooddeals/ice-cream.png"),
  // casinowhite: require("./img/gooddeals/casino.png")
};

export const getImage = name => {
  return Images[name];
};

export const getImageUri = name => {
  return { uri: Images[name] };
};
