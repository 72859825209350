import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  whiteColor,
  grayColor,
  darkGrayColor
} from "assets/jss/material-dashboard-pro-react.js";

const footerStyle = {
  block: {},
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "15px"
  },
  footerTop: {
    height: 30,
    "& img": {
      position: "relative",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      top: "-100px",
      width: "150px"
    }
  },
  footer: {
    bottom: "0",
    position: "absolute",
    width: "100%",
    color: whiteColor,
    backgroundColor: primaryColor[0],
    borderTop: "1px solid " + grayColor[3],
    padding: "15px 0",
    ...defaultFont,
    zIndex: 4,
    "& hr": {
      backgroundColor: darkGrayColor
    },
    "& a": {
      color: primaryColor[0],
      textDecoration: "none",
      backgroundColor: "transparent"
    },
    "& a:hover": {
      color: whiteColor
    }
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative"
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor
    }
  },
  copyright: {
    textAlign: "center",
    color: whiteColor
  },
  center: {
    textAlign: "center"
  },
  bdDarkGrayColor: {
    backgroundColor: darkGrayColor
  }
};
export default footerStyle;
