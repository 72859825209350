import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Snackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import Activity from "components/DigiHapi/Activity";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

import { VscDebugStepInto } from "react-icons/vsc";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchGetEquipments } from "actions/equipment";
import {
  fetchGetCommunityDetails,
  fetchReorderCommunityEquipments
} from "actions/community";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import { COMMUNITY_EQUIPMENTS_ON_DRAG_END } from "actions/types";
import { getUnusedEquipments } from "selectors/community";

const style = {
  ...extendedTablesStyle,
  ...commonStyles
};

class DragTrComponent extends React.Component {
  render() {
    const { children = null, rowInfo, classes } = this.props;
    if (rowInfo && classes) {
      const { original, index } = rowInfo;
      const { _id } = original;
      return (
        <Draggable key={_id} index={index} draggableId={_id}>
          {(draggableProvided, draggableSnapshot) => (
            <div
              className={classes.w100}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
            >
              <ReactTable.defaultProps.TrComponent className={classes.w100}>
                {children}
              </ReactTable.defaultProps.TrComponent>
              {draggableProvided.placeholder}
            </div>
          )}
        </Draggable>
      );
    } else
      return (
        <ReactTable.defaultProps.TrComponent>
          {children}
        </ReactTable.defaultProps.TrComponent>
      );
  }
}

class DropTbodyComponent extends React.Component {
  render() {
    const { children = null, classes, droppableId } = this.props;

    return (
      <Droppable droppableId={droppableId}>
        {(droppableProvided, droppableSnapshot) => (
          <div
            className={classes.w100}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            <ReactTable.defaultProps.TbodyComponent>
              {children}
            </ReactTable.defaultProps.TbodyComponent>
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

class DropTbodyComponentUsed extends DropTbodyComponent {
  static defaultProps = {
    droppableId: "used"
  };
}

class DropTbodyComponentUnused extends DropTbodyComponent {
  static defaultProps = {
    droppableId: "unused"
  };
}

class CommunityPlacesPage extends React.Component {
  state = {
    br: false,
    color: "info",
    message: null
  };

  componentDidMount() {
    const { fetchGetEquipments, community } = this.props;
    fetchGetEquipments(community._id);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchGetCommunityDetails,
      snackErrorMsg,
      snackInfoMsg,
      community
    } = this.props;

    if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
      this.autoCloseMessage();
    }

    if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
      fetchGetCommunityDetails(community._id);
      this.autoCloseMessage();
    }
  }

  hideAlert = () => {
    this.setState({
      br: false
    });
  };

  autoCloseMessage = () => {
    const { snackErrorMsg, snackInfoMsg } = this.props;

    this.setState({
      br: true,
      message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
      color: snackInfoMsg ? "info" : "danger"
    });

    if (snackInfoMsg) {
      setTimeout(this.hideAlert, 5000);
    }
  };

  getCommunityEquipments = () => {
    const { communityEquipments } = this.props;

    const equipmentsObj = communityEquipments.map(({ _id, name }) => {
      return {
        _id,
        name
      };
    });
    return equipmentsObj;
  };

  getUnusedEquipments = () => {
    const { unusedEquipments } = this.props;

    const equipmentsObj = unusedEquipments.map(({ _id, name }) => {
      return {
        _id,
        name
      };
    });
    return equipmentsObj;
  };

  handleDragEnd = result => {
    const { dispatch, equipments } = this.props;
    dispatch({
      type: COMMUNITY_EQUIPMENTS_ON_DRAG_END,
      result,
      name: equipments[result.draggableId].name
    });
  };

  getTrProps = (state, rowInfo) => {
    const { classes } = this.props;
    return { rowInfo, classes };
  };

  getTbodyProps = (state, rowInfo, column, rtInstance) => {
    const { classes } = this.props;
    return { rowInfo, column, rtInstance, classes };
  };

  render() {
    const { color, message } = this.state;
    const {
      classes,
      isFetching,
      community,
      equipmentsIds,
      areEquipmentsReordered,
      fetchReorderCommunityEquipments,
      fetchGetCurrentCommunityDetails,
      totalEquipments
    } = this.props;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card noaos>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <VscDebugStepInto />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {"Lieux de rendez-vous"}
              </h4>
            </CardHeader>
            <CardBody>
              <DragDropContext onDragEnd={this.handleDragEnd}>
                {totalEquipments && (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <ReactTable
                        TrComponent={DragTrComponent}
                        TbodyComponent={DropTbodyComponentUsed}
                        getTrProps={this.getTrProps}
                        getTbodyProps={this.getTbodyProps}
                        data={this.getCommunityEquipments()}
                        columns={[
                          {
                            Header: "Lieux de rendez-vous",
                            accessor: "name",
                            sortable: false,
                            filterable: false
                          },
                          {
                            Header: "",
                            accessor: "empty",
                            sortable: false,
                            filterable: false
                          }
                        ]}
                        // loading={isFetchingList}
                        defaultPageSize={totalEquipments}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <ReactTable
                        TrComponent={DragTrComponent}
                        TbodyComponent={DropTbodyComponentUnused}
                        getTrProps={this.getTrProps}
                        getTbodyProps={this.getTbodyProps}
                        data={this.getUnusedEquipments()}
                        columns={[
                          {
                            Header: "Piochez ou retirez des éléments ici",
                            accessor: "name",
                            sortable: false,
                            filterable: false
                          },
                          {
                            Header: "",
                            accessor: "empty",
                            sortable: false,
                            filterable: false
                          }
                        ]}
                        // loading={isFetchingList}
                        defaultPageSize={totalEquipments}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </DragDropContext>
            </CardBody>
            <CardFooter product>
              <div className={classes.w100}>
                <Button
                  color="primary"
                  disabled={!areEquipmentsReordered || isFetching}
                  onClick={() =>
                    fetchReorderCommunityEquipments(
                      community._id,
                      equipmentsIds
                    )
                  }
                  className={classes.floatRight}
                >
                  {isFetching ? <Activity /> : "Réorganiser"}
                </Button>
                <Button
                  color="brown"
                  disabled={!areEquipmentsReordered || isFetching}
                  onClick={fetchGetCurrentCommunityDetails}
                  className={classes.floatRight}
                >
                  {"Restaurer"}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <Snackbar
          place="br"
          color={color}
          icon={AddAlert}
          message={message ? message : ""}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
      </GridContainer>
    );
  }
}

CommunityPlacesPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    isFetching,
    snackInfoMsg,
    snackErrorMsg,
    equipments: communityEquipments,
    areEquipmentsReordered
  } = state.communityReducer;

  const { equipments } = state.equipmentReducer;

  const { community } = state.entitiesReducer;

  return {
    isFetching,
    totalEquipments: Object.values(equipments).length,
    equipments,
    communityEquipments:
      communityEquipments && Object.values(communityEquipments),
    equipmentsIds: communityEquipments && Object.keys(communityEquipments),
    unusedEquipments: Object.values(getUnusedEquipments(state)),
    snackInfoMsg,
    snackErrorMsg,
    areEquipmentsReordered,
    community
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchGetEquipments,
      fetchGetCommunityDetails,
      fetchReorderCommunityEquipments
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CommunityPlacesPage));
