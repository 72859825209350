import LinesEllipsis from "react-lines-ellipsis";

import ReactTable from "react-table";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import AddAlert from "@material-ui/icons/AddAlert";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Loyalty from "@material-ui/icons/Loyalty";
// import Refresh from "@material-ui/icons/Refresh";
// import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Update from "@material-ui/icons/Update";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Send from "@material-ui/icons/Send";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Error from "@material-ui/icons/Error";
import ReportProblem from "@material-ui/icons/ReportProblem";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Snackbar from "components/Snackbar/Snackbar";
import Activity from "components/DigiHapi/Activity";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import typographyStyle from "assets/jss/material-dashboard-pro-react/components/typographyStyle";
import Wizard from "components/DigiHapi/Wizard";

import commonStyle, {
  cla,
  flex1,
  column,
  flex
} from "assets/jss/ocamping/commonStyle";

import {
  fetchPayGoodDeal,
  fetchAddGoodDeal,
  fetchDeleteGoodDeal,
  fetchGetGoodDealDetails,
  fetchGetGoodDealCampsites,
  fetchGetOwnGoodDeal,
  fetchUpdateGoodDeal,
  fetchPublishGoodDeal,
  fetchModeratingAccept,
  fetchModeratingRefuse
} from "actions/gooddeal";
import {
  RESET_GOODDEAL,
  SET_GOODDEAL_SORTED,
  EDITING,
  PUBLISHED,
  MODERATING,
  REFUSED,
  ROLE_WEBMASTER
} from "actions/types";
// core components
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Components from "./GoodDealsPage";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { beigeColor } from "assets/jss/material-dashboard-pro-react";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css"; // import css
// import ComponentsStep7 from "./Step7";

const style = {
  ...dashboardStyle,
  ...extendedTablesStyle,
  ...typographyStyle,
  ...commonStyle,
  customUI: {
    backgroundColor: beigeColor[1],
    borderRadius: "10px",
    padding: 10
  },
  floatRight: {
    float: "right"
  },
  justifyContentSpaceAround: {
    display: "flex",
    justifyContent: "space-around"
  },
  descriptionContainer: {
    height: "60px"
  },
  preWrap: {
    whiteSpace: "pre-wrap"
  },
  w100: {
    width: "100%"
  }
};
class GoodDealsWizard extends React.Component {
  fetchAddGoodDeal = () => {
    const {
      fetchAddGoodDeal,
      files,
      categoryId,
      description,
      phone,
      email,
      openingTime,
      price,
      address,
      zipCode,
      city,
      latitude,
      longitude,
      webSiteUrl,
      fbUrl,
      title,
      promotion,
      lang,
      templateId,
      choices,
      activityDescription,
      activityPostDescription
    } = this.props;

    fetchAddGoodDeal({
      files,
      categoryId,
      description,
      phone,
      email,
      openingTime,
      price,
      address,
      zipCode,
      city,
      latitude,
      longitude,
      webSiteUrl,
      fbUrl,
      title,
      promotion,
      lang,
      templateId: templateId ? templateId : null,
      choices,
      activityDescription,
      activityPostDescription
    });
  };

  fetchUpdateGoodDeal = () => {
    const {
      // fetchPayGoodDeal,
      // token,
      fetchUpdateGoodDeal,
      _id,
      files,
      categoryId,
      description,
      phone,
      email,
      openingTime,
      price,
      address,
      zipCode,
      city,
      latitude,
      longitude,
      webSiteUrl,
      fbUrl,
      title,
      promotion,
      lang,
      photoUri,
      templateId,
      choices,
      activityDescription,
      activityPostDescription
    } = this.props;

    // fetchPayGoodDeal({ token });
    fetchUpdateGoodDeal({
      _id,
      files,
      categoryId,
      description,
      phone,
      email,
      openingTime,
      price,
      address,
      zipCode,
      city,
      latitude,
      longitude,
      webSiteUrl,
      fbUrl,
      title,
      promotion,
      lang,
      photoUri,
      templateId: templateId ? templateId : null,
      choices,
      activityDescription,
      activityPostDescription
    });
  };

  render() {
    const { update, isFetching, onClose } = this.props;
    return (
      <GridItem xs={12} sm={12}>
        <Wizard
          onClose={onClose}
          isFetching={isFetching}
          validate
          steps={[
            {
              stepName: "Illustration",
              stepComponent: Step1,
              stepId: "picture"
            },
            {
              stepName: "Français",
              stepComponent: Step2,
              stepId: "french"
            },
            {
              stepName: "Anglais",
              stepComponent: Step3,
              stepId: "english"
            },
            {
              stepName: "Informations",
              stepComponent: Step4,
              stepId: "infos"
            },
            {
              stepName: "Localisation",
              stepComponent: Step5,
              stepId: "gooddeal"
            }
            // {
            //   stepName: "Panier",
            //   stepComponent: ComponentsStep7.Step7,
            //   stepId: "payment"
            // }
          ]}
          title={update ? "Mise à jour d'un bon plan" : "Ajout d'un bon plan"}
          subtitle=""
          finishButtonClick={
            update ? this.fetchUpdateGoodDeal : this.fetchAddGoodDeal
          }
          finishButtonText={update ? "Modifier" : "Ajouter"}
        />
      </GridItem>
    );
  }
}

class PublishWizard extends React.Component {
  publishGoodDeal = () => {
    const { fetchPublishGoodDeal, _id: goodDealId, channelsIds } = this.props;
    fetchPublishGoodDeal(goodDealId, channelsIds);
  };

  render() {
    const { isFetching, onClose } = this.props;
    return (
      <GridItem xs={12} sm={12}>
        <Wizard
          onClose={onClose}
          isFetching={isFetching}
          validate
          steps={[
            {
              stepName: "Canaux de diffusions",
              stepComponent: Step6,
              stepId: "channels"
            }
          ]}
          title={"Mise en ligne d'un bon plan"}
          subtitle=""
          finishButtonClick={this.publishGoodDeal}
          finishButtonText={"Publier"}
        />
      </GridItem>
    );
  }
}

class GoodDealsPage extends React.Component {
  state = {
    br: false,
    color: "info",
    message: null,
    wizard: null,
    modeTable: false
  };

  constructor(props) {
    super(props);
    this.wizardRef = React.createRef();
    this.wizardPublishRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchGetOwnGoodDeal();
  }

  componentDidUpdate(prevProps, prevState) {
    const { snackErrorMsg, snackInfoMsg, newWizard } = this.props;

    if (snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) {
      this.autoCloseMessage();
    }

    if (snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg) {
      this.setState({
        wizard: null
      });
      this.props.fetchGetOwnGoodDeal();
      this.autoCloseMessage();
    }

    if (newWizard && prevProps.newWizard !== newWizard) {
      if (newWizard === "publish") {
        this.addPublishWizard();
      } else {
        this.addWizard(newWizard === "update");
      }
    }
  }

  hideAlert = () => {
    this.setState({
      br: false
    });
  };

  autoCloseMessage = () => {
    const { snackErrorMsg, snackInfoMsg } = this.props;

    this.setState({
      br: true,
      message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
      color: snackInfoMsg ? "info" : "danger"
    });

    if (snackInfoMsg) {
      setTimeout(this.hideAlert, 5000);
    }
  };

  onClose = () => {
    this.setState({
      wizard: null
    });
    this.props.dispatch({
      type: RESET_GOODDEAL
    });
    // this.props.fetchGetOwnGoodDeal();
  };

  addPublishWizard = () => {
    const { classes } = this.props;
    this.setState(
      {
        wizard: (
          <div
            className={classes.w100}
            ref={elt => {
              this.wizardPublishRef = elt;
            }}
          >
            <Components.PublishWizard onClose={this.onClose} />
          </div>
        )
      },
      () => this.wizardPublishRef.scrollIntoView({ behavior: "smooth" })
    );
  };

  addWizard = (update = true) => {
    const { classes } = this.props;
    this.setState(
      {
        wizard: (
          // wrap the React component in a standard DOM element and assign a ref to that
          // to scroll correctly
          <div
            className={classes.w100}
            ref={elt => {
              this.wizardRef = elt;
            }}
          >
            <Components.GoodDealsWizard
              update={update}
              onClose={this.onClose}
            />
          </div>
        )
      },
      () => this.wizardRef.scrollIntoView({ behavior: "smooth" })
    );
  };

  editGoodDeal = id => {
    this.props.fetchGetGoodDealDetails(id);
  };

  editGoodDealCampsites = id => {
    this.props.fetchGetGoodDealCampsites(id);
  };

  deleteGoodDeal = id => {
    this.props.fetchDeleteGoodDeal(id);
  };

  getRoundButtons = (id, status) => {
    const { classes, user } = this.props;
    const actions = [];

    if (status === EDITING || status === PUBLISHED) {
      actions.push({
        color: "success",
        icon: Send,
        onClick: this.editGoodDealCampsites,
        tooltip: "Publier"
      });
    }
    if (status === MODERATING && user.role === ROLE_WEBMASTER) {
      actions.push({
        color: "danger",
        icon: Error,
        onClick: this.refuse,
        tooltip: "Rejeter"
      });
    }
    if (status === MODERATING && user.role === ROLE_WEBMASTER) {
      actions.push({
        color: "success",
        icon: CheckCircle,
        onClick: this.accept,
        tooltip: "Accepter"
      });
    }
    if (status !== MODERATING || user.role === ROLE_WEBMASTER) {
      actions.push({
        color: "brown",
        icon: Edit,
        onClick: this.editGoodDeal,
        tooltip: "Modifier"
      });
    }
    actions.push({
      color: "danger",
      icon: Delete,
      onClick: this.delete,
      tooltip: "Supprimer"
    });

    return actions.map((prop, key) => {
      return (
        <Tooltip
          key={key}
          title={prop.tooltip}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            round
            color={prop.color}
            className={classes.actionButton + " " + classes.actionButtonRound}
            disabled={this.state && this.state.wizard ? true : false}
            onClick={() => prop.onClick(id)}
          >
            <prop.icon className={classes.icon} />
          </Button>
        </Tooltip>
      );
    });
  };

  getCampsitesNames = communitiesIds => {
    let campsiteNames = "";
    for (let index = 0; index < communitiesIds.length; index++) {
      const communityId = communitiesIds[index];
      if (index) {
        campsiteNames += ", " + this.props.campsites[communityId].name;
      } else {
        campsiteNames += this.props.campsites[communityId].name;
      }
    }

    return campsiteNames;
  };

  get2Chars = param => {
    return ("" + param).length === 1 ? "0" + param : param;
  };

  formatDate = (d, withTime = true) => {
    const date = new Date(d);
    return `${this.get2Chars(date.getDate())}/${this.get2Chars(
      date.getMonth() + 1
    )}/${date.getFullYear()} ${
      withTime
        ? this.get2Chars(date.getHours()) +
          ":" +
          this.get2Chars(date.getMinutes())
        : ""
    }`;
  };

  getStrState = status => {
    switch (status) {
      case MODERATING:
        return "En cours de modération";

      case PUBLISHED:
        return "Publiée";

      case REFUSED:
        return "Rejeté";

      default:
        return "Brouillon";
    }
  };

  getOwnGoodDealsData = () => {
    const { goodDeals, goodDealsCategories } = this.props;

    const result = goodDeals.map(
      ({ _id, dateUpdated, title, categoryId, validationDate, status }) => {
        return {
          dateUpdated: this.formatDate(dateUpdated),
          title,
          status: this.getStrState(status),
          category: goodDealsCategories[categoryId].name,
          // campsites: this.getCampsitesNames(communitiesIds),
          validationDate: this.formatDate(validationDate, false),
          actions: (
            <div className="actions-right">
              {this.getRoundButtons(_id, status)}
            </div>
          )
        };
      }
    );
    return result;
  };

  accept = id => {
    this.props.fetchModeratingAccept(id);
  };

  refuse = id => {
    this.props.fetchModeratingRefuse(id);
  };

  renderGoodDeals = () => {
    const { classes, goodDeals, goodDealsCategories, user } = this.props;
    const components = [];

    // eslint-disable-next-line
    for (const goodDeal of goodDeals) {
      components.push(
        <GridItem
          key={goodDeal._id}
          xs={12}
          sm={12}
          md={4}
          className={cla(classes, [flex, flex1])}
        >
          <Card product className={cla(classes, ["cardHover", flex1])}>
            <CardHeader image className={classes.cardHeaderHover}>
              {goodDeal.status === EDITING ? (
                <a
                  href={`https://app.ocamping.fr/gooddeal?id=${goodDeal._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={goodDeal.photoUri} alt={goodDeal.title} />
                </a>
              ) : (
                <img src={goodDeal.photoUri} alt={goodDeal.title} />
              )}
            </CardHeader>
            <CardBody className={cla(classes, [flex, flex1, column])}>
              <div className={classes.cardHoverUnder}>
                {(goodDeal.status === EDITING ||
                  goodDeal.status === PUBLISHED) && (
                  <Tooltip
                    title="Publier"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="success"
                      simple
                      justIcon
                      onClick={() => this.editGoodDealCampsites(goodDeal._id)}
                    >
                      <Send className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                )}
                {goodDeal.status === MODERATING &&
                  user.role === ROLE_WEBMASTER && (
                    <Tooltip
                      title="Rejeter"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="danger"
                        simple
                        justIcon
                        onClick={() => this.refuse(goodDeal._id)}
                      >
                        <Error className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  )}
                {goodDeal.status === MODERATING &&
                  user.role === ROLE_WEBMASTER && (
                    <Tooltip
                      title="Accepter"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="success"
                        simple
                        justIcon
                        onClick={() => this.accept(goodDeal._id)}
                      >
                        <CheckCircle className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  )}
                {(goodDeal.status !== MODERATING ||
                  user.role === ROLE_WEBMASTER) && (
                  <Tooltip
                    title="Modifier"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="brown"
                      simple
                      justIcon
                      onClick={() => this.editGoodDeal(goodDeal._id)}
                    >
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip
                  title="Supprimer"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="danger"
                    simple
                    justIcon
                    onClick={() => this.delete(goodDeal._id)}
                  >
                    <Delete className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h3 className={classes.cardProductTitle}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  {goodDeal.title}
                </a>
              </h3>
              <div className={classes.descriptionContainer}>
                <LinesEllipsis
                  className={
                    classes.cardProductDesciprion + " " + classes.preWrap
                  }
                  text={goodDeal.description}
                  maxLine={3}
                  trimRight
                  basedOn="letters"
                />
              </div>
            </CardBody>
            <CardFooter product>
              <div className={classes.price}>
                <h4>{goodDealsCategories[goodDeal.categoryId].name}</h4>
              </div>
              {goodDeal.status === EDITING && (
                <div
                  className={`${classes.stats} ${classes.productStats} ${classes.infoText} ${classes.floatRight}`}
                >
                  <Edit /> {" Brouillon"}
                </div>
              )}
              {goodDeal.status === MODERATING && (
                <div
                  className={`${classes.stats} ${classes.productStats} ${classes.warningText} ${classes.floatRight}`}
                >
                  <RemoveRedEye /> {" En cours de modération"}
                </div>
              )}
              {goodDeal.status === REFUSED && (
                <div
                  className={`${classes.stats} ${classes.productStats} ${classes.dangerText} ${classes.floatRight}`}
                >
                  <ReportProblem /> {" Rejetée"}
                </div>
              )}
              {goodDeal.status === PUBLISHED && (
                <Tooltip
                  id="tooltip-expiration"
                  title="Date d'expiration"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div
                    className={`${classes.stats} ${classes.productStats} ${
                      new Date(goodDeal.validationDate).getTime() > Date.now()
                        ? classes.successText
                        : classes.dangerText
                    }`}
                  >
                    <Update /> {this.formatDate(goodDeal.validationDate)}
                  </div>
                </Tooltip>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      );
    }
    return components;
  };

  delete = goodDealId => {
    const { classes } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.customUI}>
            <h2>Confirmation</h2>
            <p>Êtes-vous sûr de vouloir supprimer le bon plan ?</p>
            <div className={classes.justifyContentSpaceAround}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Annuler
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.deleteGoodDeal(goodDealId);
                  onClose();
                }}
              >
                Supprimer
              </Button>
            </div>
          </div>
        );
      }
    });
  };

  changeCurrentPage = page => {
    const { fetchGetOwnGoodDeal, limit, sorted } = this.props;
    fetchGetOwnGoodDeal(page, limit, sorted);
  };

  render() {
    const { color, message, wizard, modeTable } = this.state;
    const {
      classes,
      dispatch,
      page,
      totalPages,
      totalDocs,
      limit,
      sorted,
      areFetching,
      isFetchingList,
      fetchGetOwnGoodDeal
    } = this.props;

    return areFetching ? (
      <Activity />
    ) : (
      <GridContainer>
        {!modeTable && (
          <Fragment>
            {this.renderGoodDeals()}
            {!wizard && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <div className={classes.floatRight}>
                    <Pagination
                      currentPage={page}
                      totalSize={totalDocs}
                      sizePerPage={limit}
                      totalPages={totalPages}
                      theme="bootstrap"
                      changeCurrentPage={this.changeCurrentPage}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Button
                    color={"primary"}
                    className={classes.floatRight}
                    onClick={() => this.addWizard(false)}
                  >
                    <Add /> {"Ajout d'un bon plan"}
                  </Button>
                  <Button
                    color={"rose"}
                    className={classes.floatRight}
                    onClick={() => this.setState({ modeTable: !modeTable })}
                  >
                    <ArtTrack />
                    {" Tableau"}
                  </Button>
                </GridItem>
              </GridContainer>
            )}
          </Fragment>
        )}
        {modeTable && (
          <GridItem xs={12}>
            <Card>
              <CardHeader color="blue" icon>
                <CardIcon color="blue">
                  <Loyalty />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Bons Plans</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.getOwnGoodDealsData()}
                  filterable
                  columns={[
                    {
                      Header: "Date",
                      accessor: "dateUpdated",
                      sortable: true,
                      filterable: false
                    },
                    {
                      Header: "Titre",
                      accessor: "title",
                      sortable: true,
                      filterable: false
                    },
                    {
                      Header: "Catégorie",
                      accessor: "category",
                      sortable: false,
                      filterable: false
                    },
                    // {
                    //   Header: "Campings",
                    //   accessor: "campsites",
                    //   sortable: false,
                    //   filterable: false
                    // },
                    {
                      Header: "Expiration",
                      accessor: "validationDate",
                      sortable: true,
                      filterable: false
                    },
                    {
                      Header: "Statut",
                      accessor: "status",
                      sortable: true,
                      filterable: false
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  manual
                  onFetchData={state => {
                    dispatch({
                      type: SET_GOODDEAL_SORTED,
                      value: state.sorted
                    });
                    fetchGetOwnGoodDeal(
                      state.page + 1,
                      state.pageSize,
                      state.sorted
                    );
                  }}
                  loading={isFetchingList}
                  defaultPageSize={limit}
                  defaultSorted={sorted}
                  page={page - 1}
                  pageSizeOptions={[3, 6, 9, 18, 36, 72]}
                  pages={totalPages}
                  showPaginationTop
                  showPaginationBottom={false}
                  previousText="Précédent"
                  nextText="Suivant"
                  rowsText="Lignes"
                  noDataText="Aucuns bons plans"
                  ofText="sur"
                  loadingText="Chargement..."
                  className="-striped -highlight"
                />
                {!wizard && (
                  <Fragment>
                    <Button
                      color={"primary"}
                      className={classes.floatRight}
                      onClick={() => this.addWizard(false)}
                    >
                      <Add /> {"Ajout d'un bon plan"}
                    </Button>
                    <Button
                      color={"rose"}
                      className={classes.floatRight}
                      onClick={() => this.setState({ modeTable: !modeTable })}
                    >
                      <ArtTrack />
                      {" Fiches"}
                    </Button>
                  </Fragment>
                )}
              </CardBody>
            </Card>
          </GridItem>
        )}
        {this.state.wizard}
        <Snackbar
          place="br"
          color={color}
          icon={AddAlert}
          message={message ? message : ""}
          open={this.state.br}
          closeNotification={() => this.setState({ br: false })}
          close
        />
      </GridContainer>
    );
  }
}

GoodDealsPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    _id,
    isFetching,
    isFetchingList,
    files,
    citiesIds,
    categoryId,
    description,
    phone,
    email,
    openingTime,
    price,
    latitude,
    address,
    zipCode,
    city,
    longitude,
    webSiteUrl,
    fbUrl,
    title,
    promotion,
    lang,
    snackErrorMsg,
    snackInfoMsg,
    newWizard,
    photoUri,
    token,
    goodDeals,
    page,
    hasNextPage,
    nextPage,
    totalPages,
    totalDocs,
    limit,
    sorted,
    channelsIds,
    templateId,
    choices,
    activityDescription,
    activityPostDescription
  } = state.gooddealReducer;

  const { isFetching: areFetching, user } = state.profileReducer;

  const { goodDealsCategories, campsites } = state.entitiesReducer;
  return {
    _id,
    areFetching,
    isFetching,
    isFetchingList,
    files,
    citiesIds,
    categoryId,
    description,
    phone,
    email,
    openingTime,
    price,
    address,
    zipCode,
    city,
    latitude,
    longitude,
    webSiteUrl,
    fbUrl,
    title,
    promotion,
    lang,
    snackErrorMsg,
    snackInfoMsg,
    goodDealsCategories,
    newWizard,
    photoUri,
    campsites,
    token,
    goodDeals: Object.values(goodDeals),
    page,
    hasNextPage,
    nextPage,
    totalPages,
    totalDocs,
    limit,
    sorted,
    user,
    channelsIds,
    templateId,
    choices,
    activityDescription,
    activityPostDescription
  };
};

const mapDispatchToProps = dispatch => {
  let actions = bindActionCreators(
    {
      fetchPayGoodDeal,
      fetchGetOwnGoodDeal,
      fetchGetGoodDealDetails,
      fetchGetGoodDealCampsites,
      fetchAddGoodDeal,
      fetchUpdateGoodDeal,
      fetchDeleteGoodDeal,
      fetchPublishGoodDeal,
      fetchModeratingAccept,
      fetchModeratingRefuse
    },
    dispatch
  );
  return { ...actions, dispatch };
};

export default {
  GoodDealsPage: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(GoodDealsPage)),
  GoodDealsWizard: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(GoodDealsWizard)),
  PublishWizard: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(style)(PublishWizard))
};
