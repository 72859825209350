import {
  SET_GLOBAL_INFORMATIONS_VERSIONS,
  API_START,
  FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
  API_END,
  RESET_DATA
} from "actions/types";

const initialState = {
  isFetching: false,
  serverVersion: null
};

export default function versionReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_GLOBAL_INFORMATIONS_VERSION) {
        return {
          ...state,
          isFetching: true
        };
      }
      break;

    case RESET_DATA:
      return {
        ...initialState
      };

    case SET_GLOBAL_INFORMATIONS_VERSIONS:
      return {
        ...state,
        serverVersion: action.payload.serverVersion
      };

    case API_END:
      if (action.payload === FETCH_GET_GLOBAL_INFORMATIONS_VERSION) {
        return {
          ...state,
          isFetching: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
