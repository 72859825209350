import {
  SET_AUDITS,
  FETCH_GET_AUDITS,
  API_END,
  API_START
} from "../actions/types";

const initialState = {
  audits: {},
  communities: {},
  users: {},
  isFetchingList: false,
  page: 1,
  hasNextPage: false,
  nextPage: 2,
  totalPages: 0,
  totalDocs: 0
};

export default function entities(state = initialState, action) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_AUDITS) {
        return {
          ...state,
          isFetchingList: true
        };
      }
      break;

    case SET_AUDITS: {
      const { audits, communities, users, infospages } = action.entities;
      const { page, hasNextPage, totalPages, totalDocs } = infospages["1"];
      return {
        ...state,
        audits,
        communities,
        users,
        page,
        hasNextPage,
        totalPages,
        totalDocs
      };
    }

    case API_END:
      if (action.payload === FETCH_GET_AUDITS) {
        return {
          ...state,
          isFetchingList: false
        };
      }
      break;

    default:
      break;
  }
  return state;
}
